import { BorrowerClientDto, DealPurpose } from '@pinecorpca/evergreen';
import { isApplicantInfoCompletedHelper, isAssetsCompletedHelper, isIncomesCompleteHelper } from 'utils';

export const getApplicantProgress = (dealPurpose: DealPurpose | undefined, borrower: BorrowerClientDto | undefined) => {
  const applicantHelper = isApplicantInfoCompletedHelper(borrower);
  const incomeHelper = isIncomesCompleteHelper(borrower);
  const assetsHelper = isAssetsCompletedHelper(dealPurpose as DealPurpose, borrower);
  const total = (applicantHelper.total || 0) + (incomeHelper.total || 0) + (assetsHelper.total || 0);
  const progress = (applicantHelper.progress || 0) + (incomeHelper.progress || 0) + (assetsHelper.progress || 0);
  const completed = applicantHelper.completed && incomeHelper.completed && assetsHelper.completed;
  return {
    total,
    progress,
    completed,
  };
};
