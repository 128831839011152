let config: Record<string, any> = {};

const isBrowser = () => Boolean(typeof window !== 'undefined' && window.__env);

const convertBoolean = (value: any) => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return value;
};

const sortKeys = (obj: Record<string, any>) =>
  Object.keys(obj)
    .sort()
    .reduce((acc, key) => {
      acc[key] = obj[key];

      return acc;
    }, {} as any);

const envConfig = isBrowser() ? window.__env : process.env;

Object.entries(envConfig).forEach(([key, value]) => {
  config[key] = convertBoolean(value);
});
config = sortKeys(config);

export default config;
