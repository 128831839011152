import { FunctionComponent } from 'react';
import Styled from './Layout.styled';
import dynamic from 'next/dynamic';

const DynamicHeader = dynamic(() => import('./components/Header'), { ssr: false });
const DynamicFooter = dynamic(() => import('../components/Footer/Footer'), { ssr: false });
interface FlowLayoutProps {
  children: React.ReactNode;
}

const FlowLayout: FunctionComponent<FlowLayoutProps> = ({ children }: FlowLayoutProps) => (
  <>
    <DynamicHeader />
    <Styled.Container>{children}</Styled.Container>
    <DynamicFooter />
  </>
);

export default FlowLayout;
