import { OptionStruct } from '@models/option.model';
import Styled from './Option.styles';
import { CSSProperties, ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

type Props = {
  selected: boolean;
  disabled?: boolean;
  Img: ReactNode;
  option: OptionStruct;
  onSelect: (option: OptionStruct) => void;
  style?: CSSProperties;
};

const Option = ({ selected, disabled, Img, option, onSelect, style }: Props) => {
  const [showError, setShowError] = useState(false);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // product requirement is to show invalid message after user click on the option
    if (option.invalid) {
      setShowError(true);
      return;
    }
    onSelect(option);
  };

  return (
    <Styled.OptionButton
      disabled={disabled}
      aria-disabled={disabled}
      selected={selected}
      tabIndex={0}
      aria-selected={selected}
      id={option.id}
      onClick={handleOnClick}
      data-testid={option.id}
      style={style}
      invalid={showError}
      aria-invalid={showError}
      aria-errormessage={`${option.id}-error`}
    >
      {Img && <Styled.Img>{Img}</Styled.Img>}
      {typeof option.label === 'string' ? <Styled.Text>{option.label}</Styled.Text> : option.label}
      {showError && (
        <Styled.ErrorWrapper>
          <FontAwesomeIcon icon={faExclamationCircle} />
          <Styled.ErrorMessage id={`${option.id}-error`} data-testid={`${option.id}-error`}>
            {option.errorMessage}
          </Styled.ErrorMessage>
        </Styled.ErrorWrapper>
      )}
    </Styled.OptionButton>
  );
};

Option.defaultProps = {
  disabled: false,
  optionStyle: undefined,
};

export default Option;
