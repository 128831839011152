import { Button } from '@pinecorpca/spruce';
import styled from 'styled-components';

const DeletionPopupStyles = {
  Container: styled.div`
    border: 1px solid ${({ theme }) => theme.border.primary};
    box-shadow: 2px 2px 7px 1px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    right: 0;
    background: ${({ theme }) => theme.background.primary};
    padding: 40px 48px;
    max-height: 240px;
    display: block;
    max-width: 312px;
    overflow: hidden;
    z-index: 100;
  `,

  Icon: styled.div`
    position: absolute;
    right: 16px;
    top: 16px;
  `,

  Title: styled.h4`
    font-size: 18px;
    line-height: 23px;
    font-family: var(--font-futura);
    font-weight: 400;
    margin-bottom: 16px;
  `,

  ButtonRow: styled.div`
    display: flex;
    justify-content: center;
    gap: 16px;
  `,
  CloseButton: styled.button`
    all: unset;
    width: 14px;
    height: 14px;
    cursor: pointer;
    outline: none;
  `,
  Button: styled(Button)`
    width: 80px;
  `,
  DeleteButton: styled.button`
    all: unset;
    cursor: pointer;
    text-decoration: underline;

    display: flex;
    align-items: center;
    gap: 9px;
  `,
};

export default DeletionPopupStyles;
