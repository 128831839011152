import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

// override documentation for react-toastify
// https://fkhadra.github.io/react-toastify/how-to-style/#how-to-style-with-styled-components
const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: 100%;

    @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
      width: fit-content;
    }
  }
  .Toastify__toast {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.granite.tint};
    background: ${({ theme }) => theme.background.primary};
    box-shadow: 0px 3px 8px 0px rgba(15, 18, 31, 0.24);
  }
  .Toastify__toast-body {
    display: flex;
    gap: 8px;
    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.palette.pine.default};
  }
`;

export const StyledToast = {
  StrongText: styled.span`
    font-family: var(--font-futura);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  `,
  Button: styled.button`
    all: unset;
    cursor: pointer;
    width: 15px;
    height: 20px;
    color: ${({ theme }) => theme.palette.granite.shade};
  `,
};

export default StyledToastContainer;
