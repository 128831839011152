import {
  BusinessSelfEmployment,
  DetailedIncomeDto,
  Employment,
  EmploymentType,
  IncomeType,
  OtherIncome,
  RentalIncome,
} from '@pinecorpca/evergreen';
import { StoreIncomeType } from 'hooks/models/incomes.model';

export const incomeFormBuilder = (income: DetailedIncomeDto) => {
  const [year] = income.years || [];
  switch (income.incomeType) {
    case IncomeType.Employment: {
      return {
        ...income,
        years: [
          {
            annualAmount: year?.annualAmount,
            bonus: year?.bonus,
            commission: year?.commission,
            overtime: year?.overtime,
          },
        ],
      };
    }
    case IncomeType.Selfemployment: {
      return {
        ...income,
        years: [
          {
            annualAmount: year?.annualAmount,
            grossRevenue: year?.grossRevenue,
          },
        ],
      };
    }
    case IncomeType.Rental: {
      return {
        ...income,
        years: [
          {
            annualAmount: year?.annualAmount ? year.annualAmount / 12 : undefined,
          },
        ],
      };
    }
    default: {
      return {
        ...income,
        years: [
          {
            annualAmount: year?.annualAmount,
          },
        ],
      };
    }
  }
};

export const patchIncomePayload = (income: DetailedIncomeDto) => {
  const [year] = income.years || [];
  const { startDate, incomeType, current, endDate } = income;
  switch (incomeType) {
    case IncomeType.Employment: {
      const { jobTitle, employerName } = income.income as Employment;
      return {
        startDate,
        current,
        endDate,
        income: {
          employerName,
          jobTitle,
        },
        years: [
          {
            annualAmount: year?.annualAmount,
            bonus: year?.bonus,
            commission: year?.commission,
            overtime: year?.overtime,
          },
        ],
      };
    }
    case IncomeType.Selfemployment: {
      const { businessName, businessType, foundedDate, jobTitle } = income.income as BusinessSelfEmployment;
      return {
        startDate,
        current,
        endDate,
        income: {
          foundedDate,
          businessName,
          jobTitle,
          businessType,
        },
        years: [
          {
            annualAmount: year.annualAmount,
            grossRevenue: year.grossRevenue,
          },
        ],
      };
    }
    case IncomeType.Rental: {
      const { property, propertyValue, monthlyExpenses } = income.income as RentalIncome;
      return {
        startDate,
        current,
        endDate,
        income: {
          propertyValue,
          monthlyExpenses,
          property: {
            propertyType: property?.propertyType,
            propertyAddress: {
              streetNumber: property?.propertyAddress?.streetNumber,
              streetName: property?.propertyAddress?.streetName,
              city: property?.propertyAddress?.city,
              province: property?.propertyAddress?.province,
              postalCode: property?.propertyAddress?.postalCode,
            },
          },
        },
        years: [
          {
            annualAmount: year.annualAmount ? year.annualAmount * 12 : null,
          },
        ],
      };
    }
    default: {
      const { type } = income.income as OtherIncome;
      return {
        startDate,
        current,
        endDate,
        income: {
          type,
        },
        years: [
          {
            annualAmount: year.annualAmount,
          },
        ],
      };
    }
  }
};

export const createIncomePayload = (incomeType: StoreIncomeType, isCurrent: boolean) => {
  switch (incomeType) {
    case EmploymentType.FullTime:
    case EmploymentType.PartTime:
      return {
        incomeType: IncomeType.Employment,
        current: isCurrent,
        income: {
          employerAddress: {},
          employmentType: incomeType,
          currentJob: isCurrent,
        },
        years: [
          {
            year: new Date().getFullYear(),
            annualAmount: undefined,
            bonus: undefined,
            commission: undefined,
            overtime: undefined,
          },
        ],
      };
    case IncomeType.Selfemployment:
      return {
        incomeType: IncomeType.Selfemployment,
        current: isCurrent,
        income: {
          address: {},
        },
        years: [
          {
            year: new Date().getFullYear(),
            annualAmount: undefined,
            grossRevenue: undefined,
          },
        ],
      };
    case IncomeType.Rental:
      return {
        incomeType: IncomeType.Rental,
        current: isCurrent,
        income: {
          property: {
            propertyAddress: {},
            propertyType: undefined,
          },
        },
        years: [
          {
            annualAmount: undefined,
          },
        ],
      };
    default:
      return {
        incomeType: IncomeType.Other,
        current: isCurrent,
        income: {},
        years: [
          {
            annualAmount: undefined,
          },
        ],
      };
  }
};
