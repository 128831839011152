import DeletionPopup from '@containers/long-app/components/DeletionPopup/DeletionPopup';
import FormStyles from '@containers/long-app/styles/Form.styles';
import TypeFormsStyles from '@containers/long-app/styles/TypeForms.styles';
import { NAMESPACE } from '@models/enums';
import { DetailedPropertyDto } from '@pinecorpca/evergreen';
import { useAdditionalPropertyMutationOnDelete } from 'hooks/queries/additional-properties.hooks';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ForwardedRef, forwardRef } from 'react';
import PropertyFormFields from './FormFields';

type Props = {
  formIndex?: number;
  property: DetailedPropertyDto;
};

const PropertyForm = forwardRef(({ formIndex, property }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    query: { borrowerId },
  } = useRouter();
  const { t } = useTranslation(NAMESPACE.LONGAPP);
  const { mutateAsync: propertyMutationOnDelete, isPending: isDeletePropertyLoading } = useAdditionalPropertyMutationOnDelete();

  return (
    <TypeFormsStyles.Container data-testid={`propertyContainer_${property.id}`} ref={ref} tabIndex={0}>
      <FormStyles.FormTitleWrapper>
        <FormStyles.FormTitle>{`${t('ADDITIONAL_PROPERTY')} ${formIndex}`}</FormStyles.FormTitle>
        <DeletionPopup
          isLoading={isDeletePropertyLoading}
          onConfirm={() =>
            propertyMutationOnDelete({
              propertyId: property.id as string,
              borrowerId: borrowerId as string,
            })
          }
          title={t('ASSETS_DELETE_ENTRY')}
        />
      </FormStyles.FormTitleWrapper>
      <FormStyles.FormWrapper>
        <FormStyles.FormWrapper key={`propertyForm_${property?.id}`}>
          <PropertyFormFields property={property} />
        </FormStyles.FormWrapper>
      </FormStyles.FormWrapper>
    </TypeFormsStyles.Container>
  );
});

PropertyForm.displayName = 'PropertyForm';

PropertyForm.defaultProps = {
  formIndex: 0,
};

export default PropertyForm;
