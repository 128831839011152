/* eslint-disable prefer-const */
import { PathRequestBody } from '@models/patchRequestBody.model';
import { Payload } from '@models/payload.model';
import { DetailedIncomeDto, DetailedPropertyDto } from '@pinecorpca/evergreen';
import get from 'lodash/get';
/**
 * onInputChange handler
 * @param event
 */
export const buildPayload = (entry: DetailedIncomeDto | DetailedPropertyDto, event: any, errors: any): Payload | undefined => {
  const { name, value, checked, type } = event.target;
  const error = get(errors, name);
  let payload: Payload | undefined;
  if (error) return payload;
  const nameWithoutPrefix = name.replace(`${entry.id}.`, '') as string;
  const path = `/${nameWithoutPrefix.replace(/\./g, '/')}`;
  const body: PathRequestBody[] = [{ op: 'replace', path, value: type === 'checkbox' ? checked : value }];
  payload = {
    id: entry.id as string,
    body,
  };
  return payload;
};
