import { MIN_MORTGAGE_AMOUNT } from '@components/DownPayment';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

export const useQuestionForm = (data: Record<string, any>) => {
  const methods = useForm({
    defaultValues: data,
  });

  const { setError, clearErrors } = methods;

  const handleLoanAmountSubmit = useCallback(
    (data: Record<string, any>, callback: () => void) => {
      clearErrors('root');
      const total =
        Number(data?.originalMortgageAmount || 0) + Number(data?.remainingMortgageAmount || 0) + Number(data?.additionalFundsAmount || 0);
      if (total < MIN_MORTGAGE_AMOUNT) {
        setError('root', {
          type: 'min-loan-amount',
          message: 'DOWNPAYMENT_MINIMUM_ERROR',
        });
        return;
      }
      callback();
    },
    [clearErrors, setError]
  );

  const handleDownPaymentSubmit = useCallback(
    (data: Record<string, any>, callback: () => void) => {
      clearErrors('root');
      const total = Number(data?.originalMortgageAmount || 0);
      if (total < MIN_MORTGAGE_AMOUNT) {
        setError('root', {
          type: 'min-downpayment',
          message: 'DOWNPAYMENT_MINIMUM_ERROR',
        });
        return;
      }
      callback();
    },
    [clearErrors, setError]
  );

  return [methods, { handleDownPaymentSubmit, handleLoanAmountSubmit }] as const;
};
