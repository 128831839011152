import { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import config from '@config';
import { ENVIRONMENT } from '@models/enums';
import { usePartnerContext } from 'contexts';
import { LeadSource } from '@pinecorpca/evergreen';

const AmplitudeInit = () => {
  const { partner } = usePartnerContext();

  useEffect(() => {
    if (typeof window === 'undefined' || config.ENVIRONMENT !== ENVIRONMENT.LIVE) return;
    const key = partner === LeadSource.Wealthsimple ? config.AMPLITUDE_KEY_WEALTH_SIMPLE : config.AMPLITUDE_KEY_OTHER;

    amplitude.init(key as string, undefined, {
      autocapture: true,
    });
  }, [partner]);

  return null;
};

export default AmplitudeInit;
