import config from '@config';
import { ENVIRONMENT } from '@models/enums';
import Script from 'next/script';

const Analytics = () => (
  <>
    {config.ENVIRONMENT === ENVIRONMENT.LIVE && (
      <>
        {/* <!-- Google Tag Manager --> */}
        <Script id="google-gtag" strategy="afterInteractive">
          {`(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-MS6Z7LP');`}
        </Script>
        {/*<!-- End Google Tag Manager --> */}
        {/*<!-- Global site tag (gtag.js) - Google Ads: AW-10809418216 --> */}
        <Script id="google-tag-manager" src="https://www.googletagmanager.com/gtag/js?id=AW-10809418216" />
        <Script id="google-tag-manager-script">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'AW-10809418216');
        `}
        </Script>
        {/* <!-- end of Global site tag (gtag.js) --> */}
        {/* <!-- Meta Pixel Code --> */}
        <Script id="meta-pixel-code" strategy="afterInteractive">
          {`
          !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '976354753311713');
          fbq('track', 'PageView');
        `}
        </Script>
        {/* <!-- End Meta Pixel Code --> */}
      </>
    )}
  </>
);

export default Analytics;
