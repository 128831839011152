import ErrorModalStyles from './ErrorModal.styles';

type Props = {
  header?: string;
  content?: string;
  'data-testid'?: string;
  contentStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
};

const ErrorModal = ({ header, content, 'data-testid': dataTestId, contentStyle, headerStyle }: Props) => (
  <div data-testid={dataTestId}>
    {header && <ErrorModalStyles.Header style={headerStyle}>{header}</ErrorModalStyles.Header>}
    {content && <ErrorModalStyles.Content style={contentStyle}>{content}</ErrorModalStyles.Content>}
  </div>
);

ErrorModal.defaultProps = {
  'data-testid': '',
  header: undefined,
  content: undefined,
};

export default ErrorModal;
