import { ENVIRONMENT } from '@models/enums';
import { LeadSource } from '@pinecorpca/evergreen';
import { decodeJwt } from 'jose';
import { ENVIRONMENT_PARTNER_SUBDOMAIN_MAPPER, PARTNERS } from './auth.constants';

const replaceSubdomain = (host: string, subdomain: string) => {
  switch (process.env.ENVIRONMENT) {
    // dev/pilot we prepend the subdomain (e.g. dev.pine.ca -> money.dev.pine.ca)
    case ENVIRONMENT.DEV:
    case ENVIRONMENT.PILOT:
    case ENVIRONMENT.LOCAL:
      return `${subdomain}.${host}`;
    // ENVIRONMENT.LIVE we replace the subdomain (e.g. apply.pine.ca -> money.pine.ca)
    default:
      return host.replace(/(\w+)/, subdomain);
  }
};

export const hasExistingPartnerSubdomain = (host: string | null | undefined) =>
  PARTNERS.some((partner) => host?.toLowerCase()?.startsWith(partner.toLowerCase()));

export const hasPartnerClaimAccessToken = (accessToken: string | undefined) => {
  if (!accessToken) return false;
  const decodedToken = decodeJwt(accessToken);
  const partnerClaim = decodedToken[process.env.PARTNER_CLAIM as string] as string;
  return !!partnerClaim;
};

export const hasPartnerClaimPartnerToken = (partnerToken: string | undefined) =>
  PARTNERS.some((partner) => partnerToken?.toLowerCase()?.startsWith(partner.toLowerCase()));

export const getRedirectPort = (host: string) => {
  switch (process.env.ENVIRONMENT) {
    case ENVIRONMENT.LOCAL:
      return host.split(':')?.[1];
    default:
      return '';
  }
};

const getSubdomainFromToken = (accessToken: string | undefined) => {
  if (!accessToken) return null;
  const decodedToken = decodeJwt(accessToken);
  const claim = decodedToken?.[process.env.PARTNER_CLAIM as string] as LeadSource | undefined;
  const mapping = ENVIRONMENT_PARTNER_SUBDOMAIN_MAPPER[process.env.ENVIRONMENT as ENVIRONMENT];
  return claim ? mapping[claim] : null;
};

const subDomainFromPartnerToken = (partnerToken: string | undefined) => {
  if (!partnerToken) return null;
  const mapping = ENVIRONMENT_PARTNER_SUBDOMAIN_MAPPER[process.env.ENVIRONMENT as ENVIRONMENT];
  return mapping?.[partnerToken as LeadSource];
};

export const getRedirectHost = (
  accessToken: string | undefined,
  partnerToken: string | undefined
): { redirectHost: string; redirectPort: string } => {
  const subdomainFromAccessToken = getSubdomainFromToken(accessToken);
  const subdomainFromPartnerToken = subDomainFromPartnerToken(partnerToken);
  const subdomain = subdomainFromAccessToken || subdomainFromPartnerToken;
  // use the BASE_URL to construct the correct redirectUrl in `getRedirectHost(...)`
  // this is to prevent an SSR request from using the POD host as the redirect host (not 100% on how this is happening)
  // slack thread for bug: https://pinecorpworkspace.slack.com/archives/C028GDZAE6R/p1708118554984649
  const host = process.env.BASE_URL as string;
  const redirectHost = subdomain ? replaceSubdomain(host, subdomain) : host;
  const redirectPort = getRedirectPort(host);

  return { redirectHost, redirectPort };
};
