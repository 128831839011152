import { MaritalStatus } from '@pinecorpca/evergreen';

export const MaritalStatusOptions = [
  {
    id: 'MARITAL_SINGLE',
    value: MaritalStatus.Single,
    label: 'MARITAL_SINGLE',
  },
  {
    id: 'MARITAL_MARRIED',
    value: MaritalStatus.Married,
    label: 'MARITAL_MARRIED',
  },
  {
    id: 'MARITAL_COMMONLAW',
    value: MaritalStatus.Commonlaw,
    label: 'MARITAL_COMMONLAW',
  },
  {
    id: 'MARITAL_SEPARATED',
    value: MaritalStatus.Separated,
    label: 'MARITAL_SEPARATED',
  },
  {
    id: 'MARITAL_DIVORCED',
    value: MaritalStatus.Divorced,
    label: 'MARITAL_DIVORCED',
  },
  {
    id: 'Widowed',
    value: MaritalStatus.Widowed,
    label: 'MARITAL_WIDOWED',
  },
  {
    id: 'MARITAL_OTHER',
    value: MaritalStatus.Other,
    label: 'MARITAL_OTHER',
  },
];
