import styled from 'styled-components';

const StyledBorrowerAddress = {
  Form: styled.form`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,
};

export default StyledBorrowerAddress;
