/* eslint-disable max-len */
import { API } from '@models/enums/api.enum';
import {
  MortgageApplicationClientDto,
  PropertyControllerApiApiMortgageApplicationsApplicationIdPropertyPostRequest as PropertyPostRequest,
  PropertyControllerApiApiPropertyPropertyIdPatchRequest as PropertyPatchRequest,
} from '@pinecorpca/evergreen';
import { propertyApi } from 'api/evergreen';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMortgageAppQuery } from './mortgage-application.hooks';
import { useRouter } from 'next/router';

export const usePropertyQuery = () => {
  const { data: property, isSuccess } = useMortgageAppQuery((data: MortgageApplicationClientDto) => data.property);
  return {
    data: property,
    isSuccess,
  };
};

export const usePropertyMutationOnPost = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (payload: PropertyPostRequest) => propertyApi.apiMortgageApplicationsApplicationIdPropertyPost(payload),
    onSuccess: ({ data: newProperty }) => {
      queryClient.setQueryData([API.MORTGAGE_APPLICATION, applicationId], (current: MortgageApplicationClientDto | undefined) => ({
        ...current,
        property: newProperty,
      }));
    },
  });
};

export const usePropertyMutationOnPatch = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (payload: PropertyPatchRequest) => propertyApi.apiPropertyPropertyIdPatch(payload),
    onSuccess: ({ data: updatedProperty }) => {
      queryClient.setQueryData([API.MORTGAGE_APPLICATION, applicationId], (current: MortgageApplicationClientDto | undefined) => ({
        ...current,
        property: updatedProperty,
      }));
    },
  });
};
