import { useAuthentication } from '@/lib/auth';
import ErrorModal from '@components/ErrorModal/ErrorModal';
import { NAMESPACE } from '@models/enums';
import { axiosInstance } from 'api/evergreen';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

type Props = {
  children: JSX.Element;
};

type AxiosCustomError = Omit<AxiosError, 'config'> & {
  config: AxiosRequestConfig & { _retry: boolean };
};

const WithAxios = ({ children }: Props) => {
  const { t } = useTranslation(NAMESPACE.DEFAULT);
  const [mounted, setMounted] = useState(false);
  const { doLogout, updateToken } = useAuthentication();

  const responseInterceptor = useCallback((response: AxiosResponse) => response, []);

  const errorInterceptor = useCallback(
    async (error: AxiosCustomError) => {
      const originalRequest = error.config;
      if (error.response?.status === 401 && originalRequest?.url?.includes('/api/users/token_refresh')) {
        await doLogout(true);
        return Promise.reject(error);
      }

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const response = await updateToken();
        if (response.status === 200) {
          return Promise.resolve(axiosInstance.request(originalRequest));
        }
        return Promise.reject(error);
      }

      if (originalRequest.url?.includes('/api') && ![409, 401, 404].includes(error.response?.status || 0)) {
        toast.error(<ErrorModal data-testid="error-modal" header={t('ERROR_MODAL_HEADER')} content={t('ERROR_MODAL_CONTENT')} />, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3600,
          toastId: `error-modal-${new Date().getTime()}`,
        });
        return Promise.reject(error);
      }

      return Promise.reject(error);
    },
    [doLogout, t, updateToken]
  );

  /**
   * prevent a request from being made if the component is not mounted
   * this ensures the interceptor is not added multiple times
   */
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);
    return () => axiosInstance.interceptors.response.eject(interceptor);
  }, [errorInterceptor, responseInterceptor]);

  if (!mounted) return <></>;

  return children;
};

export default WithAxios;
