import { ParagraphBold } from '@spruce/Typography';
import styled from 'styled-components';

const StyledListPropertyCard = {
  Header: styled.h3`
    font-family: var(--font-tiempos);
    font-weight: 300;
    color: ${({ theme }) => theme.text.accent};
    font-size: 24px;
    line-height: 32px;
  `,
  CardDeck: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
  `,
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.background.secondary};
  `,
  Address: styled.p`
    font-family: var(--font-futura);
    font-weight: 400;
    color: ${({ theme }) => theme.text.accent};
    font-size: 28px;
    line-height: 32px;
  `,
  Tag: styled.div`
    padding: 6px 12px;
    border-radius: 6px;
    background: ${({ theme }) => theme.palette.granite.default};
    width: fit-content;
  `,
  TagText: styled(ParagraphBold)`
    white-space: nowrap;
    line-height: 15px;
    color: ${({ theme }) => theme.text.invert};
  `,
  Hint: styled.p`
    font-family: var(--font-futura);
    font-weight: 400;
    color: ${({ theme }) => theme.text.primary};
    font-size: 16px;
    line-height: 24px;
  `,
};

export default StyledListPropertyCard;
