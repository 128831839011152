import Link from 'next/link';
import styled from 'styled-components';

const Styled404 = {
  Wrapper: styled.div`
    min-height: 100vh;
    min-width: 100vw;
    background-image: url('/images/pine_bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
  `,
  ContentWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 670px;
  `,
  Title: styled.h1`
    font-family: var(--font-tiempos);
    font-weight: 300;
    font-size: 160px;
    line-height: 160px;
    color: ${({ theme }) => theme.text.invert};
    @media only screen and (max-width: 448px) {
      font-size: 72px;
      line-height: 96px;
    }
  `,
  Text: styled.p`
    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 40px;
    line-height: 60px;
    color: ${({ theme }) => theme.text.invert};
    @media only screen and (max-width: 448px) {
      font-size: 20px;
      line-height: 28px;
    }
  `,
  Link: styled(Link)`
    text-decoration: underline;
    cursor: pointer;
    color: ${({ theme }) => theme.text.invert};
  `,
};

export default Styled404;
