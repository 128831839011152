import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CloseButtonProps } from 'react-toastify';
import { StyledToast as Styled } from './Toast.styled';

const CloseButton = ({ closeToast }: Partial<CloseButtonProps>) => (
  <Styled.Button onClick={closeToast}>
    <FontAwesomeIcon icon={faTimes} size="lg" />
  </Styled.Button>
);

export default CloseButton;
