import { API } from '@models/enums/api.enum';
import { DetailedUserDto, UsersControllerApiApiUsersMePatchRequest as UserPatchRequest } from '@pinecorpca/evergreen';
import { userApi } from 'api/evergreen';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useUserQuery = () =>
  useQuery({
    queryKey: [API.USER],
    queryFn: async () => {
      const { data: user } = await userApi.apiUsersMeGet();
      return user;
    },
  });

export const useUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: UserPatchRequest) => {
      const res = await userApi.apiUsersMePatch(payload);
      return res.data;
    },
    onSuccess: (user: DetailedUserDto) => {
      queryClient.setQueryData([API.USER], () => user);
    },
  });
};
