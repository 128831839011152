import { Button, StyledBaseDateInputs, StyledBaseDateType } from '@pinecorpca/spruce';
import styled from 'styled-components';

const StyledForm = {
  Form: styled.form`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,
  InputContainer: styled.div`
    display: grid;
    grid-template-columns: 1fr;
    max-width: 100%;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      display: grid;
      grid-template-columns: 1fr !important;
    }
  `,
  RadioGroupWrapper: styled.div`
    div > label {
      font-family: var(--font-futura);
      font-weight: 450;
      font-size: 18px;
      color: ${({ theme }) => theme.text.primary};
      line-height: 21px;
      margin-bottom: 12px;
    }
    // RadioGroup is built on nested labels
    // this is used to override the option label style without the above div > label applied
    div > div > label {
      font-family: var(--font-futura) !important;
      font-weight: 400;
      font-size: 18px;
      color: ${({ theme }) => theme.text.primary};
      line-height: 21px;
      margin-bottom: 12px;
    }

    div > div > label > input {
      width: 20px !important;
      height: 20px !important;

      &:before {
        width: 10px !important;
        height: 10px !important;
        box-shadow: inset 10px 10px ${({ theme }) => theme.text.primary} !important;
      }
    }

    p[role='alert'] {
      font-family: var(--font-futura);
      font-weight: 400;
    }
  `,
  SubmitButton: styled(Button)`
    justify-self: flex-end;
    margin-bottom: 20px;
    width: 120px;
  `,
  ButtonWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;

    @media (min-width: ${({ theme }) => theme.breakpoint.md}) {
      justify-content: flex-end;
    }
  `,
};

export const StyledDateOfBirth: StyledBaseDateType = {
  ...StyledBaseDateInputs,
  Wrapper: styled(StyledBaseDateInputs.Wrapper)`
    position: relative;
  `,
  InputBox: styled(StyledBaseDateInputs.InputBox)`
    height: 64px;
    border-radius: 8px;
    width: 100%;
    max-width: 100%;
    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: ${({ theme }) => theme.text.primary};
  `,
  Input: styled(StyledBaseDateInputs.Input)`
    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: ${({ theme }) => theme.text.primary};
    min-width: 46px !important;
  `,
  Cue: styled(StyledBaseDateInputs.Cue)`
    display: none;
  `,
  Label: styled(StyledBaseDateInputs.Label)`
    font-family: var(--font-futura);
    font-weight: 450;
    font-size: 16px;
    color: ${({ theme }) => theme.text.primary};
    line-height: 21px;
  `,
  ErrorMessage: styled(StyledBaseDateInputs.ErrorMessage)`
    position: absolute;
    font-family: var(--font-futura);
    font-weight: 400;
    color: ${({ theme }) => theme.text.error};
    font-size: 14px;
    line-height: 16px;
  `,
};

export default StyledForm;
