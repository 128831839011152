import styled, { css } from 'styled-components';
import { AlertType } from './alert.models';

const getColor = (type: AlertType) => {
  switch (type) {
    case 'warning':
      return css`
        ${({ theme }) => theme.palette.wheat.shade}
      `;
    case 'error':
      return css`
        ${({ theme }) => theme.text.error}
      `;
    case 'success':
      return css`
        ${({ theme }) => theme.text.accent}
      `;
    default:
      return css`
        ${({ theme }) => theme.text.primary}
      `;
  }
};

const StyledAlert = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
  `,
  Message: styled.p<{ type: AlertType }>`
    color: ${({ type }) => getColor(type)};
    font-family: var(--font-futura);
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
  `,
};

export default StyledAlert;
