import { Province } from '@pinecorpca/evergreen';
import { OptionStruct } from '../../models/option.model';

export const Provinces: OptionStruct[] = [
  {
    id: 'ALBERTA',
    value: Province.Alberta,
    label: 'ALBERTA',
  },
  {
    id: 'BRITISH_COLUMBIA',
    value: Province.BritishColumbia,
    label: 'BRITISH_COLUMBIA',
  },
  {
    id: 'MANITOBA',
    value: Province.Manitoba,
    label: 'MANITOBA',
  },
  {
    id: 'NEW_BRUNSWICK',
    value: Province.NewBrunswick,
    label: 'NEW_BRUNSWICK',
  },
  {
    id: 'NEWFOUNDLAND_AND_LABRADOR',
    value: Province.NewfoundlandAndLabrador,
    label: 'NEWFOUNDLAND_AND_LABRADOR',
  },
  {
    id: 'NOVA_SCOTIA',
    value: Province.NovaScotia,
    label: 'NOVA_SCOTIA',
  },
  {
    id: 'NORTHWEST_TERRITORIES',
    value: Province.NorthwestTerritories,
    label: 'NORTHWEST_TERRITORIES',
  },
  {
    id: 'NUNAVUT',
    value: Province.Nunavut,
    label: 'NUNAVUT',
  },
  {
    id: 'ONTARIO',
    value: Province.Ontario,
    label: 'ONTARIO',
  },
  {
    id: 'PRINCE_EDWARD_ISLAND',
    value: Province.PrinceEdwardIsland,
    label: 'PRINCE_EDWARD_ISLAND',
  },
  {
    id: 'QUEBEC',
    value: Province.Quebec,
    label: 'QUEBEC',
  },
  {
    id: 'SASKATCHEWAN',
    value: Province.Saskatchewan,
    label: 'SASKATCHEWAN',
  },
  {
    id: 'YUKON',
    value: Province.Yukon,
    label: 'YUKON',
  },
];
