import React, { ChangeEvent, useState, useEffect } from 'react';
import Styles from './Checkbox.styles';

type Props = {
  label: string | JSX.Element;
  id: string;
  name: string;
  wrapperStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onChange: (args: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  checked?: boolean;
  invalid?: boolean;
  errorMessage?: string;
  'data-testid'?: string;
};

const Checkbox = React.forwardRef(
  (
    { label, id, style, wrapperStyle, onChange, name, disabled, 'data-testid': dataTestId, checked, invalid, errorMessage }: Props,
    ref: React.LegacyRef<HTMLInputElement>
  ): JSX.Element => {
    const [check, setCheck] = useState(checked);
    const onCheckBoxChange = (event: any) => {
      const ck = event.target.checked;
      setCheck(() => ck);
      if (onChange) {
        onChange(event);
      }
    };

    useEffect(() => {
      setCheck(() => checked);
    }, [checked]);

    return (
      <Styles.Wrapper style={wrapperStyle}>
        <Styles.Checkbox style={style} $invalid={invalid}>
          <input
            data-testid={dataTestId}
            type="checkbox"
            id={id}
            checked={check}
            onChange={onCheckBoxChange}
            name={name}
            ref={ref}
            disabled={disabled}
            aria-invalid={invalid}
            aria-errormessage={`${id}-error`}
          />
          <label htmlFor={id} className="checkbox__label">
            {label}
          </label>
        </Styles.Checkbox>
        {invalid && (
          <p id={`${id}-error`} className="errorMessage">
            {errorMessage}
          </p>
        )}
      </Styles.Wrapper>
    );
  }
);

Checkbox.defaultProps = {
  checked: false,
  style: {},
  wrapperStyle: {},
  disabled: false,
  'data-testid': '',
  invalid: undefined,
  errorMessage: undefined,
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
