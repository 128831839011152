import { API, ROUTES } from '@models/enums';
import { ServicingRequestInput, WsCashInquiryRequest } from '@pinecorpca/evergreen';
import { useMutation, useQuery } from '@tanstack/react-query';
import { mortgageApplicationApi } from 'api/evergreen';
import { useSafePush } from 'hooks/safe-push';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useServicingQuery = () => {
  const { safePush } = useSafePush();
  const {
    query: { applicationId },
  } = useRouter();

  const { data, isLoading, isError } = useQuery({
    queryKey: [API.SERVICING, applicationId],
    queryFn: async () => {
      const { data } = await mortgageApplicationApi.apiMortgageApplicationsApplicationIdServicingGet({
        applicationId: applicationId as string,
      });
      return data;
    },
    enabled: !!applicationId,
  });

  useEffect(() => {
    if (isError) {
      safePush(ROUTES.NOT_FOUND);
    }
  }, [isError, safePush]);

  return {
    data,
    isLoading,
  };
};

export const useRequestServicingMutation = () => {
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (servicingRequestInput: ServicingRequestInput) =>
      mortgageApplicationApi.apiMortgageApplicationsApplicationIdServicingServiceRequestPost({
        applicationId: applicationId as string,
        servicingRequestInput,
      }),
  });
};

export const useRequestWealthsimpleMutation = () => {
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (wsCashInquiryRequest: WsCashInquiryRequest) =>
      mortgageApplicationApi.apiMortgageApplicationsApplicationIdWsCashInquiryPost({
        applicationId: applicationId as string,
        wsCashInquiryRequest,
      }),
  });
};
