import styled, { css } from 'styled-components';

type Props = {
  disabled?: boolean;
  selected?: boolean;
  invalid?: boolean;
};

const selectedChanges = (props: Props) => {
  if (props.selected) {
    return css`
      box-shadow: 0 0 0 2px ${({ theme }) => theme.border.active};
      background: ${({ theme }) => theme.background.secondary};
    `;
  }
  return css`
    box-shadow: 0 0 0 1px ${({ theme }) => theme.border.primary};
    background: ${({ theme }) => theme.background.primary};
  `;
};

const StyledOption = {
  OptionButton: styled.button<Props>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    border: none;
    ${(props) => selectedChanges(props)};
    border-radius: 8px;
    transition: all 0.2s ease;
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    padding: 24px;
    text-align: left;

    @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
      &:hover {
        box-shadow: 0 0 0 2px ${({ theme }) => theme.border.active};
      }
    }

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      padding: 18px 24px;
    }

    ${({ invalid }) =>
      invalid &&
      css`
        box-shadow: 0 0 0 2px ${({ theme }) => theme.border.error} !important;
        background: ${({ theme }) => theme.background.primary};
      `}
  `,
  Text: styled.p`
    display: flex;
    align-items: center;
    gap: 12px;
    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: ${({ theme }) => theme.text.primary};

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      font-size: 18px;
      line-height: 22px;
    }
  `,

  Img: styled.span`
    margin-bottom: 16px;
    width: 100%;
    max-width: 96px;
    text-align: center;
  `,
  ErrorWrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${({ theme }) => theme.text.error};
  `,
  ErrorMessage: styled.p`
    font-family: var(--font-futura);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: -0.07px;
    color: ${({ theme }) => theme.text.error};
  `,
};

export default StyledOption;
