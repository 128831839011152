import { NAMESPACE } from '@models/enums/namespace.enum';
import { AddressDto, DwellingStatus } from '@pinecorpca/evergreen';
import { useTranslation } from 'next-i18next';
import Styles from './ListedPropertyCard.styles';
import { formatAddress } from 'utils/formatAddress.util';

type Props = {
  address: (AddressDto & { tag: DwellingStatus }) | undefined;
};

const ListedPropertyCard = ({ address }: Props) => {
  const { t } = useTranslation(NAMESPACE.LONGAPP);

  if (!address) return <></>;

  return (
    <Styles.Wrapper data-testid={`rental-card-${address.id}`}>
      <Styles.Tag>
        <Styles.TagText>{t(address.tag === DwellingStatus.Own ? 'PRIMARY_RESIDENCE' : 'RENTAL_PROPERTY')}</Styles.TagText>
      </Styles.Tag>
      <Styles.Address>{formatAddress(address)}</Styles.Address>
    </Styles.Wrapper>
  );
};

export default ListedPropertyCard;
