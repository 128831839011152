import { BorrowerClientDto, BorrowerType } from '@pinecorpca/evergreen';

type nameProp = string | undefined | null;

export const formatName = (firstName: nameProp, lastName: nameProp, middleName?: nameProp) => {
  return [firstName, middleName, lastName]
    .map((name) => name?.trim())
    .join(' ')
    .replace(/\s+/g, ' ')
    .trim();
};

export const getPrimaryClientName = (borrowers: BorrowerClientDto[]) => {
  const primaryBorrower = borrowers.find((borrower) => borrower.borrowerType === BorrowerType.Primary);
  return formatName(primaryBorrower?.firstName, primaryBorrower?.lastName);
};
