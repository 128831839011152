import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const useRouterNavigating = () => {
  const { events } = useRouter();
  const [isNavigating, setIsNavigating] = useState(false);

  useEffect(() => {
    events.on('routeChangeStart', () => setIsNavigating(true));
    return () => {
      events.off('routeChangeStart', () => setIsNavigating(false));
    };
  }, [events]);

  return isNavigating;
};
