import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

/**
 * useMediaQuery does not support SSR
 * this hook is a wrapper to ensure client side has loaded, if this is not used, we'll get a hydration mismatch
 * we must use specifc values for breakpoints as unexpected results can be seen if we simply false check instead
 * example: {!isTabletOrMobile && <myComponent} will render on the server side because the value is undefined
 * https://github.com/yocontra/react-responsive/issues/162
 */
export const useResponsive = () => {
  const [isClient, setIsClient] = useState(false);
  const {
    breakpoint: { md, xlg },
  } = useTheme();

  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${md})` });
  const isDesktopOrLaptop = useMediaQuery({ query: `(min-width: ${xlg})` });

  useEffect(() => {
    if (typeof window !== 'undefined') setIsClient(true);
  }, []);

  return {
    isClient,
    isTabletOrMobile: isClient ? isTabletOrMobile : false,
    isDesktopOrLaptop: isClient ? isDesktopOrLaptop : false,
  };
};
