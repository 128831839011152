import { FlowRoot } from '@models/flow.model';
import { PathRequestBody } from '@models/patchRequestBody.model';
import { DealPurpose, MortgageApplicationClientDto } from '@pinecorpca/evergreen';
import { preApprovalPropertyForm, purchasePropertyForm, refinancePropertyForm, switchPropertyForm } from '../config/flow.config';

type FlowDecision = Pick<MortgageApplicationClientDto, 'dealPurpose'>;

const formatNumeric = (value: string): string => {
  const formattedValue = value?.replace(/,/g, '').replace(/[^0-9.]/g, '');
  return Number(formattedValue) != null ? formattedValue : value;
};

export const constructPayload = (property: string, obj: Record<string, string>): PathRequestBody[] => [
  { op: 'replace', path: `/${property}`, value: formatNumeric(obj[property]) },
];

export const getPropertyFlow = ({ dealPurpose = DealPurpose.Purchase }: FlowDecision): FlowRoot => {
  switch (dealPurpose) {
    case DealPurpose.Purchase:
      return purchasePropertyForm;
    case DealPurpose.Refinance:
      return refinancePropertyForm;
    case DealPurpose.SwitchTransfer:
      return switchPropertyForm;
    case DealPurpose.PreApproval:
      return preApprovalPropertyForm;
    default:
      return purchasePropertyForm;
  }
};
