import { DateConstants } from '@components/DatePicker/picker.constants';
import { AddressDto } from '@pinecorpca/evergreen';
import { format, isMatch, parse, subYears } from 'date-fns';
import { formatPersistedDate, isAfterOrEqual } from 'utils';

export const isCurrentAddressComplete = (address: AddressDto | undefined) => {
  if (!address) return false;
  const { streetNumber, streetName, city, province, postalCode } = address;
  return !!(streetNumber && streetName && city && province && postalCode);
};

export const moveInDateWithinThreeYears = (moveInDate: string | undefined) => {
  if (!moveInDate) return false;
  const threeYearsAgo = subYears(new Date(), 3);
  const format = isMatch(moveInDate, 'yyyy-MM-dd') ? 'yyyy-MM-dd' : 'dd/MM/yyyy';
  const withInThreeYears = isAfterOrEqual(parse(moveInDate, format, new Date()), threeYearsAgo);
  return withInThreeYears;
};

export const getPreviousAddressMoveInDate = (currentAddressMoveInDate: string | undefined) => {
  const moveInDate = formatPersistedDate(currentAddressMoveInDate || '');
  if (!moveInDate) return null;
  const threeYearsAgo = subYears(moveInDate, 3);
  return format(threeYearsAgo, DateConstants.BE_DATE_FORMAT);
};
