import { ENVIRONMENT } from '@models/enums';
import { LeadSource } from '@pinecorpca/evergreen';

export const PARTNERS = [LeadSource.Wealthsimple, 'money'] as const;

export const ENVIRONMENT_PARTNER_SUBDOMAIN_MAPPER: Record<ENVIRONMENT, Partial<Record<LeadSource, string>>> = {
  [ENVIRONMENT.LOCAL]: {
    WEALTHSIMPLE: LeadSource.Wealthsimple.toLowerCase(),
  },
  [ENVIRONMENT.DEV]: {
    WEALTHSIMPLE: 'money',
  },
  [ENVIRONMENT.PILOT]: {
    WEALTHSIMPLE: LeadSource.Wealthsimple.toLowerCase(),
  },
  [ENVIRONMENT.LIVE]: {
    WEALTHSIMPLE: LeadSource.Wealthsimple.toLowerCase(),
  },
};
