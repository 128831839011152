import { SectionStatus } from '@models/sectionStatus.enum';
import { ParagraphBold } from '@spruce/Typography';
import styled, { css } from 'styled-components';

const bg = (status: SectionStatus) => {
  switch (status) {
    case SectionStatus.INCOMPLETE:
      return css`
        background-color: ${({ theme }) => theme.palette.granite.default};
      `;
    case SectionStatus.SUBMITTED:
      return css`
        background-color: ${({ theme }) => theme.palette.moss.bright};
      `;
    case SectionStatus.IN_PROGRESS:
      return css`
        background-color: ${({ theme }) => theme.palette.wheat.default};
      `;
    case SectionStatus.COMPLETED:
      return css`
        background-color: ${({ theme }) => theme.palette.lichen.default};
      `;
    case SectionStatus.INREVIEW:
      return css`
        background-color: ${({ theme }) => theme.palette.sandstone.default};
      `;
    default:
      return css`
        background-color: ${({ theme }) => theme.palette.pine.tint};
      `;
  }
};

const color = (status: SectionStatus) => {
  switch (status) {
    case SectionStatus.INCOMPLETE:
    case SectionStatus.SUBMITTED:
    case SectionStatus.IN_PROGRESS:
    case SectionStatus.INREVIEW:
    case SectionStatus.DEFAULT:
      return css`
        color: ${({ theme }) => theme.text.invert};
      `;
    default:
      return css`
        color: ${({ theme }) => theme.text.accent};
      `;
  }
};

const StatusStyles = {
  Wrapper: styled.div<{ status: SectionStatus }>`
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 6px;
    height: 25px;
    gap: 5px;
    ${({ status }) => bg(status)};
  `,

  Text: styled(ParagraphBold)<{ status: SectionStatus }>`
    white-space: nowrap;
    line-height: 15px;
    ${({ status }) => color(status)}
  `,
};

export default StatusStyles;
