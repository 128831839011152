import styled, { css } from 'styled-components';

const font = css`
  font-family: var(--font-futura);
  font-weight: 400;
  color: ${({ theme }) => theme.text.primary};
`;

const StyledProvinceModal = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    max-width: 300px;
  `,
  H2: styled.h2`
    ${font}
    font-size: 28px;
    line-height: 34px;
  `,
  Text: styled.p`
    ${font}
    font-size: 14px;
    line-height: 22px;
  `,
  ImageWrapper: styled.div`
    position: relative;
    width: 100%;
    height: 96px;
  `,
};

export default StyledProvinceModal;
