import { NAMESPACE } from '@models/enums/namespace.enum';
import { SectionStatus } from '@models/sectionStatus.enum';
import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import StatusStyles from './Pill.styles';
import Image from 'next/image';

type Props = {
  status: SectionStatus;
  value?: string;
};

const Pill = ({ status, value }: Props) => {
  const { t } = useTranslation(NAMESPACE.CUSTOMERPORTAL);

  const content = useMemo((): { img: JSX.Element; text: string } => {
    switch (status) {
      case SectionStatus.SUBMITTED:
        return { img: <Image alt="" src="/images/lock.svg" width={12} height={24} />, text: t('STATUS_SUBMITTED') };
      case SectionStatus.INREVIEW:
        return { img: <Image alt="" src="/images/lock.svg" width={12} height={24} />, text: t('STATUS_INREVIEW') };
      case SectionStatus.COMPLETED:
        return { img: <Image alt="" src="/images/checkmark.svg" width={16} height={16} />, text: t('STATUS_COMPLETED') };
      case SectionStatus.IN_PROGRESS:
        return { img: <Image alt="" src="/images/renew.svg" width={14} height={15} />, text: t('STATUS_INPROGRESS') };
      case SectionStatus.INCOMPLETE:
        return { img: <Image alt="" src="/images/warning.svg" width={12} height={12} />, text: t('STATUS_EMPTY') };
      default:
        return { img: <Image alt="" src="/images/warning.svg" width={16} height={16} />, text: t(value as string) };
    }
  }, [status, t, value]);

  return (
    <StatusStyles.Wrapper status={status}>
      {content.img}
      <StatusStyles.Text status={status}>{content.text}</StatusStyles.Text>
    </StatusStyles.Wrapper>
  );
};

Pill.defaultProps = { value: undefined };

export default Pill;
