import styled, { css } from 'styled-components';

const boxShadow = (invalid?: boolean) =>
  invalid
    ? css`
        box-shadow: 0 0 0 2px ${({ theme }) => theme.border.error};
      `
    : css`
        box-shadow: 0 0 0 1px ${({ theme }) => theme.border.primary};
      `;

const StyledDropdown = {
  Wrapper: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 9px;
  `,
  Select: styled.select<{ invalid?: boolean; hasValue: boolean }>`
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: ${({ theme }) => theme.background.primary};
    ${({ invalid }) => boxShadow(invalid)};
    border: 0 solid ${({ theme }) => theme.border.primary};
    border-radius: 8px;
    height: 64px;
    cursor: pointer;
    font-size: 18px;
    line-height: 22px;
    padding: 6px 24px 6px 12px;
    color: ${({ theme, hasValue }) => (hasValue ? theme.text.primary : theme.text.disabled)};
    font-family: var(--font-futura);
    font-weight: 400;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      font-size: 16px;
      line-height: 21px;
    }

    :disabled {
      background-color: ${({ theme }) => theme.background.disabled};
    }

    :focus {
      outline: none;
      background-color: ${({ theme }) => theme.background.primary};
      border: 1px;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1), 0 0 0 2px ${({ invalid, theme }) => (invalid ? theme.border.error : theme.border.active)};
    }
  `,
  Label: styled.label`
    font-size: 18px;
    line-height: 22px;
  `,
  ErrorMessage: styled.p`
    position: absolute;
    bottom: -24px;
    color: ${({ theme }) => theme.text.error};
    font-size: 14px;
    line-height: 18px;
  `,
  ChevronWrapper: styled.div`
    position: absolute;
    right: 20px;
    top: 65px;
    transform: translateY(-50%);
    pointer-events: none;
  `,
};

export default StyledDropdown;
