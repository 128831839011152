import { NAMESPACE } from '@models/enums';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Styled from './ErrorPage.styled';

const ErrorPage = () => {
  const { t } = useTranslation(NAMESPACE.COMMON);
  const { reload } = useRouter();

  return (
    <Styled.ErrorWrapper>
      <Styled.ErrorContainer>
        <Styled.MessageContainer>
          <Styled.ErrorMessage>
            {t('ERROR_PAGE_MESSAGE')}
            <Styled.UnderLine />
          </Styled.ErrorMessage>
          <Styled.ErrorLink onClick={reload}>{t('ERROR_CTA')}</Styled.ErrorLink>
        </Styled.MessageContainer>
        <Styled.ErrorImage>
          <Image
            alt={t('ERROR_IMAGE_ALT')}
            src="/images/error/error-page.svg"
            fill
            priority
            sizes="100vw"
            style={{ objectFit: 'contain' }}
          />
        </Styled.ErrorImage>
      </Styled.ErrorContainer>
    </Styled.ErrorWrapper>
  );
};

export default ErrorPage;
