export enum NAMESPACE {
  DEFAULT = 'default',
  SUBMITTED = 'submitted',
  LONGAPP = 'longapp',
  CUSTOMERPORTAL = 'customerportal',
  ADDITIONAL_BORROWERS = 'additional-borrowers',
  CREDIT_CONSENT = 'credit-consent',
  COMMON = 'common',
  TASKS = 'tasks',
  COMMUNICATIONS = 'communications',
  RATES = 'rates',
  SERVICING = 'servicing',
  PROMOTION = 'promotion',
  PARTNER = 'partner',
}
