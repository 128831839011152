import { AddressDto, Province } from '@pinecorpca/evergreen';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { provinceMapper } from './common.utils';

export interface Options {
  excludePostalCode: boolean;
}

export const isObjectPropertiesUndefined = <T>(obj: T): boolean => {
  if (!obj) return true;
  const objectEntries = Object.values(obj);
  if (!objectEntries.length) return true;
  return objectEntries.every((value) => value == null);
};

export const formatAddress = (address: AddressDto | undefined | null, options?: Options) => {
  if (isObjectPropertiesUndefined(address)) return undefined;

  const { postalCode, province, addressLine2 } = { ...address };
  // its possible for an address to not have streetNumber,streetName,city if the application was originally a pre-approval and then converted
  // note: spaces are intentional within the below ternary operators to preserve the format of the address
  const streetName = address?.streetName ? `${address.streetName}, ` : '';
  const streetNumber = address?.streetNumber ? `${address?.streetNumber} ` : '';
  const city = address?.city ? `${address.city}, ` : '';

  const formattedAddress = `${streetNumber}${streetName}${city}${provinceMapper[province as Province]} ${
    options?.excludePostalCode ? '' : postalCode || ''
  }`;

  if (addressLine2) return `${addressLine2} - ${formattedAddress}`;

  return formattedAddress;
};

type AddressType = AddressDto | undefined;

type AddressSubDto = Pick<AddressDto, 'streetName' | 'streetNumber' | 'addressLine2' | 'city' | 'province' | 'postalCode'>;

const keys = ['streetNumber', 'streetName', 'addressLine2', 'province', 'postalCode', 'city'];

export const areAddressesEqual = (mainAddress: AddressType, secondAddress: AddressType): boolean => {
  if (!mainAddress || !secondAddress) return false;

  const mainAddressObj = pick(mainAddress, keys);
  // regex remove spaces between words, eg: "123  Main  St" => "123 Main St"
  Object.keys(mainAddressObj).forEach((key) => {
    mainAddressObj[key as keyof AddressSubDto] = mainAddressObj[key as keyof AddressSubDto]
      ?.replace(/\s+/g, ' ')
      ?.trim()
      ?.toLocaleLowerCase() as any;
  });
  const secondAddressObj = pick(secondAddress, keys);
  Object.keys(secondAddressObj).forEach((key) => {
    secondAddressObj[key as keyof AddressSubDto] = secondAddressObj[key as keyof AddressSubDto]
      ?.replace(/\s+/g, ' ')
      ?.trim()
      ?.toLocaleLowerCase() as any;
  });

  return isEqual(mainAddressObj, secondAddressObj);
};
