import DatePicker from '@components/DatePicker/DatePicker';
import FormStyles from '@containers/long-app/styles/Form.styles';
import { NAMESPACE } from '@models/enums/namespace.enum';
import { BusinessSelfEmployment, DetailedIncomeDto } from '@pinecorpca/evergreen';
import Dropdown from '@spruce/Dropdown/Dropdown';
import CurrencyInput from '@spruce/Inputs/Currency/CurrencyInput';
import Input from '@spruce/Inputs/Input/Input';
import { subYears } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { isAfterOrEqual } from 'utils/date.utils';
import { BusinessTypeOptions } from '../../../models/businessTypeOptions.constants';
import { formatDate } from '../../../utils/incomes.utils';

type Props = {
  income: DetailedIncomeDto;
  index: number;
};

const SelfEmployedForm = ({ income, index }: Props): JSX.Element => {
  const { control, getValues } = useFormContext();

  const { t } = useTranslation(NAMESPACE.LONGAPP);
  return (
    <>
      <FormStyles.FormTitle>
        <span className="title">{t('INCOMES_SELFEMPLOYED_BUSINESS_DETAILS')}</span>
      </FormStyles.FormTitle>
      <FormStyles.SubFormWrapper>
        <Controller
          control={control}
          name={`incomes.${index}.income.businessName`}
          rules={{
            required: {
              value: true,
              message: t('INCOMES_SELFEMPLOYED_NAME_REQUIRED'),
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
            <Input
              name={name}
              id={name}
              label={t('INCOMES_SELFEMPLOYED_BUSINESS_NAME')}
              placeholder={t('INCOMES_SELFEMPLOYED_BUSINESS_NAME_PLACEHOLDER')}
              data-testid={`${(income.income as BusinessSelfEmployment)?.incomeId}.businessName`}
              value={value}
              invalid={!!error}
              errorMessage={error?.message}
              onChange={onChange}
              ref={ref}
            />
          )}
        />
        {!!getValues(`incomes.${index}.current`) && (
          <Controller
            key={`DATE_ESTABLISHED_${(income.income as BusinessSelfEmployment)?.incomeId}`}
            control={control}
            name={`incomes.${index}.income.foundedDate`}
            rules={{
              required: {
                value: true,
                message: t('INCOMES_SELFEMPLOYED_DATE_REQUIRED'),
              },
            }}
            render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
              <DatePicker
                id={name}
                name={name}
                ref={ref}
                label={t('INCOMES_SELFEMPLOYED_DATE_ESTABLISHED')}
                value={value}
                to={new Date()}
                from={subYears(new Date(), 100)}
                picker="month"
                invalid={!!error?.message}
                errorMessage={error?.message ?? ''}
                onChange={onChange}
              />
            )}
          />
        )}
        <Controller
          key={`START_DATE_${(income.income as BusinessSelfEmployment)?.incomeId}`}
          control={control}
          name={`incomes.${index}.startDate`}
          rules={{
            required: {
              value: true,
              message: t('INCOMES_EMPLOYMENT_START_DATE_REQUIRED'),
            },
            validate: (value) => {
              if (!!getValues(`incomes.${index}.current`) || !value) return undefined;
              const startDate = getValues(`incomes.${index}.income.foundedDate`);
              return isAfterOrEqual(new Date(value), new Date(startDate)) || t('INCOMES_INVALID_ESTABLISHED_DATE');
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
            <DatePicker
              id={name}
              name={name}
              ref={ref}
              label={t('INCOMES_EMPLOYMENT_START_DATE')}
              value={value}
              to={new Date()}
              from={formatDate(getValues(`incomes.${index}.income.foundedDate`)) ?? subYears(new Date(), 100)}
              picker="month"
              invalid={!!error?.message}
              errorMessage={error?.message ?? ''}
              onChange={onChange}
            />
          )}
        />
        {!getValues(`incomes.${index}.current`) && (
          <Controller
            control={control}
            name={`incomes.${index}.endDate`}
            rules={{
              required: {
                value: true,
                message: t('INCOMES_EMPLOYMENT_END_DATE_REQUIRED'),
              },
              validate: (value) => {
                if (!value) return undefined;
                const startDate = getValues(`incomes.${index}.startDate`);
                return isAfterOrEqual(new Date(value), new Date(startDate)) || t('INCOMES_INVALID_END_DATE');
              },
            }}
            render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
              <DatePicker
                name={name}
                id={name}
                ref={ref}
                label={t('INCOMES_EMPLOYMENT_END_DATE')}
                value={value}
                to={new Date()}
                from={formatDate(getValues(`incomes.${index}.startDate`)) ?? subYears(new Date(), 100)}
                picker="month"
                invalid={!!error?.message}
                errorMessage={error?.message ?? ''}
                onChange={onChange}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name={`incomes.${index}.years.0.grossRevenue`}
          rules={{
            required: {
              value: true,
              message: t('INCOMES_SELFEMPLOYED_REVENUE_REQUIRED'),
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
            <CurrencyInput
              name={name}
              id={name}
              label={t('INCOMES_SELFEMPLOYED_GROSS_REVENUE')}
              data-testid={`${(income.income as BusinessSelfEmployment)?.incomeId}.grossRevenue`}
              placeholder="$0"
              value={value}
              invalid={!!error}
              errorMessage={error?.message}
              onChange={onChange}
              ref={ref}
            />
          )}
        />
        <Controller
          control={control}
          name={`incomes.${index}.income.businessType`}
          rules={{
            required: {
              value: true,
              message: t('INCOMES_SELFEMPLOYED_TYPE_REQUIRED'),
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
            <Dropdown
              name={name}
              id={name}
              label={t('INCOMES_SELFEMPLOYED_OPERATING_TYPE')}
              placeholder={t('INCOMES_SELFEMPLOYED_OPERATING_TYPE_PLACEHOLDER')}
              options={BusinessTypeOptions}
              data-testid={`${(income.income as BusinessSelfEmployment)?.incomeId}.businessType`}
              defaultValue={value}
              invalid={!!error}
              errorMessage={error?.message}
              onChange={onChange}
              ref={ref}
            />
          )}
        />
      </FormStyles.SubFormWrapper>

      <FormStyles.FormSubtitle>{t('INCOMES_SELFEMPLOYED_INCOME')}</FormStyles.FormSubtitle>
      <FormStyles.SubFormWrapper>
        <Controller
          control={control}
          name={`incomes.${index}.income.jobTitle`}
          rules={{
            required: {
              value: true,
              message: t('INCOMES_SELFEMPLOYED_TITLE_REQUIRED'),
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
            <Input
              name={name}
              id={name}
              label={t('INCOMES_SELFEMPLOYED_JOB_TITLE')}
              placeholder={t('INCOMES_EMPLOYMENT_JOB_TITLE_PLACEHOLDER')}
              data-testid={`${(income.income as BusinessSelfEmployment)?.incomeId}.jobTitle`}
              value={value}
              invalid={!!error}
              errorMessage={error?.message}
              onChange={onChange}
              ref={ref}
            />
          )}
        />
        <Controller
          control={control}
          name={`incomes.${index}.years.0.annualAmount`}
          rules={{
            required: {
              value: true,
              message: t('INCOMES_SELFEMPLOYED_INCOME_REQUIRED'),
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
            <CurrencyInput
              name={name}
              id={name}
              label={t('INCOMES_SELFEMPLOYED_ANNUAL_BUSINESS_INCOME')}
              data-testid={`${(income.income as BusinessSelfEmployment)?.incomeId}.annualAmount`}
              placeholder="$0"
              value={value}
              invalid={!!error}
              errorMessage={error?.message}
              onChange={onChange}
              ref={ref}
            />
          )}
        />
      </FormStyles.SubFormWrapper>
    </>
  );
};

export default SelfEmployedForm;
