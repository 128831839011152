import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

/**
 * using router.push() in a useEffect will cause the push to be called multiple times in some cases
 * to avoid this console error, we track the router events to ensure that the push is only called once
 * @returns safePush function
 */
export const useSafePush = () => {
  const [onChanging, setOnChanging] = useState(false);
  const router = useRouter();
  const safePush = useCallback(
    (path: string) => {
      if (onChanging) {
        return;
      }
      setOnChanging(true);
      router.push(path);
    },
    [onChanging, router]
  );

  useEffect(() => {
    const handleRouteChange = () => {
      setOnChanging(false);
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, setOnChanging]);
  return { safePush };
};
