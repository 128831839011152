import { DefaultTheme } from 'styled-components';
import { theme as spruceTheme, wealthSimpleTheme } from '@pinecorpca/spruce';

const additionalAttributes = {
  pineSideBarWidth: '100px',
  pineNavHeight: '75px',
  pineNavHeightMobile: '76px',
  pineFooterHeight: '65px',
  pineFooterMobileHeight: '132px',
  pineSideNavHeight: '64px',
  pineLayoutMaxWidth: '1280px',
  pineQuestionSectionMaxWidth: '924px',
  mediumLayoutMaxWidth: '1080px',
  stepIndicatorMobileHeight: '40px',
  breakpoint: {
    xlg: '1224px',
    lg: '932px',
    mdlg: '816px',
    md: '768px',
    sm: '578px',
    xs: '432px',
  },
};

export const theme: DefaultTheme = {
  ...spruceTheme,
  ...additionalAttributes,
};

export const wsTheme: DefaultTheme = {
  ...wealthSimpleTheme,
  ...additionalAttributes,
  background: {
    ...wealthSimpleTheme.background,
    accent: '#2A2C32',
  },
  cta: {
    ...wealthSimpleTheme.cta,
    secondary: {
      default: {
        background: '#fcfcfc',
        color: '#32302f',
        border: '#32302f',
      },
      hover: {
        background: '#f7f7f7',
        color: '#32302f',
        border: '#32302f',
      },
      active: {
        background: '#f0f0f0',
        color: '#32302f',
        border: '#32302f',
      },
      disabled: {
        background: '#fcfcfc',
        color: '#32302f',
        border: '#32302f',
      },
    },
  },
};

// TODO remove when demo complete https://pinecanada.atlassian.net/browse/CUS-4641
export const rbTheme: DefaultTheme = {
  ...spruceTheme,
  ...additionalAttributes,
  border: wealthSimpleTheme.border,
  background: wealthSimpleTheme.background,
  cta: {
    ...spruceTheme.cta,
    primary: {
      default: {
        background: '#da291c',
        color: '#ffffff',
        border: '#da291c',
      },
      hover: {
        background: '#ae2116',
        color: '#ffffff',
        border: '#da291c',
      },
      active: {
        background: '#da291c',
        color: '#ffffff',
        border: '#da291c',
      },
      disabled: {
        background: '#da291c',
        color: '#ffffff',
        border: '#da291c',
      },
    },
    secondary: {
      default: {
        background: '#ffffff',
        color: '#da291c',
        border: '#da291c',
      },
      hover: {
        background: '#ffffff',
        color: '#da291c',
        border: '#da291c',
      },
      active: {
        background: '#ffffff',
        color: '#da291c',
        border: '#da291c',
      },
      disabled: {
        background: '#ffffff',
        color: '#32302f',
        border: '#32302f',
      },
    },
  },
};
