import styled from 'styled-components';

const ErrorModalStyles = {
  Header: styled.h1`
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
  `,

  Content: styled.p`
    font-family: var(--font-futura);
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
  `,
};

export default ErrorModalStyles;
