import Head from 'next/head';

const SEO = () => (
  <Head>
    <meta name="facebook-domain-verification" content="ksj4cd3lh8z94af5z8n658dk5flv2g" />
    <link rel="icon" href="/favicon.ico" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta name="mobile-web-app-capable" content="yes" />
    <link rel="apple-touch-icon" href="/images/logo.svg" />
  </Head>
);

export default SEO;
