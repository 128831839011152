import { API } from '@models/enums/api.enum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { downPaymentApi } from 'api/evergreen';
import {
  DownpaymentsControllerApiApiBorrowersBorrowerIdDownpaymentsPostRequest as DownPaymentPost,
  DownpaymentsControllerApiApiDownpaymentDownpaymentIdPatchRequest as DownPaymentPatch,
  MortgageApplicationClientDto,
} from '@pinecorpca/evergreen';
import { useBorrowerQuery } from './borrower.hooks';
import { useRouter } from 'next/router';

export const useDownpaymentQuery = (borrowerId?: string) => {
  const { data: borrower } = useBorrowerQuery(borrowerId);
  return { data: borrower?.downPayments || [] };
};

export const useDownpaymentMutationOnPost = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (payload: DownPaymentPost) => downPaymentApi.apiBorrowersBorrowerIdDownpaymentsPost(payload),
    onSuccess: ({ data: newDownpayment }) => {
      queryClient.setQueryData(
        [API.MORTGAGE_APPLICATION, applicationId as string],
        (current: MortgageApplicationClientDto | undefined) => ({
          ...current,
          borrowers: current?.borrowers?.map((borrower) =>
            borrower.id === newDownpayment.borrowerId
              ? { ...borrower, downPayments: [...(borrower?.downPayments || []), newDownpayment] }
              : borrower
          ),
        })
      );
    },
  });
};

export const useDownpaymentMutationOnPatch = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (payload: DownPaymentPatch) => downPaymentApi.apiDownpaymentDownpaymentIdPatch(payload),
    onSuccess: ({ data: updatedDownpayment }) => {
      queryClient.setQueryData([API.MORTGAGE_APPLICATION, applicationId as string], (current: MortgageApplicationClientDto | undefined) => {
        const borrower = current?.borrowers?.find(({ id }) => id === updatedDownpayment.borrowerId);
        const downPayments = borrower?.downPayments?.map((downpayment) =>
          downpayment.id === updatedDownpayment.id ? updatedDownpayment : downpayment
        );
        return {
          ...current,
          borrowers: current?.borrowers?.map((borrower) =>
            borrower.id === updatedDownpayment.borrowerId ? { ...borrower, downPayments } : borrower
          ),
        };
      });
    },
  });
};

export const useDownpaymentMutationOnDelete = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (request: { borrowerId: string; downpaymentId: string }) =>
      downPaymentApi.apiDownpaymentDownpaymentIdDelete({ downpaymentId: request.downpaymentId }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [API.MORTGAGE_APPLICATION, applicationId as string] }),
  });
};
