import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NAMESPACE } from '@models/enums/namespace.enum';
import { Spinner } from '@pinecorpca/spruce';
import noop from 'lodash/noop';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import Styled from './DeletionPopup.styles';

type Props = {
  title: string;
  isLoading: boolean;
  style?: React.CSSProperties;
  onConfirm?: () => Promise<any>;
  onClose?: () => void;
  'data-testid'?: string;
};

const DeletionPopup = ({ isLoading, style, title, onConfirm, onClose, 'data-testid': dataTestId }: Props) => {
  const [open, setOpen] = useState<boolean>();
  const { t } = useTranslation(NAMESPACE.DEFAULT);

  const toggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpen((prev) => !prev);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement | SVGSVGElement | MouseEvent>) => {
    event?.preventDefault();
    setOpen(false);
    onClose?.();
  };

  const handleConfirm = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    await onConfirm?.();
    setOpen(false);
  };

  return (
    <>
      <Styled.DeleteButton data-testid={dataTestId} type="button" onClick={toggle}>
        <FontAwesomeIcon icon={faTrashCan} />
        {t('DELETE')}
      </Styled.DeleteButton>
      {open && (
        <Styled.Container style={style}>
          <Styled.Icon>
            {!isLoading && (
              <Styled.CloseButton data-testid={`${dataTestId}_close`} tabIndex={0} onClick={handleClose}>
                <FontAwesomeIcon icon={faTimes} size="lg" />
              </Styled.CloseButton>
            )}
            {isLoading && <Spinner radius="20px" />}
          </Styled.Icon>
          <Styled.Title>{title}</Styled.Title>
          <Styled.ButtonRow>
            <Styled.Button color="secondary" size="small" onClick={handleClose} style={{ pointerEvents: isLoading ? 'none' : 'auto' }}>
              {t('COMMON_NO')}
            </Styled.Button>
            <Styled.Button onClick={handleConfirm} size="small" style={{ pointerEvents: isLoading ? 'none' : 'auto' }}>
              {t('COMMON_YES')}
            </Styled.Button>
          </Styled.ButtonRow>
        </Styled.Container>
      )}
    </>
  );
};

DeletionPopup.defaultProps = {
  style: {},
  'data-testid': '',
  onClose: noop,
  onConfirm: noop,
};

export default DeletionPopup;
