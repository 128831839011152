import { formatCurrency } from 'utils';

export const constructLoanAmount = (
  originalMortgageAmount?: string,
  remainingMortgageAmount?: string,
  additionalFundsAmount?: string
): string | null => {
  if (originalMortgageAmount != null) {
    return formatCurrency(originalMortgageAmount);
  }
  if (remainingMortgageAmount != null && additionalFundsAmount != null) {
    return formatCurrency(Number(remainingMortgageAmount) + Number(additionalFundsAmount));
  }
  if (remainingMortgageAmount != null) {
    return formatCurrency(remainingMortgageAmount);
  }
  if (additionalFundsAmount != null) {
    return formatCurrency(additionalFundsAmount);
  }
  return null;
};
