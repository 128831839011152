import styled from 'styled-components';

const QuestionStyles = {
  Wrapper: styled.div`
    display: flex;
    gap: 48px;
    &:focus {
      outline: none;
    }
  `,
  HeaderWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
  `,
  Header: styled.h2`
    font-size: 40px;
    line-height: 46px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      font-size: 36px;
      line-height: 41px;
    }
  `,
  SubHeader: styled.span`
    color: ${({ theme }) => theme.text.secondary};
    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      font-size: 18px;
      line-height: 22px;
    }
  `,
  HeaderContainer: styled.div`
    display: flex;
    gap: 48px;
  `,
  ContentWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    max-width: ${({ theme }) => theme.pineQuestionSectionMaxWidth};

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      gap: 24px;
    }
  `,
  DesktopView: styled.div`
    display: block;
    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      display: none;
    }
  `,
};

export default QuestionStyles;
