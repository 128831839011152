import styled from 'styled-components';

export const TooltipStyles = {
  Wrapper: styled.div`
    position: relative;
  `,

  TitleWrapper: styled.div`
    display: flex;
    svg {
      max-width: 16px;
      min-width: 16px;
      max-height: 16px;
      min-height: 16px;
      margin-right: 8px;
      position: relative;
      cursor: pointer;
    }
  `,

  Title: styled.h2`
    font-family: var(--font-tiempos);
    font-weight: 400;
    color: ${({ theme }) => theme.text.secondary};
    font-style: italic;
    font-size: 12px;
    line-height: 14px;
  `,

  ContentContainer: styled.div<{ open: boolean }>`
    display: ${({ open }) => (open ? 'block' : 'none')};
    z-index: ${({ open }) => (open ? 9999 : -1)};
    max-width: 400px;
    width: 100%;
    box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.15);
    border: 1px solid ${({ theme }) => theme.border.primary};
    background-color: ${({ theme }) => theme.background.primary};
    padding: 16px 32px;
    position: absolute;
    top: 40px;
    transition: all 0.3s ease-in-out;

    &:focus {
      outline: none;
      border: 1px solid ${({ theme }) => theme.border.active};
    }

    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      background-color: ${({ theme }) => theme.background.primary};
      top: -11px;
      transform: translateX(-50%) rotate(45deg);
      border-top: 1px solid ${({ theme }) => theme.border.primary};
      border-left: 1px solid ${({ theme }) => theme.border.primary};
    }
  `,

  Content: styled.p<{ whitespace?: boolean }>`
    display: inherit;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.text.secondary};
    margin-bottom: ${({ whitespace }) => (whitespace ? '8px' : 0)};
  `,

  Icon: styled.div`
    position: absolute;
    right: 16px;
    top: 16px;

    .closeBtn {
      width: 14px;
      height: 14px;
      cursor: pointer;
      outline: none;
    }
  `,
};
