export type PathType = 'fragment' | 'route';
export enum StepStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  INCOMPLETE = 'INCOMPLETE',
}

export interface Step {
  label: string;
  to?: string;
  current: boolean;
  status: StepStatus;
  totalProgress: number;
  currentProgress: number;
}
