import { Component, forwardRef } from 'react';
import { DefaultInputComponentProps, State } from 'react-phone-number-input';
import { Control } from 'react-hook-form';
import ReactPhoneInput, { Country, DefaultFormValues, Props } from 'react-phone-number-input/react-hook-form';
import noop from 'lodash/noop';
import Styled from './PhoneInput.styled';
import 'react-phone-number-input/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

type PhoneInputRefType = Component<
  Props<DefaultInputComponentProps, DefaultFormValues>,
  State<Props<DefaultInputComponentProps, DefaultFormValues>>
>;

interface PhoneInputProps {
  value: string;
  label?: string;
  id?: string;
  invalid?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  name: string;
  control: Control;
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  countries?: Country[];
  'data-testid'?: string;
}

const PhoneInput = forwardRef<PhoneInputRefType, PhoneInputProps>(
  (
    {
      value,
      onChange,
      label,
      name,
      invalid,
      disabled,
      errorMessage,
      placeholder,
      style,
      id,
      countries,
      control,
      wrapperStyle,
      'data-testid': dataTestId,
      onBlur,
    },
    ref
  ) => (
    <Styled.Wrapper>
      {label && <Styled.Label htmlFor={id}>{label}</Styled.Label>}
      <Styled.InputPhoneWrapper invalid={invalid} disabled={disabled} style={wrapperStyle}>
        <ReactPhoneInput
          id={id}
          countries={countries || ['US', 'CA']}
          defaultCountry="CA"
          limitMaxLength
          international={false}
          name={name}
          control={control}
          data-testid={dataTestId}
          addInternationalOption={false}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
          style={style}
          aria-invalid={invalid}
          aria-errormessage={`${id}-error`}
          ref={ref}
        />
        {invalid && (
          <Styled.ErrorIcon>
            <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
          </Styled.ErrorIcon>
        )}
      </Styled.InputPhoneWrapper>
      {invalid && <Styled.ErrorMessage id={`${id}-error`}>{errorMessage}</Styled.ErrorMessage>}
    </Styled.Wrapper>
  )
);

PhoneInput.displayName = 'PhoneInput';

PhoneInput.defaultProps = {
  invalid: false,
  disabled: false,
  errorMessage: '',
  placeholder: '',
  label: '',
  'data-testid': 'phone',
  id: 'phone-input',
  style: undefined,
  wrapperStyle: undefined,
  countries: undefined,
  onBlur: noop,
};

export default PhoneInput;
