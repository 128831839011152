import styled from 'styled-components';

const StyledAddress = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Wrapper: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px 48px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      grid-template-columns: 1fr;
    }
  `,
  Divider: styled.div`
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.border.primary};
    margin: 0 0 20px;
  `,
};

export default StyledAddress;
