import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { AnalyticsBrowser, Analytics } from '@segment/analytics-next';
import { createContext } from 'react';
import config from '@config';
import { useRouter } from 'next/router';

interface AnalyticsProviderProps {
  children: React.ReactNode;
}

interface AnalyticsContextProps {
  analytics: Analytics | undefined;
}

const defaultAnalyticsContext = {
  analytics: undefined,
};

const AnalyticsContext = createContext<AnalyticsContextProps>(defaultAnalyticsContext);

export const useAnalyticsContext = () => useContext(AnalyticsContext);

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({ children }) => {
  const mountRef = useRef(false);
  const router = useRouter();
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);

  const loadAnalytics = useCallback(async () => {
    if (!config.SEGMENT_ANALYTICS_WRITE_KEY || analytics) return;
    const [response] = await AnalyticsBrowser.load({ writeKey: config.SEGMENT_ANALYTICS_WRITE_KEY });
    setAnalytics(response);
  }, [analytics]);

  useEffect(() => {
    loadAnalytics();
  }, [loadAnalytics]);

  useEffect(() => {
    if (!analytics) return;

    const handleRouteChange = (url: string) => {
      if (!url) return;
      const pageName = url.substring(url.lastIndexOf('/') + 1);
      const traits = analytics?.user()?.traits();
      analytics.page(pageName, undefined, {
        dateViewed: new Date(),
        url: url,
        pageName: pageName,
        ...(traits?.email && { email: traits.email }),
      });
    };

    // ensure we capture utm query params on first page load
    // router.events only triggers on router navigation events (push/replace)
    if (!mountRef.current) {
      handleRouteChange(router.asPath);
      mountRef.current = true;
      return;
    }

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [analytics, router.asPath, router.events]);

  return <AnalyticsContext.Provider value={{ analytics }}>{children}</AnalyticsContext.Provider>;
};
