import { NAMESPACE } from '@models/enums';
import CurrencyInput from '@spruce/Inputs/Currency/CurrencyInput';
import { FunctionComponent, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { formatCurrency } from 'utils/common.utils';
import { MAX_ADDITIONAL_FUNDS, MAX_PROPERTY_VALUE } from './constants/loanAmount.constants';
import { StyledLoanAmount } from './LoanAmount.styles';
import { constructLoanAmount } from './utils/loanAmount.utils';
import { MINIMUM_AMOUNT } from '@/lib/constants/global.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';

export interface LoanAmountProps {
  refinance?: boolean;
}

const LoanAmount: FunctionComponent<LoanAmountProps> = ({ refinance }: LoanAmountProps) => {
  const { t } = useTranslation(NAMESPACE.DEFAULT);
  const {
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();
  const [estimatedValue, originalMortgageAmount, remainingMortgageAmount, additionalFundsAmount] = useWatch({
    control,
    name: ['estimatedValue', 'originalMortgageAmount', 'remainingMortgageAmount', 'additionalFundsAmount'],
  });
  // This hook is required as two inputs depend on each other, a change on one of them
  // should trigger validation on the other
  useEffect(() => {
    if (remainingMortgageAmount != null && estimatedValue != null) {
      trigger('remainingMortgageAmount');
    }
    if (additionalFundsAmount != null && estimatedValue != null) {
      trigger('additionalFundsAmount');
    }
  }, [remainingMortgageAmount, additionalFundsAmount, estimatedValue, trigger]);

  return (
    <StyledLoanAmount.Wrapper>
      <Controller
        control={control}
        name="estimatedValue"
        rules={{
          required: {
            value: true,
            message: t('LOAN_AMOUNT_VALUE_ERROR'),
          },
          max: {
            value: MAX_PROPERTY_VALUE,
            message: t('PROPERTY_VALUE_EXCEEDED'),
          },
          min: {
            value: MINIMUM_AMOUNT,
            message: t('MINIMUM_AMOUNT_ERROR', { ns: NAMESPACE.DEFAULT }),
          },
        }}
        render={({ field: { value, ref, onChange }, fieldState: { error } }) => (
          <CurrencyInput
            style={{
              marginBottom: '10px',
            }}
            name="estimatedValue"
            id="loan-amount-property-value"
            data-testid="loan-amount-property"
            label={t('LOAN_AMOUNT_PROPERTY_VALUE_LABEL')}
            invalid={!!error}
            value={value}
            onChange={onChange}
            errorMessage={error?.message}
            placeholder="$0"
            ref={ref}
          />
        )}
      />
      {refinance ? (
        <>
          <Controller
            control={control}
            name="remainingMortgageAmount"
            rules={{
              required: {
                value: true,
                message: t('LOAN_AMOUNT_VALUE_ERROR'),
              },
              max: {
                value: Number(getValues('estimatedValue')) * 0.8,
                message: t('REMAINING_MORTGAGE_BALANCE_ERROR_MAX'),
              },
            }}
            render={({ field: { value, ref, onChange, name }, fieldState: { error } }) => (
              <CurrencyInput
                style={{
                  marginBottom: '10px',
                }}
                name={name}
                id="remaining-mortgage-amount"
                data-testid="remaining-mortgage-amount"
                label={t('REMAINING_MORTGAGE_BALANCE')}
                invalid={!!error}
                value={value}
                onChange={onChange}
                placeholder="$0"
                errorMessage={error?.message}
                ref={ref}
              />
            )}
          />
          <Controller
            control={control}
            name="additionalFundsAmount"
            rules={{
              required: {
                value: true,
                message: t('LOAN_AMOUNT_VALUE_ERROR'),
              },
              max: {
                value: Number(getValues('estimatedValue'))
                  ? Math.min(Number(getValues('estimatedValue')) * 0.8 - Number(getValues('remainingMortgageAmount')), MAX_ADDITIONAL_FUNDS)
                  : MAX_ADDITIONAL_FUNDS,
                message: t(
                  Number(getValues('additionalFundsAmount')) > MAX_ADDITIONAL_FUNDS
                    ? 'ADDITIONAL_FUNDS_ERROR_MAX'
                    : 'ADDITIONAL_FUNDS_ERROR_SUM_MAX',
                  { value: formatCurrency(MAX_ADDITIONAL_FUNDS) }
                ),
              },
              min: {
                value: MINIMUM_AMOUNT,
                message: t('MINIMUM_AMOUNT_ERROR', { ns: NAMESPACE.DEFAULT }),
              },
            }}
            render={({ field: { value, ref, onChange, name }, fieldState: { error } }) => (
              <CurrencyInput
                style={{
                  marginBottom: '20px',
                }}
                name={name}
                id="additional-funds-amount"
                data-testid="additional-funds-amount"
                label={t('ADDITIONAL_FUNDS')}
                invalid={!!error}
                value={value}
                onChange={onChange}
                placeholder="$0"
                errorMessage={error?.message}
                ref={ref}
              />
            )}
          />
        </>
      ) : (
        <>
          <Controller
            control={control}
            name="originalMortgageAmount"
            rules={{
              required: {
                value: true,
                message: t('LOAN_AMOUNT_VALUE_ERROR'),
              },
              max: {
                value: Number(getValues('estimatedValue')),
                message: t('LOAN_AMOUNT_MORTGAGE_ERROR_MAX'),
              },
            }}
            render={({ field: { value, ref, onChange, name }, fieldState: { error } }) => (
              <CurrencyInput
                style={{
                  marginBottom: '20px',
                }}
                name={name}
                id="loan-amount-mortgage-value"
                data-testid="loan-mortgage-value"
                label={t('REMAINING_MORTGAGE_BALANCE')}
                placeholder="$0"
                invalid={!!error}
                value={value}
                onChange={onChange}
                errorMessage={error?.message}
                ref={ref}
              />
            )}
          />
        </>
      )}
      <StyledLoanAmount.Divider />
      <StyledLoanAmount.LoanAmountWrapper>
        <StyledLoanAmount.LoanAmount data-testid="loan-amount-label">
          {constructLoanAmount(originalMortgageAmount, remainingMortgageAmount, additionalFundsAmount) ||
            formatCurrency('0', { minimumFractionDigits: 2 })}
        </StyledLoanAmount.LoanAmount>
        <StyledLoanAmount.LoanAmountText id="loan_amount-label">{t('LOAN_AMOUNT')}</StyledLoanAmount.LoanAmountText>
        {errors.root?.type === 'min-loan-amount' && (
          <StyledLoanAmount.ErrorMessageWrapper>
            <FontAwesomeIcon icon={faCircleExclamation} />
            <StyledLoanAmount.ErrorMessage data-testid="min-loan-amount-required-error" role="alert">
              {t(errors.root?.message || '', { ns: NAMESPACE.DEFAULT })}
            </StyledLoanAmount.ErrorMessage>
          </StyledLoanAmount.ErrorMessageWrapper>
        )}
      </StyledLoanAmount.LoanAmountWrapper>
    </StyledLoanAmount.Wrapper>
  );
};

LoanAmount.defaultProps = {
  refinance: false,
};

export default LoanAmount;
