import { useFlags } from 'launchdarkly-react-client-sdk';
import { MAX_PERCENT_PURCHASE_PRICE, MIN_PERCENT_PURCHASE_PRICE, OLD_MAX_PERCENT_PURCHASE_PRICE } from '../models/constraints';
import { useTranslation } from 'next-i18next';
import { NAMESPACE } from '@models/enums';
import { useCallback } from 'react';

export const useValidateDownPayment = () => {
  const { enableNewInsuranceRules } = useFlags();
  const { t } = useTranslation(NAMESPACE.LONGAPP);

  const validateMinDownPayment = useCallback(
    (purchasePrice: number): { value: number; message: string } => {
      if (purchasePrice < MIN_PERCENT_PURCHASE_PRICE) {
        return {
          value: purchasePrice * 0.05,
          message: t('DOWNPAYMENT_ERROR', { percent: 5 }),
        };
      }

      const maxPercentPurchasePrice = enableNewInsuranceRules ? MAX_PERCENT_PURCHASE_PRICE : OLD_MAX_PERCENT_PURCHASE_PRICE;
      if (purchasePrice >= MIN_PERCENT_PURCHASE_PRICE && purchasePrice < maxPercentPurchasePrice) {
        const minPercentAmount = MIN_PERCENT_PURCHASE_PRICE * 0.05;
        const remainingPercentAmount = (purchasePrice - MIN_PERCENT_PURCHASE_PRICE) * 0.1;
        return {
          value: minPercentAmount + remainingPercentAmount,
          message: t('DOWNPAYMENT_ERROR_2'),
        };
      }

      return {
        value: purchasePrice * 0.2,
        message: t('DOWNPAYMENT_ERROR', { percent: 20 }),
      };
    },
    [enableNewInsuranceRules, t]
  );

  return {
    validateMinDownPayment,
  };
};
