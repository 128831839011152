import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { NAMESPACE } from '@models/enums/namespace.enum';
import { useTranslation } from 'next-i18next';
import ScreenReaderOnly from '@components/ScreenReaderOnly/ScreenReaderOnly';
import { Spinner } from '@pinecorpca/spruce';
import { StyledAutoComplete } from './AutoComplete.styles';
import { AddressDetails, AddressSuggestion, NextType } from './models/address-response.model';
import { retrieveAddress, findAddress } from './api/canada-post.api';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';

interface AutoCompleteProps {
  onChange: (address: AddressDetails) => void;
  placeholder: string;
  disabled?: boolean;
}

const AutoComplete = ({ onChange, placeholder, disabled }: AutoCompleteProps) => {
  const { t } = useTranslation(NAMESPACE.COMMON);
  const [suggestions, setSuggestions] = useState<AddressSuggestion[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const suggestionsRef = useRef<HTMLUListElement | null>(null);
  const searchRef = useRef<HTMLInputElement | null>(null);

  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (!suggestionsRef?.current?.contains(e?.target as Node)) setShowSuggestions(false);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, suggestionsRef]);

  const setSearchInput = (value: string) => {
    if (searchRef.current) {
      searchRef.current.value = value;
      searchRef.current.focus();
    }
  };

  const onClearClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setSearchInput('');
    setSuggestions([]);
    setShowSuggestions(false);
  };

  const onChangeEventHandler = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value?.length < 4) return;
    setLoading(true);
    const res = await findAddress(value);
    const { Items } = res.data;
    setSuggestions(Items);
    setShowSuggestions(true);
    setLoading(false);
  }, []);

  const onSelection = async (event: React.MouseEvent<HTMLElement, MouseEvent>, address: AddressSuggestion) => {
    event.preventDefault();
    if (!address) return;
    setLoading(true);
    if (address.Next === NextType.FIND) {
      const res = await findAddress(address.Text, address.Id);
      const { Items } = res.data;
      setSuggestions(Items);
      setShowSuggestions(true);
    } else {
      const res = await retrieveAddress(address);
      const { Items } = res.data;
      onChange(Items[0]);
      setSuggestions([]);
      setShowSuggestions(false);
      setSearchInput(Items[0].Line1);
    }
    setLoading(false);
  };

  return (
    <StyledAutoComplete.Wrapper>
      <StyledAutoComplete.InputWrapper>
        <StyledAutoComplete.SearchIconWrapper>
          <FontAwesomeIcon icon={faSearch} />
        </StyledAutoComplete.SearchIconWrapper>
        <StyledAutoComplete.Input
          disabled={disabled}
          ref={searchRef}
          spellCheck={false}
          autoComplete="off"
          id="search"
          name="search"
          type="text"
          data-testid="search"
          onChange={debounce((event) => onChangeEventHandler(event), 300)}
          placeholder={placeholder}
          onFocus={() => {
            if (suggestions.length > 0) setShowSuggestions(true);
          }}
        />
        <StyledAutoComplete.InputEventWrapper>
          {isLoading && (
            <>
              <Spinner />
              <ScreenReaderOnly>{t('SR_LOADING')}</ScreenReaderOnly>
            </>
          )}
          {searchRef?.current?.value && !isLoading && (
            <StyledAutoComplete.ClearButton type="button" data-testid="auto-complete-clear-cta" onClick={onClearClick}>
              <ScreenReaderOnly>{t('SR_CLEAR')}</ScreenReaderOnly>
              <Image src="/images/cross.svg" alt="" width={8} height={8} />
            </StyledAutoComplete.ClearButton>
          )}
        </StyledAutoComplete.InputEventWrapper>
      </StyledAutoComplete.InputWrapper>
      {suggestions.length > 0 && showSuggestions && (
        <StyledAutoComplete.ItemList ref={suggestionsRef} role="listbox">
          {suggestions.map((suggestion, index, { length }) => (
            <StyledAutoComplete.ListItem key={suggestion.Id}>
              <StyledAutoComplete.Item
                aria-setsize={length}
                aria-posinset={index + 1}
                type="button"
                tabIndex={0}
                data-testid={suggestion.Id}
                chevronRight="/images/chevronright.svg"
                showChevron={suggestion.Next === NextType.FIND}
                onClick={(event: any) => onSelection(event, suggestion)}
              >
                <StyledAutoComplete.Text>{suggestion.Text}</StyledAutoComplete.Text>
                {suggestion.Description}
              </StyledAutoComplete.Item>
            </StyledAutoComplete.ListItem>
          ))}
        </StyledAutoComplete.ItemList>
      )}
    </StyledAutoComplete.Wrapper>
  );
};

AutoComplete.defaultProps = {
  disabled: false,
};

export default AutoComplete;
