import { FlowState } from '@models/flowState.model';
import * as dateFns from 'date-fns';
import {
  AdditionalIncomeType,
  BusinessSelfEmployment,
  DetailedIncomeDto,
  Employment,
  IncomeType,
  OtherIncome,
  PropertyType,
  RentalIncome,
} from '@pinecorpca/evergreen';
import { differenceInMonths, isMatch, parse } from 'date-fns';
import { objectToNotation } from 'utils/common.utils';
import { patchIncomePayload } from './income-builders.utilts';
import { PathRequestBody } from '@models/patchRequestBody.model';
import { isBeforeOrEqual } from 'utils/date.utils';

export const isIncomeComplete = (income: DetailedIncomeDto): boolean => {
  const [year] = income.years || [];
  switch (income.incomeType) {
    case IncomeType.Employment:
      return !!(
        income.startDate &&
        (income.current || (!income.current && income.endDate)) &&
        (income.income as Employment)?.employerName &&
        (income.income as Employment)?.jobTitle &&
        year.annualAmount != null
      );
    case IncomeType.Selfemployment:
      return !!(
        income.startDate &&
        (income.current || (!income.current && income.endDate)) &&
        (income.income as BusinessSelfEmployment)?.businessName &&
        (!income.current || (income.current && (income.income as BusinessSelfEmployment)?.foundedDate)) &&
        year.grossRevenue &&
        (income?.income as BusinessSelfEmployment).businessType &&
        (income.income as BusinessSelfEmployment)?.jobTitle &&
        year.annualAmount != null
      );
    case IncomeType.Rental:
      return !!(
        income.startDate &&
        (income?.income as RentalIncome).property?.propertyType &&
        (income?.income as RentalIncome).property?.propertyAddress?.streetNumber &&
        (income?.income as RentalIncome).property?.propertyAddress?.streetName &&
        (income?.income as RentalIncome).property?.propertyAddress?.city &&
        (income?.income as RentalIncome).property?.propertyAddress?.province &&
        (income?.income as RentalIncome).property?.propertyAddress?.postalCode &&
        (income?.income as RentalIncome).propertyValue &&
        year.annualAmount != null
      );
    default: {
      const isStudentOrUnemployment = [AdditionalIncomeType.Student, AdditionalIncomeType.Unemployment].includes(
        (income?.income as any).type
      );
      return !!(
        income.startDate &&
        (income.current || (!income.current && income.endDate)) &&
        (income?.income as any).type &&
        (isStudentOrUnemployment || (!isStudentOrUnemployment && year.annualAmount != null))
      );
    }
  }
};

export const isValidLengthOfEmployment = (income: DetailedIncomeDto) => {
  if (!income.startDate) return false;
  const subThreeYears = dateFns.subYears(new Date(), 3).setDate(1);
  const subThreeYearsDate = new Date(subThreeYears).setHours(0, 0, 0, 0);
  const format = dateFns.isMatch(income.startDate, 'yyyy-MM-dd') ? 'yyyy-MM-dd' : 'dd/MM/yyyy';
  const parsedDate = dateFns.parse(income.startDate, format, new Date());
  return isBeforeOrEqual(parsedDate, new Date(subThreeYearsDate));
};

const isCurrentIncomesLargerThanRequiredLengthEmployment = (incomes: DetailedIncomeDto[]): boolean => {
  const isRentalOrIncome = incomes
    .filter((income) => isIncomeComplete(income))
    .some((income) => [IncomeType.Other, IncomeType.Rental].includes(income.incomeType as IncomeType) && income.current);
  if (isRentalOrIncome && incomes.length) return true;
  const incomeValid = incomes
    .filter(
      (income) =>
        income.current &&
        isIncomeComplete(income) &&
        [IncomeType.Employment, IncomeType.Selfemployment].includes(income.incomeType as IncomeType)
    )
    .find((income) => isValidLengthOfEmployment(income));
  return !!incomeValid;
};

export const currentIncomesSubmitHandler = (incomes: DetailedIncomeDto[]): string =>
  !incomes.filter((income) => income.current).length || isCurrentIncomesLargerThanRequiredLengthEmployment(incomes)
    ? FlowState.NEXTSECTION
    : 'previous_income_types';

export const isCurrentIncomesComplete = (incomes: DetailedIncomeDto[]): boolean => {
  const currentIncomes = incomes?.filter((income) => income.current);
  const incompleteIncome = currentIncomes?.find((income) => !isIncomeComplete(income));
  return !!currentIncomes?.length && !incompleteIncome;
};

export const isPreviousIncomesComplete = (incomes: DetailedIncomeDto[]): boolean => {
  const previousIncomes = incomes?.filter((income) => !income.current);
  const incompleteIncome = previousIncomes?.find((income) => !isIncomeComplete(income));
  const previousIncomeValid = previousIncomes?.find(
    (income) => isValidLengthOfEmployment(income) || income.incomeType === IncomeType.Other
  );
  return !!previousIncomes?.length && !!previousIncomeValid && !incompleteIncome;
};

export const formatDate = (date?: string) => {
  if (!date) return undefined;
  const format = dateFns.isMatch(date, 'yyyy-MM-dd') ? 'yyyy-MM-dd' : 'dd/MM/yyyy';
  return dateFns.parse(date, format, new Date());
};

export const timeAtEmployer = (startDate: string, endDate: string, isCurrent: boolean) => {
  const format = isMatch(startDate, 'yyyy-MM-dd') ? 'yyyy-MM-dd' : 'dd/MM/yyyy';
  const startDateAtEmployer = parse(startDate, format, new Date());
  const endDateAtEmployer = isCurrent ? new Date() : parse(endDate, format, new Date());
  return differenceInMonths(endDateAtEmployer, startDateAtEmployer).toString();
};

export const totalCalculatedIncome = (incomes: DetailedIncomeDto[]) =>
  incomes?.reduce((acc, curr) => {
    const { incomeType, years } = curr;
    const [year] = years || [];
    if (incomeType === IncomeType.Rental) {
      acc += Number(year.annualAmount) ? Number(year.annualAmount) * 12 : 0;
    } else {
      acc += Number(year.annualAmount) || 0;
    }
    // since Employment is the only type with 'bonus' we check for that property only
    if (year && 'bonus' in year) {
      const { bonus, overtime, commission } = year;
      acc += (Number(bonus) || 0) + (Number(overtime) || 0) + (Number(commission) || 0);
    }
    return acc;
  }, 0);

const payloadHelper = (item: PathRequestBody, income: DetailedIncomeDto) => {
  switch (income.incomeType) {
    case IncomeType.Other: {
      const { type } = income.income as OtherIncome;
      if (
        item.path.includes('years/0/annualAmount') &&
        [AdditionalIncomeType.Unemployment, AdditionalIncomeType.Student].includes(type as AdditionalIncomeType)
      ) {
        return { op: 'replace', path: '/years/0/annualAmount', value: null };
      }
    }
    case IncomeType.Rental: {
      const { property } = income.income as RentalIncome;
      if (
        item.path.includes('monthlyExpenses') &&
        ![PropertyType.RowHousing, PropertyType.ApartmentHighRise].includes(property?.propertyType as PropertyType)
      ) {
        return { op: 'replace', path: '/income/monthlyExpenses', value: null };
      }
    }
    default:
      return item;
  }
};

export const buildIncomePatchPayload = (income: DetailedIncomeDto) => {
  const incomePayload = patchIncomePayload(income);
  const flattenedNotationObject = objectToNotation(incomePayload);
  return Object.entries(flattenedNotationObject)
    .map(([key, value]) => ({ op: 'replace', path: key, value }))
    .map((item) => payloadHelper(item, income));
};
