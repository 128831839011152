import React, { FunctionComponent, useState } from 'react';
import noop from 'lodash/noop';
import { ShortAppCompleteDto } from '@pinecorpca/evergreen';

export type ShortAppContextType = {
  body: ShortAppCompleteDto;
  updateBody: (body: ShortAppCompleteDto) => void;
  replaceBody: (body: ShortAppCompleteDto) => void;
};

export const ShortAppContext = React.createContext<ShortAppContextType>({
  body: {},
  updateBody: () => noop,
  replaceBody: () => noop,
});

export const useShortAppContext = () => React.useContext(ShortAppContext);
export const ShortAppProvider: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const [body, setBody] = useState({});
  return (
    <ShortAppContext.Provider
      value={{
        body,
        updateBody: (requestBody: ShortAppCompleteDto) => setBody((prev: ShortAppCompleteDto) => ({ ...prev, ...requestBody })),
        replaceBody: (requestBody: ShortAppCompleteDto) => setBody(requestBody),
      }}
    >
      {children}
    </ShortAppContext.Provider>
  );
};
