import { API, LONG_APP_ROUTES } from '@models/enums';
import { FlowRoot } from '@models/flow.model';
import { FlowState } from '@models/flowState.model';
import { MaritalStatusOptions } from '@models/marital-status.constants';
import { PATTERNS } from '@models/patterns.constants';
import { DetailedBorrowerFullDto } from '@pinecorpca/evergreen';
import { isMatch, parse, subYears } from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { isAfterOrEqual } from 'utils/date.utils';
import BorrowerAddress from '../components/BorrowerAddress';
import { DwellingStatusOptions } from './dwelling-options.model';
import { orderedRelationships } from '../constants/borrower.constants';

export const BorrowerInfoForm: FlowRoot = {
  root: 'borrower_personal_info',
  id: 'borrower_info',
  pageTitle: 'BORROWER_INFO',
  flowName: 'borrower',
  nextSecUrl: `/applications/:applicationId/borrowers/:borrowerId/${LONG_APP_ROUTES.INCOMES}`,
  borrower_personal_info: {
    id: 'borrower_personal_info',
    type: 'form',
    header: 'BORROWER_INFO_APPLYING',
    next: 'borrower_current_address',
    api: API.BORROWER,
    style: { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '14px 48px' },
    inputs: [
      {
        id: 'first_name',
        label: 'APPLICANT_FIRST_NAME',
        type: 'input',
        fieldName: 'firstName',
        placeholder: 'APPLICANT_FIRST_NAME_PLACEHOLDER',
        rules: {
          required: {
            value: true,
            message: 'FIRST_NAME_REQUIRED',
          },
          maxLength: { value: 75, message: 'MAXIMUM_LENGTH_EXCEEDED' },
        },
      },
      {
        id: 'middle_initial',
        label: 'APPLICANT_MIDDLE_INITIAL',
        type: 'input',
        fieldName: 'middleName',
      },
      {
        id: 'last_name',
        label: 'APPLICANT_LAST_NAME',
        type: 'input',
        fieldName: 'lastName',
        placeholder: 'APPLICANT_LAST_NAME_PLACEHOLDER',
        rules: {
          required: {
            value: true,
            message: 'LAST_NAME_REQUIRED',
          },
          maxLength: { value: 75, message: 'MAXIMUM_LENGTH_EXCEEDED' },
        },
      },
      {
        id: 'phone_number',
        label: 'APPLICANT_CONTACT_PHONE',
        type: 'phone',
        fieldName: 'phoneNumber',
        placeholder: 'APPLICANT_CONTACT_PHONE_PLACEHOLDER',
        rules: {
          required: {
            value: true,
            message: 'PHONE_REQUIRED',
          },
          validate: (value: string) => isPossiblePhoneNumber(value) || 'PHONE_INVALID',
        },
      },
      {
        id: 'date-of-birth',
        label: 'DATE_OF_BIRTH',
        type: 'dateOfBirth',
        fieldName: 'dateOfBirth',
        rules: {
          required: {
            value: true,
            message: 'DATE_OF_BIRTH_REQUIRED',
          },
          validate: (value: string) => new Date(value) <= subYears(new Date(), 18) || 'DATE_OF_BIRTH_AGE_REQUIREMENT',
        },
      },
      {
        id: 'marital_status',
        label: 'MARITAL',
        type: 'select',
        fieldName: 'maritalStatus',
        placeholder: 'MARITAL_PLACEHOLDER',
        options: MaritalStatusOptions,
        rules: {
          required: {
            value: true,
            message: 'MARITAL_REQUIRED',
          },
        },
      },
      {
        id: 'contactEmail',
        label: 'FORM_EMAIL',
        type: 'input',
        fieldName: 'contactEmail',
        placeholder: 'FORM_EMAIL_PLACEHOLDER',
        rules: {
          required: {
            value: true,
            message: 'EMAIL_REQUIRED',
          },
          pattern: {
            value: PATTERNS.EMAIL,
            message: 'FORM_EMAIL_INVALID',
          },
        },
      },
      {
        id: 'relationship',
        label: 'RELATIONSHIP',
        type: 'select',
        fieldName: 'relationship',
        placeholder: 'RELATIONSHIP_PLACEHOLDER',
        options: orderedRelationships.map((value) => ({
          id: value,
          value,
          label: `RELATIONSHIP_${value}`,
        })),
        rules: {
          required: {
            value: true,
            message: 'FORM_RELATIONSHIP_REQUIRED',
          },
        },
      },
    ],
  },
  borrower_current_address: {
    id: 'borrower_current_address',
    type: 'customized',
    component: BorrowerAddress,
    api: API.ADDRESS,
    dataPath: API.BORROWER,
    header: 'BORROWER_INFO_CURRENT_ADDRESS',
    next: 'current_address_moveIn',
    label: 'CURRENT_ADDRESS',
    isCompleteHelper: (borrower: DetailedBorrowerFullDto) => {
      const { addressHistories } = borrower ?? {};
      return !!addressHistories?.length;
    },
  },
  current_address_moveIn: {
    id: 'current_address_moveIn',
    header: 'BORROWER_INFO_MOVE_IN_DATE',
    type: 'form',
    next: 'dwelling_status',
    api: API.ADDRESS,
    inputs: [
      {
        id: 'moveIn-date',
        label: 'BORROWER_INFO_MOVE_IN_LABEL',
        type: 'date',
        attributes: { picker: 'month', readOnly: true },
        to: new Date(),
        from: subYears(new Date(), 100),
        fieldName: 'moveInDate',
        secondaryFieldName: 'current.moveInDate',
        rules: {
          required: {
            value: true,
            message: 'MOVEIN_DATE_REQUIRED',
          },
        },
      },
    ],
  },
  dwelling_status: {
    id: 'dwelling_status',
    header: 'BORROWER_INFO_DWELLING_STATUS',
    type: 'options',
    next: FlowState.DYNAMIC,
    fieldName: 'dwellingStatus',
    api: API.BORROWER,
    options: cloneDeep(DwellingStatusOptions),
    submitHandler: (args) => {
      if (!args?.moveInDate) return FlowState.NEXTSECTION;
      const threeYearsAgo = subYears(new Date(), 3);
      const format = isMatch(args.moveInDate, 'yyyy-MM-dd') ? 'yyyy-MM-dd' : 'dd/MM/yyyy';
      const withInThreeYears = isAfterOrEqual(parse(args.moveInDate, format, new Date()), threeYearsAgo);
      return withInThreeYears ? 'previous_address' : FlowState.NEXTSECTION;
    },
    dataPath: `${API.ADDRESS}.current`,
    tagLabel: 'LIVING_ARRANGEMENTS',
  },
  previous_address: {
    id: 'previous_address',
    header: 'BORROWER_INFO_PREVIOUS_ADDRESS_TITLE',
    type: 'form',
    next: FlowState.NEXTSECTION,
    api: API.ADDRESS,
    inputs: [
      {
        id: 'previous_address_form',
        label: 'PREVIOUS_ADDRESS',
        type: 'address',
        fieldName: 'address',
        secondaryFieldName: 'previous.address',
      },
    ],
  },
};
