import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode, useState } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import { API } from '@models/enums';

interface CustomQueryClientProviderProps {
  children: ReactNode;
}

export function CustomQueryClientProvider({ children }: CustomQueryClientProviderProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            retryOnMount: false,
            retry: false,
            throwOnError(error, query) {
              // this will be caught by the global error boundary
              // where the user can retry via "try again" button
              // for now, we'll only catch 400 and 500 errors for entities as this is usually retrieve via the users endpoint
              if (axios.isAxiosError(error) && query.queryKey.includes(API.ENTITIES)) {
                return error.response?.status === 400 || (error.response?.status || 0) >= 500;
              }
              return false;
            },
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      {children}
    </QueryClientProvider>
  );
}
