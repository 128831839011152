import styled from 'styled-components';

const StyledErrorPage = {
  ErrorWrapper: styled.div`
    display: flex;

    padding: 22px;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.background.secondary};
    width: 100vw;
    height: 100vh;
  `,
  ErrorContainer: styled.div`
    display: flex;
    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      flex-direction: column;
    }
  `,
  MessageContainer: styled.div`
    max-width: 414px;
    align-self: flex-start;
    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      width: 100%;
    }
  `,
  ErrorLink: styled.button`
    all: unset;
    cursor: pointer;
    font-family: var(--font-futura);
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.text.primary};

    background-color: ${({ theme }) => theme.background.primary};
    border: 1px solid ${({ theme }) => theme.border.primary};
    box-sizing: border-box;
    border-radius: 5px;
    padding: 4px 12px;

    &:hover {
      background-color: ${({ theme }) => theme.background.invert};
      color: ${({ theme }) => theme.text.invert};
    }

    transition: all 0.3s ease;
  `,
  UnderLine: styled.div`
    position: relative;
    left: 40px;
    width: 4px;
    height: 74px;
    transform: rotate(90deg);
    background-color: ${({ theme }) => theme.background.invert};
  `,
  ErrorMessage: styled.h1`
    font-family: var(--font-tiempos);
    font-weight: 300;
    font-size: 50px;
    line-height: 64px;
    color: ${({ theme }) => theme.text.primary};
    margin-bottom: 10px;
  `,
  ErrorImage: styled.div`
    position: relative;
    width: 50vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    top: 200px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      width: 90vw;
      top: 0;
    }
  `,
};

export default StyledErrorPage;
