import { Reducer } from 'react';
import { Step } from '../models/stepIndicator.model';
import { IndicatorType } from './IndicatorContext';

export enum ReducerActionType {
  SET_STEPS = 'SET_STEPS',
}

export type ReducerAction = { type: ReducerActionType.SET_STEPS; steps: Step[] };

export const indicatorReducer: Reducer<IndicatorType, ReducerAction> = (state, action) => {
  const { type } = action;

  switch (type) {
    case ReducerActionType.SET_STEPS:
      return { ...state, steps: action.steps, isReady: !!action.steps.length };
    default:
      return state;
  }
};
