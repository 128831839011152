import { OptionStruct } from '@models/option.model';
import { EmploymentType, IncomeType } from '@pinecorpca/evergreen';

export const incomeTypes: OptionStruct[] = [
  {
    id: EmploymentType.FullTime,
    value: EmploymentType.FullTime,
    label: 'INCOMES_EMPLOYMENT_FULLTIME',
  },
  {
    id: EmploymentType.PartTime,
    value: EmploymentType.PartTime,
    label: 'INCOMES_EMPLOYMENT_PARTTIME',
  },
  {
    id: IncomeType.Selfemployment,
    value: IncomeType.Selfemployment,
    label: 'INCOMES_SELFEMPLOYED',
  },
  {
    id: IncomeType.Rental,
    value: IncomeType.Rental,
    label: 'INCOMES_RENTAL',
  },
  {
    id: IncomeType.Other,
    value: IncomeType.Other,
    label: 'INCOMES_OTHER',
  },
];

export const formatOptionLabel = ({ label, id }: OptionStruct, current: boolean, isPrimary: boolean) => {
  if ((current && isPrimary) || id == IncomeType.Other) return label;
  if (current && !isPrimary) return `${label}_CO`;
  if (!current && isPrimary) return `${label}_PAST`;
  return `${label}_PAST_CO`;
};
