export const generateLoginUrl = async (redirectUri: string, unblock?: string): Promise<string | null> => {
  try {
    const queryString = new URLSearchParams({
      callbackUri: `${process.env.EVERGREEN_BASE_URL}/oauth_callback`,
      redirectUri,
      ...(unblock && { unblock }),
    }).toString();

    const url = `${process.env.EVERGREEN_BASE_URL}/api/users/login-url?${queryString}`;
    const response = await fetch(url);
    const route = await response.text();
    return response.ok ? route : null;
  } catch (_) {
    return null;
  }
};
