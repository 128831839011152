import React, { createContext, FunctionComponent, ReactNode, useContext, useEffect, useReducer } from 'react';
import { Step } from '../models/stepIndicator.model';
import { indicatorReducer, ReducerAction, ReducerActionType } from './indicatorReducer';

export type IndicatorType = {
  steps: Step[];
  isReady: boolean;
};

export type IndicatorContextType = {
  state: IndicatorType;
  dispatch: React.Dispatch<ReducerAction>;
};

export const IndicatorContext = createContext<IndicatorContextType>({
  state: { steps: [], isReady: false },
  dispatch: () => null,
});

export const useStepIndicator = () => useContext(IndicatorContext);

export const IndicatorContextProvider: FunctionComponent<{ children: ReactNode; steps: Step[] }> = ({ children, steps }) => {
  const [state, dispatch] = useReducer(indicatorReducer, { steps: [], isReady: false });
  useEffect(() => {
    if (!steps.length) return;
    dispatch({ type: ReducerActionType.SET_STEPS, steps });
  }, [steps]);

  return <IndicatorContext.Provider value={{ state, dispatch }}>{children}</IndicatorContext.Provider>;
};
