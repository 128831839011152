import { API } from '@models/enums/api.enum';
import { useQuery } from '@tanstack/react-query';
import { ratesApi } from 'api/evergreen';
import { useRouter } from 'next/router';

export const useBestRatesQuery = (applicationId: string) =>
  useQuery({
    queryKey: [API.BEST_RATES, applicationId],
    queryFn: async () => {
      const { data } = await ratesApi.apiApplicationApplicationIdBestRatesGet({ applicationId });
      return data;
    },
    enabled: !!applicationId,
  });

export const useBestRateRebateQuery = () => {
  const {
    query: { applicationId },
  } = useRouter();
  return useQuery({
    queryKey: [API.BEST_RATES_REBATE, applicationId],
    queryFn: async () => {
      const { data } = await ratesApi.apiPublicWsAumDiscountsGet();
      return data;
    },
  });
};
