import styled from 'styled-components';

const TypeFormsStyles = {
  Form: styled.form`
    display: flex;
    flex-direction: column;
    gap: 25px;

    margin-bottom: 20px;
  `,
  TotalDl: styled.dl`
    margin: 0;

    display: flex;
    flex-direction: column;
    padding: 32px;
    border: 1px solid ${({ theme }) => theme.border.primary};
    border-radius: 8px;
    background-color: ${({ theme }) => theme.background.primary};

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      padding: 24px;
    }
  `,
  TotalDd: styled.dd`
    font-family: var(--font-tiempos);
    font-weight: 400;
    font-size: 36px;
    line-height: 41px;
    margin: 0;
  `,
  TotalDt: styled.dt`
    font-family: var(--font-futura);
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
    color: ${({ theme }) => theme.text.primary};
  `,
  FormGroupWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
  `,
  FormList: styled.ol`
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 15px;
  `,
  FormListItem: styled.li`
    list-style-type: none;
    min-height: 228px;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
    background: ${({ theme }) => theme.background.primary};
    border: 1px solid ${({ theme }) => theme.border.primary};
    padding: 24px 24px 32px;
  `,
};

export default TypeFormsStyles;
