import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import ErrorModal from '@components/ErrorModal/ErrorModal';
import { useTranslation } from 'next-i18next';
import { NAMESPACE } from '@models/enums';

const MaintenanceToast = () => {
  const { enableMaintenanceMode } = useFlags();
  const { t } = useTranslation(NAMESPACE.DEFAULT);
  const mountRef = useRef(false);

  useEffect(() => {
    if (mountRef.current || !enableMaintenanceMode) return;

    toast.warn(
      <ErrorModal
        data-testid="maintenance-toast"
        header={t('MAINTENANCE_MODE_HEADER')}
        content={t('MAINTENANCE_MODE_CONTENT')}
        headerStyle={{ fontSize: 16, fontWeight: 400 }}
        contentStyle={{ fontSize: 14, fontWeight: 300 }}
      />,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        toastId: `error-modal-${new Date().getTime()}`,
        style: { zIndex: 999999, top: 60 },
      }
    );
    mountRef.current = true;
  }, [t, enableMaintenanceMode]);

  return null;
};

export default MaintenanceToast;
