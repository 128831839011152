import { Step, StepStatus } from '@components/StepIndicator';
import { LONG_APP_ROUTES, ROUTES } from '@models/enums';
import { BorrowerType, DealPurpose, MortgageApplicationClientDto } from '@pinecorpca/evergreen';
import partition from 'lodash/partition';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { getLastCompletedSection, isApplicantInfoCompletedHelper, isPropertyCompletedHelper } from 'utils';
import { getApplicantProgress } from './construct-steps.util';

export const useConstructSteps = (application: MortgageApplicationClientDto | undefined) => {
  const { asPath } = useRouter();

  const [[primaryBorrower], coBorrowers] = partition(application?.borrowers, (borrower) => borrower.borrowerType === BorrowerType.Primary);

  const propertyStep = useMemo<Step>(() => {
    const helper = isPropertyCompletedHelper(application);
    const path = `/${ROUTES.APPLICATIONS}/${application?.id}/${LONG_APP_ROUTES.PROPERTY}`;
    const ratesPath = `/${ROUTES.APPLICATIONS}/${application?.id}/${LONG_APP_ROUTES.RATES}`;
    return {
      label: 'STEP_INDICATOR_PROPERTY',
      to: path,
      status: helper.completed ? StepStatus.COMPLETED : asPath.includes(path) ? StepStatus.IN_PROGRESS : StepStatus.INCOMPLETE,
      current: [ratesPath, path].includes(asPath),
      currentProgress: helper.progress || 0,
      totalProgress: helper.total || 0,
    };
  }, [application, asPath]);

  const primaryApplicantStep = useMemo<Step>(() => {
    const helper = getApplicantProgress(application?.dealPurpose as DealPurpose, primaryBorrower);
    const path = `/${ROUTES.APPLICATIONS}/${application?.id}/${ROUTES.BORROWERS}/${primaryBorrower?.id}`;
    return {
      label: 'STEP_INDICATOR_APPLICANT',
      to: `${path}/${LONG_APP_ROUTES.APPLICANT_INFO}`,
      status: helper.completed ? StepStatus.COMPLETED : asPath.includes(path) ? StepStatus.IN_PROGRESS : StepStatus.INCOMPLETE,
      current: asPath.includes(path),
      currentProgress: helper.progress || 0,
      totalProgress: helper.total || 0,
    };
  }, [primaryBorrower, application, asPath]);

  const coBorrowerStep = useMemo<Step>(() => {
    /**
     * CoBorrowers is optional, and we provide an option within coborrowers to indicate the user is a single applicant
     * we use `isReviewPath` to complete this step/add arbitrary progress
     */
    const isReviewPath = asPath.includes(`/${ROUTES.APPLICATIONS}/${application?.id}/${LONG_APP_ROUTES.REVIEW}`);

    const path = `/${ROUTES.APPLICATIONS}/${application?.id}/${LONG_APP_ROUTES.ADDITIONAL_BORROWERS}`;
    const isCurrent =
      asPath.includes(path) ||
      coBorrowers.some((borrower) => asPath.includes(`/${ROUTES.APPLICATIONS}/${application?.id}/${ROUTES.BORROWERS}/${borrower.id}`));
    const isCompleted =
      (coBorrowers.length && coBorrowers.every((borrower) => isApplicantInfoCompletedHelper(borrower).completed)) || isReviewPath;

    const progress = coBorrowers.reduce(
      (acc, borrower) => {
        const helper = getApplicantProgress(application?.dealPurpose as DealPurpose, borrower);
        return {
          totalProgress: acc.totalProgress + (helper?.total || 0),
          currentProgress: acc.currentProgress + (helper?.progress || 0),
        };
      },
      { totalProgress: 0, currentProgress: 0 }
    );

    return {
      label: 'STEP_INDICATOR_COBORROWER',
      to: path,
      status: isCompleted ? StepStatus.COMPLETED : asPath.includes(path) ? StepStatus.IN_PROGRESS : StepStatus.INCOMPLETE,
      current: isCurrent,
      currentProgress: progress.currentProgress || 0,
      totalProgress: progress.totalProgress === 0 && !isReviewPath ? 10 : progress.totalProgress,
    };
  }, [application?.dealPurpose, application?.id, asPath, coBorrowers]);

  const reviewStep = useMemo<Step>(() => {
    const path = `/${ROUTES.APPLICATIONS}/${application?.id}/${LONG_APP_ROUTES.REVIEW}`;
    const lastCompletedStep = getLastCompletedSection(application);
    return {
      label: 'STEP_INDICATOR_REVIEW',
      to: path,
      status: lastCompletedStep.path.includes(path) ? StepStatus.COMPLETED : StepStatus.INCOMPLETE,
      current: asPath.includes(path),
      totalProgress: 1,
      currentProgress: 1,
    };
  }, [application, asPath]);

  return useMemo(
    () => [propertyStep, primaryApplicantStep, coBorrowerStep, reviewStep],
    [propertyStep, primaryApplicantStep, coBorrowerStep, reviewStep]
  );
};
