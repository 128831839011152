import { useStepIndicator } from './state/IndicatorContext';
import IndicatorItem from './IndicatorItem';
import { Step } from './models/stepIndicator.model';
import Styled from './StepIndicator.styles';
import { useEffect, useRef } from 'react';

const StepIndicator = () => {
  const progressLineRef = useRef<HTMLDivElement>(null);
  const baseProgressBarRef = useRef<HTMLDivElement>(null);
  const { state } = useStepIndicator();

  useEffect(() => {
    const total = state.steps.reduce((acc, step) => acc + step.totalProgress, 0);
    const current = state.steps.reduce((acc, step) => acc + step.currentProgress, 0);
    const baseBarHeight = baseProgressBarRef.current?.clientHeight || 0;
    const progressLineHeight = (current * baseBarHeight) / total;
    const newHeight = progressLineHeight >= baseBarHeight ? baseBarHeight : progressLineHeight;
    if (progressLineRef.current) progressLineRef.current.style.height = `${newHeight}px`;
  }, [state.steps]);

  return (
    <Styled.Nav>
      <Styled.ProgressIndicatorLine ref={progressLineRef} />
      <Styled.BaseProgressBar ref={baseProgressBarRef} />
      <Styled.List>
        {state.steps.map((step: Step) => (
          <Styled.ListItem key={step?.to || step.label}>
            <IndicatorItem step={step} />
          </Styled.ListItem>
        ))}
      </Styled.List>
    </Styled.Nav>
  );
};

export default StepIndicator;
