import styled from 'styled-components';

const FormStyles = {
  FormWrapper: styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
  `,

  SubFormWrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px 48px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 24px;
    }
  `,
  FormTitleWrapper: styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid ${({ theme }) => theme.border.primary};
    padding-bottom: 32px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      padding-bottom: 16px;
    }
  `,
  FormTitle: styled.h2`
    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: ${({ theme }) => theme.text.primary};
  `,
  FormSubtitle: styled.h4`
    font-family: var(--font-futura);
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    border-top: 1px solid ${({ theme }) => theme.border.primary};
    padding-top: 16px;
  `,
  FormCue: styled.p`
    color: ${({ theme }) => theme.text.secondary};
    font-family: var(--font-futura);
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
  `,
  FormRow: styled.div`
    grid-column: 1/-1;
    > div {
      flex: 1 1 100%;
    }

    @media only screen and (max-width: 960px) {
      flex-direction: column;
      align-items: unset;
      gap: 20px;
    }
  `,
  DummyField: styled.div`
    width: 100%;
  `,
};

export default FormStyles;
