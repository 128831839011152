import { ReactNode, forwardRef } from 'react';
import Styled from './AddButton.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-light-svg-icons';

interface AddButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  'data-testid'?: string;
  children: ReactNode;
}

const AddButton = forwardRef<HTMLButtonElement, AddButtonProps>(({ onClick, 'data-testid': dataTestId, children }, ref) => (
  <Styled.Button ref={ref} onClick={onClick} data-testid={dataTestId}>
    <FontAwesomeIcon icon={faCirclePlus} size="sm" />
    {children}
  </Styled.Button>
));

AddButton.displayName = 'AddButton';

export default AddButton;
