export const calculateLoanAmount = (val1: string, val2: string): string => {
  if (val1 == null || val2 == null) return '';
  return (Number(val1) - Number(val2)).toString();
};

export const calculateTotalDownPayment = (val1: string, val2: string): string => {
  if (val1 == null || val2 == null) return '';
  return ((Number(val1) * Number(val2)) / 100).toString();
};

export const formatPercent = (purchasePrice: string, totalDownPayment: string): string => {
  if (totalDownPayment == null || purchasePrice == null) return '';
  return ((Number(totalDownPayment) / Number(purchasePrice)) * 100).toFixed(2);
};
