import { PARTNER_IMAGE_KEY } from '@/lib/constants';
import { OptionStruct } from '@models/option.model';
import { PropertyType } from '@pinecorpca/evergreen';
import Image from 'next/image';

export const PropertyOccupationType: OptionStruct[] = [
  {
    id: 'OWNER_OCCUPIED',
    value: 'owner-occupied',
    label: 'FLOW_OCCUPATION_OCCUPIED',
  },
  {
    id: 'RENTAL',
    value: 'rental',
    label: 'FLOW_OCCUPATION_RENTAL',
    invalid: true,
    errorMessage: 'FLOW_REFINANCE_PROPERTY_USAGE_INVESTMENT_ERROR',
  },
  {
    id: 'SECOND_HOME',
    value: 'second-home',
    label: 'FLOW_OCCUPATION_SECOND',
  },
];

export const PropertyTypeOptions: OptionStruct[] = [
  {
    id: 'DETACHED',
    value: PropertyType.Detached,
    label: 'FLOW_PROPERTY_DETACHED',
    img: (
      <Image
        alt=""
        src={`/images/${PARTNER_IMAGE_KEY}/detached.svg`}
        onError={(e) => (e.currentTarget.src = '/images/detached.svg')}
        width={96}
        height={96}
      />
    ),
    style: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  },
  {
    id: 'SEMI_DETACHED',
    value: PropertyType.SemiDetached,
    label: 'FLOW_PROPERTY_SEMI_DETACHED',
    img: (
      <Image
        alt=""
        src={`/images/${PARTNER_IMAGE_KEY}/semi-detached.svg`}
        onError={(e) => (e.currentTarget.src = '/images/semi-detached.svg')}
        width={96}
        height={96}
      />
    ),
    style: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  },
  {
    id: 'ROW_HOUSING',
    value: PropertyType.RowHousing,
    label: 'FLOW_PROPERTY_TOWNHOUSE',
    img: (
      <Image
        alt=""
        src={`/images/${PARTNER_IMAGE_KEY}/townhouse.svg`}
        onError={(e) => (e.currentTarget.src = '/images/townhouse.svg')}
        width={96}
        height={96}
      />
    ),
    style: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  },
  {
    id: 'APARTMENT_HIGH_RISE',
    value: PropertyType.ApartmentHighRise,
    label: 'FLOW_PROPERTY_CONDO',
    img: (
      <Image
        alt=""
        src={`/images/${PARTNER_IMAGE_KEY}/apartment.svg`}
        onError={(e) => (e.currentTarget.src = '/images/apartment.svg')}
        width={96}
        height={96}
      />
    ),
    style: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  },
];

export const PurchaseIntention: OptionStruct[] = [
  {
    id: 'ASAP',
    value: 'asap',
    label: 'FLOW_PURCHASE_ASAP',
  },
  {
    id: 'SOON',
    value: 'within-3-6-months',
    label: 'FLOW_PURCHASE_SOON',
  },
  {
    id: 'LATER',
    value: '6-plus-months',
    label: 'FLOW_PURCHASE_LATER',
  },
];
