import { OptionStruct } from '@models/option.model';
import { DownPaymentSource } from '@pinecorpca/evergreen';

export const DownPaymentSourceOptions: OptionStruct[] = [
  {
    id: 'PERSONAL_CASH',
    value: DownPaymentSource.PersonalCash,
    label: 'ASSETS_CASH_SAVINGS',
  },
  {
    id: 'RRSP',
    value: DownPaymentSource.Rrsp,
    label: 'ASSETS_RRSP',
  },
  {
    id: 'GIFT',
    value: DownPaymentSource.Gift,
    label: 'ASSETS_GIFT',
  },
  {
    id: 'SALES_OF_EXISTING_PROPERTY',
    value: DownPaymentSource.SalesOfExistingProperty,
    label: 'ASSETS_SALES_EXISTING_PROPERTIES',
  },
  {
    id: 'OTHER',
    value: DownPaymentSource.Other,
    label: 'ASSETS_OTHER',
  },
];
