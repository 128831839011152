import { EmploymentType, IncomeType } from '@pinecorpca/evergreen';
import { ReactNode } from 'react';
import TypeFormsStyles from '@containers/long-app/styles/TypeForms.styles';
import FormStyles from '@containers/long-app/styles/Form.styles';
import EmploymentFormSkeleton from '../employmentForm/EmploymentFormSkeleton';
import OtherFormSkeleton from '../otherForm/OtherFormSkeleton';
import RentalFormSkeleton from '../rentalForm/RentalFormSkeleton';
import SelfEmployedFormSkeleton from '../selfEmploymentForm/SelfEmployedFormSkeleton';
import { SkeletonType } from '../../../models/income.models';

interface SkeletonProps {
  skeletonType: SkeletonType;
}

const Skeleton = ({ skeletonType }: SkeletonProps) => {
  const renderSkeleton = (): ReactNode => {
    switch (skeletonType) {
      case EmploymentType.FullTime:
      case EmploymentType.PartTime:
        return <EmploymentFormSkeleton />;
      case IncomeType.Selfemployment:
        return <SelfEmployedFormSkeleton />;
      case IncomeType.Rental:
        return <RentalFormSkeleton />;
      default:
        return <OtherFormSkeleton />;
    }
  };

  return (
    <FormStyles.FormWrapper style={{ padding: 0, marginBottom: '20px' }}>
      <TypeFormsStyles.Container style={{ padding: '20px' }}>{renderSkeleton()}</TypeFormsStyles.Container>
    </FormStyles.FormWrapper>
  );
};

export default Skeleton;
