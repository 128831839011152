import styled from 'styled-components';

export const H1 = styled.h1`
  font-family: var(--font-tiempos);
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
`;

export const H2 = styled.h2`
  font-family: var(--font-tiempos);
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
`;

export const H3 = styled.h3`
  font-family: var(--font-tiempos);
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
`;

export const H4 = styled.h4`
  font-family: var(--font-tiempos);
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
`;

export const H5 = styled.h5`
  font-family: var(--font-tiempos);
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
`;

export const H6 = styled.h6`
  font-family: var(--font-tiempos);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;
