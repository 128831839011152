import { FormTitleSkeleton, InputSkeleton } from '@containers/long-app/styles/EntrySkeletons.styles';
import FormStyles from '@containers/long-app/styles/Form.styles';

const RentalFormSkeleton = () => (
  <section data-testid="RentalSkeleton_form">
    <FormTitleSkeleton />
    <FormStyles.SubFormWrapper>
      <InputSkeleton width="50%">
        <div className="label" />
        <div className="input" />
      </InputSkeleton>
      <FormStyles.DummyField />
      <FormStyles.FormRow>
        <InputSkeleton width="33%">
          <div className="label" />
          <div className="input" />
        </InputSkeleton>
        <InputSkeleton width="67%">
          <div className="label" />
          <div className="input" />
        </InputSkeleton>
        <InputSkeleton>
          <div className="label" />
          <div className="input" />
        </InputSkeleton>
        <InputSkeleton>
          <div className="label" />
          <div className="input" />
        </InputSkeleton>
        <InputSkeleton>
          <div className="label" />
          <div className="input" />
        </InputSkeleton>
        <InputSkeleton>
          <div className="label" />
          <div className="input" />
        </InputSkeleton>
      </FormStyles.FormRow>
    </FormStyles.SubFormWrapper>

    <FormTitleSkeleton />
    <FormStyles.SubFormWrapper>
      <InputSkeleton>
        <div className="label" />
        <div className="input" />
      </InputSkeleton>
      <InputSkeleton>
        <div className="label" />
        <div className="input" />
      </InputSkeleton>
    </FormStyles.SubFormWrapper>
  </section>
);

export default RentalFormSkeleton;
