import { Controller, FieldValues, useFormContext } from 'react-hook-form';

type FieldMapperProps<T extends FieldValues> = {
  fields: T[];
};
const FieldMapper = <T extends FieldValues>({ fields }: FieldMapperProps<T>) => {
  const { control } = useFormContext<T>();

  return (
    <>
      {fields.map(({ name, component: Component, rules, props }) => (
        <Controller
          key={name}
          name={name}
          control={control}
          rules={rules}
          render={({ field: { name, value, onChange, ref }, fieldState: { error } }) => (
            <Component
              {...props}
              data-testid={`field-${name}`}
              name={name}
              value={value}
              onChange={onChange}
              inputRef={ref}
              invalid={!!error?.message}
              errorMessage={error?.message}
            />
          )}
        />
      ))}
    </>
  );
};

export default FieldMapper;
