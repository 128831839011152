import { hexToRGB } from '@pinecorpca/spruce';
import { createGlobalStyle, DefaultTheme, withTheme } from 'styled-components';

const GlobalStyle = createGlobalStyle<{ theme: DefaultTheme }>`
  :root {
    --PhoneInput-color--focus: ${({ theme }) => theme.text.accent};
  }

  html,
  body {
    box-sizing: border-box;
    max-width: 100vw;
    width: 100%;
    margin: 0;
    padding: 0;
    height: auto;
    background: ${({ theme }) => theme.background.primary};
  }

  /**
    This is required as nextjs will (by default) scroll to the top of the page on route change. (push, replace)
    to prevent odd center scrolling when there's a hash, we have to account for the header height
  */
  * {
    scroll-margin-top: calc(${({ theme }) => theme.pineNavHeight} + 46px);

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      scroll-margin-top: calc(${({ theme }) => theme.pineNavHeightMobile} + 46px);
    }

  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  h1, h2, h3 {
    font-family: var(--font-tiempos);
    font-weight: 300;
    color: ${({ theme }) => theme.text.primary};
    margin: 0;
  }

  h1 {
    font-size: 32px;
    line-height: 38px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      font-size: 22px;
      line-height: 28px;
    }
  }

  h2 {
    font-size: 26px;
    line-height: 31px;
    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  h3 {
    font-size: 18px;
    line-height: 22px;
  }

  h4, h5, h6 {
    color: ${({ theme }) => theme.text.primary};
        font-family: var(--font-futura);
    font-weight: 450;
    margin: 0;
  }

  p, input, li {
    color: ${({ theme }) => theme.text.primary};
    font-family: var(--font-futura);
    font-weight: 400;
    margin: 0;
  }

  strong {
        font-family: var(--font-futura);
    font-weight: 700;
    color: ${({ theme }) => theme.text.primary};
  }

  label {
    font-family: var(--font-futura) !important;
    font-weight: 450;
    color: ${({ theme }) => theme.text.primary};
    margin: 0;
  }

  em {
    font-style: italic;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  .bold {
    font-weight: 700;
  }

  .tagGroup {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .errorMessage {
    color: ${({ theme }) => theme.text.error};
    font-size: 14px;
    line-height: 22px;
    margin-top: 8px;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 0;
    }
  }

  // global styles overide for antd date picker
  .ant-picker:hover, .ant-picker-focused {
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  .ant-picker-panel-container {
    border-radius: 6px;
    td, th, button {
      font-family: var(--font-futura);
      font-weight: 400;
      font-size: 14px;
    }
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
      background: ${({ theme }) => theme.background.invert};
      border-radius: 6px;
    }
  }
  // google places (autocomplete) style overrides
  .pac-container {
    margin-top: 4px;
    border-radius: 8px;
    padding: 0 10px 10px;
    width: 100%;

    .pac-icon {
      position: relative;
      top: -2px;
    }

    .pac-item {
      height: 50px;
      display: flex;
      align-items: center;
    }
    
    .pac-item, .pac-matched {
      font-family: var(--font-futura);
      font-weight: 300;
      color: ${({ theme }) => theme.text.disabled};
      opacity: 1;
      font-size: 14px;
      line-height: 18px;

      .pac-item-query {
        font-family: var(--font-futura);
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: ${({ theme }) => theme.text.primary};
      }
    }
  }

   // global styles override for ant modal
   .ant-modal-content {
      border-radius: 6px;
    }
    .ant-modal-footer {
      border: none;
      text-align: right;
      padding-bottom: 20px;
    }
    .ant-modal-header {
      border: none;
      padding-bottom: 0;
      border-radius: 6px;
    }

    .ant-modal-body {
      font-family: var(--font-futura);
      font-weight: 400;
      color: ${({ theme }) => theme.text.secondary};
      font-size: 18px;
      line-height: 20px;
    }

    .ant-modal-title {
      font-family: var(--font-tiempos);
      font-weight: 400;
      color: ${({ theme }) => theme.text.primary};
      width: 100%;
      font-size: 32px;
      line-height: 35px;
      margin-top: 20px;
    }

    .ant-btn {
      flex: 0 0 50%;
      padding: 11px 21px;
      border: none;
      min-width: 96px;
      border-radius: 79px;
      height: 40px;

          font-family: var(--font-futura);
    font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      cursor: pointer;
        span {
          color: ${({ theme }) => theme.text.invert} !important;
        }
        color: ${({ theme }) => theme.text.invert} !important;
        background-color: ${({ theme }) => theme.cta.primary.default.background} !important;
        &:hover, &:focus {
          background-color: ${({ theme }) => theme.cta.primary.active.background} !important;
        }
    }

    button {
      font-family: var(--font-futura) !important;
      font-weight: 450;
    }
    
    // antd dropdown overrides
    .ant-dropdown {
      border-radius: 8px !important;
      background: ${({ theme }) => theme.background.primary};
      box-shadow: 0px 3px 8px 0px rgba(15, 18, 31, 0.24);
    }
    .ant-dropdown-menu {
      border-radius: 8px;
      background: ${({ theme }) => theme.background.primary};
      box-shadow: 0px 3px 8px 0px ${({ theme }) => hexToRGB(theme.palette.stone.tint, 0.24)};
    }
    .ant-dropdown-menu-item {
      font-family: var(--font-futura) !important;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding: 10px 20px 10px 10px;
      color: ${({ theme }) => theme.text.primary};
    }
		.ant-dropdown-menu-item-selected {
			background: ${({ theme }) => theme.palette.lichen.default};
		}
		.ant-dropdown-menu-item-group-title {
      font-family: var(--font-futura) !important;
      font-size: 14px;
      line-height: 20px;
		}

`;

export default withTheme(GlobalStyle);
