import styled from 'styled-components';

const StyledLoader = {
  Wrapper: styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.background.invert};
    opacity: 0.9;
    z-index: 99999;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    ::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  `,
};

export default StyledLoader;
