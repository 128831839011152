import { RelationshipType } from '@pinecorpca/evergreen';

export const orderedRelationships = [
  RelationshipType.Spouse,
  RelationshipType.CommonLaw,
  RelationshipType.Parent,
  RelationshipType.RelatedFamilyMember,
  RelationshipType.Sibling,
  RelationshipType.Grandparent,
  RelationshipType.Grandchild,
  RelationshipType.Child,
  RelationshipType.Other,
];
