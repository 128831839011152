import { theme as defaultTheme, rbTheme, wsTheme } from 'styles/theme';
import { DefaultTheme } from 'styled-components';
import { useEffect, useState } from 'react';
import { LeadSource } from '@pinecorpca/evergreen';
import { getPartnerSource } from 'contexts/partner';

const getThemePreference = (leadSource: LeadSource | null): DefaultTheme => {
  switch (leadSource) {
    case LeadSource.Wealthsimple:
      return wsTheme;
    case 'ROGERSBANKS' as LeadSource: // TODO remove when demo complete https://pinecanada.atlassian.net/browse/CUS-4641
      return rbTheme;
    default:
      return defaultTheme;
  }
};

export const useThemePreference = (host: string) => {
  const [theme, setTheme] = useState<DefaultTheme>(defaultTheme);
  const partner = getPartnerSource(host);

  useEffect(() => {
    const themePreference = getThemePreference(partner);
    setTheme(themePreference);
  }, [partner]);

  return theme;
};
