import { API } from '@models/enums/api.enum';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { userApi } from 'api/evergreen';
import { EntityApplication } from 'hooks';

export const useEntitiesQuery = (enabled = true): UseQueryResult<EntityApplication[]> =>
  useQuery({
    queryKey: [API.ENTITIES],
    queryFn: async () => {
      const { data } = await userApi.apiUsersMeEntitiesGet();
      const { servicingApplications, activeApplications } = data;

      return activeApplications
        ?.sort((a, b) => new Date(b.creationDate || '').getTime() - new Date(a.creationDate || '').getTime())
        ?.map((application) => ({
          application,
          servicingApplication: servicingApplications?.find((s) => s.id === application.id),
        }));
    },
    enabled,
  });
