import { FlowState } from '@models/flowState.model';
import { BorrowerType, DetailedBorrowerFullDto } from '@pinecorpca/evergreen';

export const isDownpaymentComplete = (borrower: DetailedBorrowerFullDto) => {
  const { downPayments } = borrower ?? {};
  return !!downPayments?.length && downPayments?.every((downpayment) => !!downpayment.amount);
};

export const isListedPropertyComplete = (borrower: DetailedBorrowerFullDto) => {
  const { hasAdditionalProperties } = borrower ?? {};
  return hasAdditionalProperties != null;
};

export const listedPropertySubmitHandler = (borrower: DetailedBorrowerFullDto) => {
  const { hasAdditionalProperties } = borrower ?? {};
  if (hasAdditionalProperties == null) return '';
  return hasAdditionalProperties ? 'property_types' : FlowState.NEXTSECTION;
};

export const isAdditionalPropertiesComplete = (borrower: DetailedBorrowerFullDto) => {
  const additionalProperties = borrower?.additionalProperties;
  const isCoBorrower = borrower?.borrowerType === BorrowerType.Cosigner;
  const isPropertyIncomplete = !!additionalProperties?.find(
    (property) =>
      !(
        property.propertyAddress?.streetName &&
        property.propertyAddress?.streetNumber &&
        property.propertyAddress?.city &&
        property.propertyAddress?.province &&
        property.propertyAddress?.postalCode
      )
  );
  return (!!additionalProperties?.length && !isPropertyIncomplete) || (isCoBorrower && !!borrower.hasAdditionalProperties);
};
