import { LeadSource } from '@pinecorpca/evergreen';
import { getSource } from './partner.utils';

export const getPartnerSource = (host: string | null): LeadSource | null => {
  /**
   * some components will add the host from req.headers.host in server side rendering
   * this is to ensure we have the host right away. If we don't (since not all pages implement ServerSideProps, we fallback to the window)
   */
  const locationHost = typeof window !== 'undefined' ? window.location.host : host;
  const partner = getSource(locationHost);
  return partner;
};
