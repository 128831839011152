import Address from '@components/AddressForm/AddressForm';
import DatePicker from '@components/DatePicker/DatePicker';
import DownPayment from '@components/DownPayment/DownPayment';
import LoanAmount from '@components/LoanAmount/LoanAmount';
import PhoneInput from '@components/PhoneInput/PhoneInput';
import PlacesAutoComplete from '@components/PlacesAutoComplete/PlacesAutoComplete';
import { NAMESPACE } from '@models/enums/namespace.enum';
import { FlowInput, FlowNode } from '@models/flow.model';
import { OptionStruct } from '@models/option.model';
import { DateInput, RadioGroup } from '@pinecorpca/spruce';
import Dropdown from '@spruce/Dropdown/Dropdown';
import CurrencyInput from '@spruce/Inputs/Currency/CurrencyInput';
import Input from '@spruce/Inputs/Input/Input';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'next-i18next';
import { CSSProperties, ReactNode, useCallback } from 'react';
import { Controller, FormProvider, useFormState } from 'react-hook-form';
import StyledForm, { StyledDateOfBirth } from './Form.styles';
import { useQuestionForm } from './hooks/useQuestionForm';

type Props = {
  question: FlowNode;
  onSubmit: (data: { [key: string]: any }) => void;
  disabled: boolean;
  submitting: boolean | undefined;
  namespace?: string;
  data: any;
  Footer?: ReactNode;
  BackButton: ReactNode;
  style?: CSSProperties;
};

const Form = ({ question, style, disabled, submitting, namespace, data, onSubmit, Footer, BackButton }: Props): JSX.Element => {
  const { t } = useTranslation(namespace);
  const [methods, { handleDownPaymentSubmit, handleLoanAmountSubmit }] = useQuestionForm(data);
  const { handleSubmit, control } = methods;

  const { dirtyFields } = useFormState({ control: methods.control });
  const massageDataBeforeSubmit = (data: Record<string, any>): Record<string, any> =>
    Object.keys(data)
      .filter((key) => dirtyFields[key])
      .reduce((acc, curr) => ({ ...acc, [curr]: data[curr] }), {});

  const handleOnSubmit = (data: Record<string, any>) => {
    const inputType = question.inputs?.[0]?.type;
    const submitData = isEmpty(dirtyFields) ? {} : massageDataBeforeSubmit(data);
    switch (inputType) {
      case 'loanAmount':
        handleLoanAmountSubmit(data, () => onSubmit(submitData));
        break;
      case 'downpayment':
        handleDownPaymentSubmit(data, () => onSubmit(submitData));
        break;
      default:
        onSubmit(submitData);
        break;
    }
  };

  /**
   * Render controls
   *
   * @param {object} entry - control
   */
  const renderEntry = useCallback(
    (entry: FlowInput) => {
      // add each control to 'controls' object
      switch (entry.type) {
        case 'autocomplete': {
          return (
            <Controller
              control={control}
              key={entry.id}
              name={entry.fieldName as string}
              rules={entry.rules}
              render={({ field: { value, onChange, name }, fieldState: { error } }) => (
                <PlacesAutoComplete
                  value={value}
                  name={name}
                  label={entry.hideLabel ? '' : t(entry.label as string)}
                  onChange={onChange}
                  placeholder={t(entry.placeholder as string)}
                  error={error}
                />
              )}
            />
          );
        }
        case 'phone': {
          return (
            <Controller
              key={entry.id}
              control={control}
              name={entry.fieldName as string}
              rules={entry.rules}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <PhoneInput
                  label={t(entry.label as string)}
                  name={name}
                  id={entry.id}
                  onChange={onChange}
                  placeholder={entry?.placeholder ? t(entry.placeholder as string) : ''}
                  control={control}
                  value={value}
                  data-testid={`${entry.id}_input`}
                  invalid={!!error}
                  errorMessage={t(error?.message ?? '')}
                />
              )}
            />
          );
        }

        case 'input': {
          if (entry.filter === 'currency') {
            return (
              <Controller
                control={control}
                key={entry.id}
                name={entry.fieldName as string}
                rules={entry.rules}
                render={({ field: { value, onChange, name }, fieldState: { error } }) => (
                  <CurrencyInput
                    data-testid={`${entry.id}_currency`}
                    key={entry.id}
                    id={entry.id}
                    name={name}
                    onChange={onChange}
                    label={t(entry.label as string)}
                    disabled={disabled}
                    placeholder={t(entry.placeholder ?? '')}
                    value={(value as string) || ''}
                    style={{
                      marginBottom: '20px',
                    }}
                    invalid={!!error}
                    errorMessage={t(error?.message as unknown as string)}
                  />
                )}
              />
            );
          }
          return (
            <Controller
              control={control}
              key={entry.id}
              name={entry.fieldName as string}
              rules={entry.rules}
              render={({ field: { value, onChange, name }, fieldState: { error } }) => (
                <Input
                  data-testid={`${entry.id}_input`}
                  key={entry.id}
                  id={entry.id}
                  name={name}
                  label={t(entry.label as string)}
                  disabled={disabled || entry.disabled}
                  filter={entry.filter}
                  type="text"
                  placeholder={t(entry.placeholder ?? '')}
                  value={value || ''}
                  hideLabel={entry.hideLabel}
                  onChange={onChange}
                  style={{ marginBottom: '20px' }}
                  invalid={!!error}
                  errorMessage={t(error?.message as unknown as string)}
                />
              )}
            />
          );
        }
        case 'downpayment':
          return <DownPayment key={entry.id} {...entry.inputProps} />;
        case 'loanAmount':
          return <LoanAmount key={entry.id} {...(entry.inputProps ?? {})} />;
        case 'address':
          return (
            <Address
              key={entry.id}
              id={entry.id}
              disabled={disabled}
              fieldName={entry.secondaryFieldName ?? entry.fieldName}
              {...(entry.inputProps ?? {})}
            />
          );
        case 'date':
          return (
            <Controller
              key={`${entry.id}_${entry.type}`}
              control={control}
              name={entry?.secondaryFieldName ?? (entry.fieldName as string)}
              defaultValue={entry?.value || undefined}
              rules={{ ...entry.rules }}
              render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
                <DatePicker
                  name={name}
                  id={name}
                  key={entry.id}
                  label={t(entry.label as string)}
                  from={entry.from}
                  to={entry.to}
                  value={value}
                  picker={entry.attributes?.picker}
                  invalid={!!error?.message}
                  errorMessage={t(error?.message ?? '')}
                  onChange={onChange}
                  ref={ref}
                />
              )}
            />
          );
        case 'dateOfBirth':
          return (
            <Controller
              key={`${entry.id}_${entry.type}`}
              control={control}
              name={entry?.secondaryFieldName ?? (entry.fieldName as string)}
              rules={{ ...entry.rules }}
              render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
                <DateInput
                  id={entry.id}
                  ref={ref}
                  data-testid={`${entry.id}`}
                  label={t(entry.label as string)}
                  value={value ?? ''}
                  invalid={!!error}
                  cue={t('DATE_OF_BIRTH_CUE')}
                  errorMessage={t(error?.message ?? '')}
                  onChange={onChange}
                  Styled={StyledDateOfBirth}
                />
              )}
            />
          );
        case 'radio': {
          return (
            <Controller
              control={control}
              key={entry.id}
              name={entry.fieldName as string}
              defaultValue={entry.value}
              rules={entry.rules}
              render={({ field: { value, onChange, name }, fieldState: { error } }) => (
                <StyledForm.RadioGroupWrapper>
                  <RadioGroup
                    options={entry.options?.map((option) => ({
                      ...option,
                      label: t(option.label as string),
                    }))}
                    defaultValue={value != null ? `${value}` : undefined}
                    name={name}
                    title={entry.hideLabel ? '' : t(entry.label as string)}
                    onChange={onChange}
                    disabled={disabled}
                    invalid={!!error}
                    errorMessage={t(error?.message ?? '')}
                    radioStyle={{ fontFamily: 'var(--font-futura)', fontWeight: 400 }}
                    data-testid={`${name}_radio`}
                  />
                </StyledForm.RadioGroupWrapper>
              )}
            />
          );
        }
        default:
          return (
            <Controller
              key={entry.id}
              control={control}
              name={entry.fieldName as string}
              defaultValue={entry.value}
              rules={entry.rules}
              render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
                <Dropdown
                  data-testid={`${entry.id}_select`}
                  id={name}
                  name={name}
                  label={t(entry.label as string)}
                  defaultValue={value}
                  placeholder={entry.placeholder ? t(entry.placeholder) : undefined}
                  onChange={onChange}
                  options={
                    entry.options?.map((option) => ({
                      ...option,
                      label: t(option.label as string),
                    })) as OptionStruct[]
                  }
                  disabled={disabled}
                  style={{ marginBottom: '20px' }}
                  invalid={!!error?.message}
                  ref={ref}
                  errorMessage={t(error?.message ?? '')}
                />
              )}
            />
          );
      }
    },
    [disabled, control, t]
  );

  return (
    <FormProvider {...methods}>
      <StyledForm.Form autoComplete="off" onSubmit={handleSubmit(handleOnSubmit)}>
        <StyledForm.InputContainer style={style}>
          {question?.inputs
            ?.filter((field) => (field?.condition ? field.condition(data) : true))
            ?.map((entry: FlowInput) => renderEntry(entry))}
        </StyledForm.InputContainer>
        {Footer}
        <StyledForm.ButtonWrapper>
          {BackButton}
          <StyledForm.SubmitButton key={`button-${question.id}`} data-testid={`button-${question.id}`} type="submit" loading={submitting}>
            {t('CTA_CONTINUE', { ns: NAMESPACE.DEFAULT })}
          </StyledForm.SubmitButton>
        </StyledForm.ButtonWrapper>
      </StyledForm.Form>
    </FormProvider>
  );
};

Form.defaultProps = {
  gap: 15,
  namespace: 'default',
};

export default Form;
