import AddressForm from '@components/AddressForm/AddressForm';
import { propertyIntentions } from '@containers/long-app/containers/property/config/flow.config';
import FormStyles from '@containers/long-app/styles/Form.styles';
import { NAMESPACE } from '@models/enums/namespace.enum';
import { OptionStruct } from '@models/option.model';
import { AddressDto, DetailedPropertyDto } from '@pinecorpca/evergreen';
import Dropdown from '@spruce/Dropdown/Dropdown';
import { useAdditionalPropertyMutationOnPatch } from 'hooks/queries/additional-properties.hooks';
import get from 'lodash/get';
import { useTranslation } from 'next-i18next';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { buildPayload } from '../../utils/buildPayload';

type Props = {
  property: DetailedPropertyDto;
};

const PropertyFormFields = ({ property }: Props): JSX.Element => {
  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const { dirtyFields } = useFormState({
    control,
  });
  const { t } = useTranslation(NAMESPACE.LONGAPP);
  const { mutate: additionalPropertyMutation } = useAdditionalPropertyMutationOnPatch();

  const onBlur = (event: any, property: DetailedPropertyDto) => {
    const { name, type } = event.target;
    if (!['select-one', 'form'].includes(type) && !get(dirtyFields, name)) return;
    const payload = buildPayload(property, event, errors);
    if (payload) {
      additionalPropertyMutation({
        propertyId: payload.id,
        requestBody: payload.body,
      });
    }
  };

  return (
    <>
      <FormStyles.SubFormWrapper>
        <FormStyles.FormRow>
          <AddressForm
            key="ASSET_PROPERTY_ADDRESS"
            address={property.propertyAddress as AddressDto}
            fieldName={`${property.id}.propertyAddress`}
            onInputBlur={(event) => onBlur(event, property)}
          />
        </FormStyles.FormRow>
        <Controller
          control={control}
          name={`${property.id}.currentOccupationType`}
          defaultValue={property.currentOccupationType ?? ''}
          rules={{
            required: {
              value: true,
              message: t('PROPERTY_USAGE_REQUIRED'),
            },
          }}
          render={({ field: { onChange, name, ref, value }, fieldState: { error } }) => (
            <Dropdown
              id="HOW_TO_USE_PROPERTY"
              label={t('HOW_TO_USE_PROPERTY')}
              options={
                propertyIntentions?.map((option) => ({
                  ...option,
                  label: t(option.label),
                })) as OptionStruct[]
              }
              defaultValue={value}
              data-testid={`${property.id}.occupationType`}
              onChange={(e) => {
                onChange(e);
                if (error) clearErrors(name);
                onBlur(e, property);
              }}
              name={name}
              invalid={!!error}
              errorMessage={error?.message}
              ref={ref}
              style={{ marginBottom: '12px' }}
            />
          )}
        />
      </FormStyles.SubFormWrapper>
    </>
  );
};

export default PropertyFormFields;
