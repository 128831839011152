import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styled from './Alert.styled';
import { useMemo } from 'react';
import { faBellOn, faCircleCheck, faCircleInfo, faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { AlertType } from './alert.models';
import { NAMESPACE } from '@models/enums';
import { useTranslation } from 'next-i18next';

interface AlertProps {
  message: string;
  type: AlertType;
  namespace?: NAMESPACE;
  'data-testid'?: string;
}

const Alert = ({ message, type, namespace, 'data-testid': dataTestId }: AlertProps) => {
  const { t } = useTranslation(namespace || NAMESPACE.DEFAULT);

  const icon = useMemo(() => {
    switch (type) {
      case 'warning':
        return faBellOn;
      case 'error':
        return faCircleXmark;
      case 'success':
        return faCircleCheck;
      default:
        return faCircleInfo;
    }
  }, [type]);

  return (
    <Styled.Message data-testid={dataTestId} type={type} role="alert">
      <FontAwesomeIcon icon={icon} role="presentation" />
      {t(message)}
    </Styled.Message>
  );
};

export default Alert;
