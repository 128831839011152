import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { TooltipStyles } from './Tooltip.styles';

type Props = {
  title: string;
  content: string | string[];
  style?: React.CSSProperties;
};

const Tooltip = ({ title, content, style }: Props) => {
  const [hoverOpen, setHoverOpen] = useState(false);
  const [clickOpen, setClickOpen] = useState(false);
  const contentRef = useRef<any>();
  const renderContent = () => {
    if (Array.isArray(content)) {
      return content.map((c, index, { length }) => (
        <TooltipStyles.Content key={c} whitespace={index < length - 1}>
          {c}
        </TooltipStyles.Content>
      ));
    }
    return <TooltipStyles.Content key={content}>{content}</TooltipStyles.Content>;
  };

  const handleClickOutside = (e: any) => {
    if (!contentRef?.current?.contains(e.target)) {
      setClickOpen(() => false);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setClickOpen((prev) => !prev);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (hoverOpen || clickOpen) {
      contentRef?.current?.focus();
    }
  }, [hoverOpen, clickOpen]);

  return (
    <TooltipStyles.Wrapper style={style}>
      <TooltipStyles.TitleWrapper>
        <Image
          alt=""
          src="/images/info.svg"
          width={15}
          height={15}
          tabIndex={0}
          onMouseEnter={() => setHoverOpen(() => true)}
          onMouseLeave={() => setHoverOpen(() => false)}
          onClick={() => setClickOpen(() => true)}
          onKeyDown={handleKeyDown}
          style={{ marginRight: '5px' }}
        />
        <TooltipStyles.Title>{title}</TooltipStyles.Title>
      </TooltipStyles.TitleWrapper>
      <TooltipStyles.ContentContainer tabIndex={hoverOpen || clickOpen ? 0 : -1} open={hoverOpen || clickOpen} ref={contentRef}>
        {renderContent()}
        <TooltipStyles.Icon>
          <Image alt="" src="/images/close.svg" width={15} height={15} className="closeBtn" onClick={() => setClickOpen(() => false)} />
        </TooltipStyles.Icon>
      </TooltipStyles.ContentContainer>
    </TooltipStyles.Wrapper>
  );
};

Tooltip.defaultProps = {
  style: {},
};

export default Tooltip;
