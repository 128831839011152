import AutoComplete from '@components/AutoComplete/AutoComplete';
import { AddressDetails } from '@components/AutoComplete/models/address-response.model';
import { provinceMapper } from '@components/AutoComplete/models/province-mapper.model';
import { FilterTypes } from '@models/enums/filterTypes.enum';
import { NAMESPACE } from '@models/enums/namespace.enum';
import { PATTERNS } from '@models/patterns.constants';
import { AddressDto, Province } from '@pinecorpca/evergreen';
import Dropdown from '@spruce/Dropdown/Dropdown';
import Input from '@spruce/Inputs/Input/Input';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import Styled from './AddressForm.styles';
import { Provinces } from './provinces.constants';
import ProvinceModal from './components/ProvinceModal';
import { enabledProvinces } from './constants/enabled-provinces.constant';

type Props = {
  address?: AddressDto;
  disabled?: boolean;
  id?: string;
  fieldName?: string;
  validateProvince?: boolean;
  onInputBlur?: (e: any) => void;
};

const AddressForm = ({ id, address, disabled, fieldName, onInputBlur, validateProvince }: Props): JSX.Element => {
  const { t } = useTranslation(NAMESPACE.DEFAULT);
  const { setValue, control, getValues } = useFormContext();
  const [toggleProvinceModal, setToggleProvinceModal] = useState(false);

  const province = useWatch({
    control,
    name: `${fieldName}.province`,
  });

  useEffect(() => {
    if (province && !enabledProvinces.includes(province) && validateProvince) setToggleProvinceModal(true);
  }, [province, validateProvince]);

  /**
   * the address prop can be mutated outside of this component
   * to handle that cause, we have to update accordingly
   */
  useEffect(() => {
    if (fieldName && isEmpty(getValues(fieldName)) && !isEmpty(address)) {
      setValue(fieldName || '', address, { shouldDirty: true });
    }
  }, [address, fieldName, getValues, setValue]);

  const onAutoCompleteChange = useCallback(
    (result: AddressDetails) => {
      const { BuildingNumber, Street, PostalCode, SubBuilding, Province, City } = result;
      const selectedAddress: AddressDto = {
        streetNumber: BuildingNumber,
        streetName: Street,
        postalCode: PostalCode,
        addressLine2: SubBuilding || '',
        city: City,
        province: provinceMapper[Province] as Province,
      };

      setValue(
        fieldName || '',
        {
          streetName: selectedAddress?.streetName,
          streetNumber: selectedAddress?.streetNumber,
          city: selectedAddress?.city,
          province: selectedAddress?.province,
          postalCode: selectedAddress?.postalCode,
          addressLine2: selectedAddress?.addressLine2,
        },
        { shouldDirty: true }
      );
      onInputBlur?.({ target: { name: fieldName, type: 'form', value: { ...selectedAddress } } });
    },
    [setValue, fieldName, onInputBlur]
  );

  return (
    <Styled.Container>
      <AutoComplete disabled={disabled} onChange={onAutoCompleteChange} placeholder={t('ADDRESS_SEARCH')} />
      <Styled.Divider />
      <Styled.Wrapper>
        <Controller
          control={control}
          name={`${fieldName}.streetNumber`}
          rules={{
            required: {
              value: true,
              message: t('FORM_STREET_NUM_REQUIRED'),
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error, invalid } }) => (
            <Input
              name={name}
              value={value ?? ''}
              id={`${id || fieldName}-street-number`}
              data-testid={`${id || fieldName}-street-number`}
              onChange={onChange}
              label={t('ADDRESS_STREET_NUMBER')}
              placeholder={t('ADDRESS_STREET_NUMBER_PLACEHOLDER')}
              disabled={disabled}
              invalid={invalid}
              errorMessage={error?.message}
              onBlur={onInputBlur}
              ref={ref}
            />
          )}
        />
        <Controller
          control={control}
          name={`${fieldName}.streetName`}
          rules={{
            required: {
              value: true,
              message: t('FORM_STREET_NAME_REQUIRED'),
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error, invalid } }) => (
            <Input
              name={name}
              id={`${id || fieldName}-street-name`}
              data-testid={`${id || fieldName}-street-name`}
              label={t('ADDRESS_STREET_NAME')}
              placeholder={t('ADDRESS_STREET_NAME_PLACEHOLDER')}
              value={value ?? ''}
              disabled={disabled}
              onChange={onChange}
              invalid={invalid}
              errorMessage={error?.message}
              onBlur={onInputBlur}
              ref={ref}
            />
          )}
        />
        <Controller
          control={control}
          name={`${fieldName}.addressLine2`}
          render={({ field: { value, onChange, name, ref } }) => (
            <Input
              name={name}
              onChange={onChange}
              id={`${id || fieldName}-line2`}
              data-testid={`${id || fieldName}-line2`}
              label={t('ADDRESS_ADDRESS_LINE2')}
              placeholder={t('ADDRESS_ADDRESS_LINE2_PLACEHOLDER')}
              disabled={disabled}
              value={value ?? ''}
              onBlur={onInputBlur}
              ref={ref}
            />
          )}
        />
        <Controller
          control={control}
          name={`${fieldName}.city`}
          rules={{
            required: {
              value: true,
              message: t('FORM_CITY_REQUIRED'),
            },
            pattern: {
              value: PATTERNS.CITY,
              message: t('FORM_CITY_INVALID'),
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error, invalid } }) => (
            <Input
              name={name}
              id={`${id || fieldName}-city`}
              data-testid={`${id || fieldName}-city`}
              label={t('ADDRESS_CITY')}
              placeholder={t('ADDRESS_CITY_PLACEHOLDER')}
              value={value ?? ''}
              disabled={disabled}
              onChange={onChange}
              onBlur={onInputBlur}
              invalid={invalid}
              errorMessage={error?.message}
              ref={ref}
            />
          )}
        />
        <Controller
          control={control}
          name={`${fieldName}.province`}
          defaultValue={address?.province}
          rules={{
            required: {
              value: true,
              message: t('FORM_PROVINCE_REQUIRED'),
            },
            validate: (value) => {
              if (!value || !validateProvince) return undefined;
              return enabledProvinces.includes(value) || t('FORM_PROVINCE_INVALID');
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error, invalid } }) => (
            <Dropdown
              name={name}
              id={`${id || fieldName}-province`}
              data-testid={`${id || fieldName}-province`}
              label={t('ADDRESS_PROVINCE')}
              placeholder={t('ADDRESS_PROVINCE_PLACEHOLDER')}
              options={Provinces.map(({ id, label, value }) => ({
                id,
                label: t(label as string, { ns: NAMESPACE.DEFAULT }),
                value,
              }))}
              disabled={disabled}
              defaultValue={value ?? ''}
              invalid={invalid}
              onChange={(el) => {
                onChange(el);
                onInputBlur?.(el);
              }}
              errorMessage={error?.message}
              ref={ref}
            />
          )}
        />
        <Controller
          control={control}
          name={`${fieldName}.postalCode`}
          defaultValue={address?.postalCode ?? ''}
          rules={{
            required: {
              value: true,
              message: t('FORM_ZIP_REQUIRED'),
            },
            pattern: {
              value: PATTERNS.ZIP,
              message: t('FORM_ZIP_INVALID'),
            },
          }}
          render={({ field: { onChange, name, ref, value }, fieldState: { error, invalid } }) => (
            <Input
              name={name}
              id={`${id || fieldName}-postal-code`}
              data-testid={`${id || fieldName}-postal-code`}
              label={t('ADDRESS_POSTAL_CODE')}
              placeholder={t('ADDRESS_POSTAL_CODE_PLACEHOLDER')}
              filter={FilterTypes.ZIP}
              disabled={disabled}
              invalid={invalid}
              onChange={onChange}
              onBlur={onInputBlur}
              errorMessage={error?.message}
              ref={ref}
              value={value}
            />
          )}
        />
      </Styled.Wrapper>
      <ProvinceModal open={toggleProvinceModal} onClose={() => setToggleProvinceModal(false)} />
    </Styled.Container>
  );
};

AddressForm.defaultProps = {
  fieldName: 'address',
  id: '',
  disabled: false,
  validateProvince: false,
  address: {},
  onInputBlur: noop,
};

export default AddressForm;
