import { AddButton } from '@containers/long-app/components';
import FormStyles from '@containers/long-app/styles/Form.styles';
import TypeFormsStyles from '@containers/long-app/styles/TypeForms.styles';
import { NAMESPACE } from '@models/enums/namespace.enum';
import { DetailedDownPaymentDto, DownPaymentSource } from '@pinecorpca/evergreen';
import { Button } from '@pinecorpca/spruce';
import OptionGroup from '@spruce/Option/OptionGroup';
import { LabelBold } from '@spruce/Typography';
import { DownPaymentSourceOptions } from 'hooks/models/downpayments.model';
import {
  useDownpaymentMutationOnDelete,
  useDownpaymentMutationOnPatch,
  useDownpaymentMutationOnPost,
  useDownpaymentQuery,
} from 'hooks/queries/downpayments.hooks';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import pLimit from 'p-limit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { formatCurrency } from 'utils/common.utils';
import DownpaymentForm from './DownpaymentForm';
import DownpaymentSkeleton from './DownpaymentSkeleton';

const limit = pLimit(1);

interface DownPaymentFieldType extends DetailedDownPaymentDto {
  downPaymentId: string;
}

type Props = {
  onSubmit: () => void;
};

const Downpayments = ({ onSubmit }: Props) => {
  const {
    query: { borrowerId },
  } = useRouter();
  const { t } = useTranslation(NAMESPACE.LONGAPP);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [showDownPaymentOptions, setShowDownPaymentOptions] = useState(false);
  const { data: downPayments } = useDownpaymentQuery(borrowerId as string);
  const { mutateAsync: postDownpayment, isPending: isLoading } = useDownpaymentMutationOnPost();
  const { mutateAsync: updateDownpayment, isPending: isPatchLoading } = useDownpaymentMutationOnPatch();
  const { mutateAsync: deleteDownpayment, isPending: isDeleteLoading } = useDownpaymentMutationOnDelete();
  const mountRef = useRef(false);
  const optionsRef = useRef<HTMLDivElement | null>(null);
  const methods = useForm<{ downpayments: DownPaymentFieldType[] }>({ mode: 'onChange' });
  const { handleSubmit, control, reset, watch } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'downpayments',
  });
  const watchedDownPayments = watch('downpayments');
  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchedDownPayments[index],
  }));

  useEffect(() => {
    if (!mountRef.current && downPayments?.length) {
      reset({
        downpayments: downPayments.map((downPayment) => ({ ...downPayment, downPaymentId: downPayment.id })) as DownPaymentFieldType[],
      });
      mountRef.current = true;
    }
  }, [downPayments, reset]);

  useEffect(() => {
    setShowDownPaymentOptions(!downPayments?.length);
  }, [downPayments?.length]);

  useEffect(() => {
    if (showDownPaymentOptions) {
      optionsRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [showDownPaymentOptions]);

  useEffect(() => {
    if (deleteIndex !== null) {
      remove(deleteIndex);
      setDeleteIndex(null);
    }
  }, [deleteIndex, remove]);

  const totalDownPayments = useMemo(
    () => controlledFields?.reduce((acc, curr) => acc + (Number(curr?.amount) || 0) || 0, 0),
    [controlledFields]
  );

  const handleOnAdd = useCallback(
    async (answer: any) => {
      const { value } = answer.target;

      setShowDownPaymentOptions(false);
      await postDownpayment(
        {
          borrowerId: borrowerId as string,
          downPaymentDto: { source: value },
        },
        {
          onSuccess(response) {
            append({
              ...response.data,
              downPaymentId: response.data.id as string,
            });
          },
        }
      );
    },
    [postDownpayment, borrowerId, append]
  );

  const handleOnDelete = useCallback(
    (id: string, index: number) =>
      deleteDownpayment(
        {
          downpaymentId: id,
          borrowerId: borrowerId as string,
        },
        { onSuccess: () => setDeleteIndex(index) }
      ),
    [borrowerId, deleteDownpayment]
  );

  const onFormSubmit = useCallback(
    async ({ downpayments }: { downpayments: DownPaymentFieldType[] }) => {
      const requests = downpayments.map(({ id, amount }) => ({
        ifMatch: '',
        downpaymentId: id as string,
        requestBody: [{ op: 'replace', path: '/amount', value: amount }],
      }));
      await Promise.all(requests.map((request) => limit(() => updateDownpayment(request))));
      onSubmit();
    },
    [onSubmit, updateDownpayment]
  );

  const downPaymentOptions = useMemo(
    () => DownPaymentSourceOptions.filter((option) => !downPayments?.map((dp) => dp.source).includes(option.value as DownPaymentSource)),
    [downPayments]
  );

  return (
    <FormProvider {...methods}>
      <TypeFormsStyles.Form onSubmit={handleSubmit(onFormSubmit)}>
        <TypeFormsStyles.FormGroupWrapper>
          <>
            {fields.map((field, index) => (
              <TypeFormsStyles.FormListItem key={field.id}>
                <DownpaymentForm
                  fieldIndex={index}
                  isLoading={isDeleteLoading}
                  onDelete={() => handleOnDelete(field.downPaymentId, index)}
                />
              </TypeFormsStyles.FormListItem>
            ))}
          </>
          {isLoading && (
            <TypeFormsStyles.FormListItem key="downpayment_skeleton">
              <TypeFormsStyles.Container>
                <FormStyles.FormWrapper>
                  <DownpaymentSkeleton />
                </FormStyles.FormWrapper>
              </TypeFormsStyles.Container>
            </TypeFormsStyles.FormListItem>
          )}
        </TypeFormsStyles.FormGroupWrapper>

        {!showDownPaymentOptions && !!fields?.length && !!downPaymentOptions.length && (
          <AddButton onClick={() => setShowDownPaymentOptions(true)} data-testid="add-downpayment-button">
            {t('DOWNPAYMENT_ADD_ANOTHER')}
          </AddButton>
        )}

        {showDownPaymentOptions && (
          <>
            {!!fields?.length && <LabelBold style={{ marginBottom: '20px' }}>{t('SELECT_ANOTHER_DOWNPAYMENT')}</LabelBold>}
            <OptionGroup
              ref={optionsRef}
              options={downPaymentOptions.map((option) => ({
                ...option,
                label: t(option.label as string),
              }))}
              onChange={handleOnAdd}
              disabled={isLoading}
            />
          </>
        )}
        {!!fields?.length && (
          <TypeFormsStyles.TotalDl>
            <TypeFormsStyles.TotalDd data-testid="total-downpayment">{formatCurrency(totalDownPayments || '0')}</TypeFormsStyles.TotalDd>
            <TypeFormsStyles.TotalDt>{t('DOWNPAYMENT')}</TypeFormsStyles.TotalDt>
          </TypeFormsStyles.TotalDl>
        )}

        {!!fields?.length && (
          <Button
            loading={isPatchLoading}
            data-testid="submit-downpayments"
            type="submit"
            style={{ width: '172px', alignSelf: 'flex-end' }}
          >
            {t('SAVE_CONTINUE')}
          </Button>
        )}
      </TypeFormsStyles.Form>
    </FormProvider>
  );
};

export default Downpayments;
