import { NAMESPACE } from '@models/enums/namespace.enum';
import { Button } from '@pinecorpca/spruce';
import { FunctionComponent } from 'react';
import { useTranslation } from 'next-i18next';
import { useTheme } from 'styled-components';
import Styled from './ProvinceModal.styled';
import Image from 'next/image';
import { Modal } from '@components/common';

interface ProvinceModalProps {
  open: boolean;
  onClose: () => void;
}

const ProvinceModal: FunctionComponent<ProvinceModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation(NAMESPACE.DEFAULT);
  const { background } = useTheme();

  return (
    <Modal open={open} onClose={onClose} style={{ backgroundColor: background.secondary }}>
      <Styled.Wrapper data-testid="province-error-modal">
        <Styled.H2>{t('ADDRESS_INVALID_PROVINCE')}</Styled.H2>
        <Styled.Text>{t('ADDRESS_INVALID_PROVINCE_CONTENT')}</Styled.Text>
        <Button style={{ padding: '10px 0', width: '84px' }} onClick={onClose} data-testid="province-modal-ok-cta">
          {t('OK', { ns: NAMESPACE.COMMON })}
        </Button>
        <Styled.ImageWrapper>
          <Image alt="" src="/images/outside-house.svg" height={96} width={300} />
        </Styled.ImageWrapper>
      </Styled.Wrapper>
    </Modal>
  );
};

export default ProvinceModal;
