import React, { CSSProperties, useEffect, useState } from 'react';
import { OptionStruct } from '../../../models/option.model';
import Option from './Option';
import Styled from './OptionGroup.styles';

type Props = {
  name?: string;
  disabled?: boolean;
  options?: OptionStruct[];
  errorMessage?: string;
  invalid?: boolean;
  onChange: (args: any) => void;
  style?: CSSProperties;
  defaultValue?: string;
};

const OptionGroup = React.forwardRef<HTMLDivElement, Props>(
  ({ name, disabled, style, options, errorMessage, invalid, onChange, defaultValue }, ref) => {
    const [optionsState, setOptions] = useState(options);

    useEffect(() => {
      if (defaultValue == null) return;
      const newOptions = options?.map((option) => ({ ...option, selected: option.value === `${defaultValue}` }));
      setOptions(newOptions);
    }, [defaultValue, options]);

    const handleOnSelect = (option: OptionStruct) => {
      const newOptions = optionsState?.map((opt) => ({ ...opt, selected: opt.value === option.value }));
      setOptions(newOptions);
      onChange?.({
        target: {
          value: option?.value as string,
          name,
        },
      });
    };

    return (
      <Styled.Group style={style} role="listbox" ref={ref} aria-invalid={invalid} aria-errormessage={`${name}-error`}>
        {optionsState?.map((option: OptionStruct) => (
          <Option
            Img={option?.img}
            option={option}
            key={option.id}
            onSelect={handleOnSelect}
            selected={option.selected || false}
            disabled={disabled}
            style={option.style}
          />
        ))}
        {invalid && <Styled.ErrorMessage id={`${name}-error`}>{errorMessage}</Styled.ErrorMessage>}
      </Styled.Group>
    );
  }
);

OptionGroup.displayName = 'OptionGroup';

OptionGroup.defaultProps = {
  name: '',
  disabled: false,
  options: [],
  errorMessage: '',
  invalid: false,
  style: undefined,
};

export default OptionGroup;
