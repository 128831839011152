import {
  AddressHistoriesControllerApi,
  AssetsControllerApi,
  BorrowerControllerApi,
  CreditConsentControllerApi,
  CrmControllerApi,
  DocumentControllerApi,
  DownpaymentsControllerApi,
  EnvelopeControllerApi,
  IncomesControllerApi,
  LosControllerApi,
  MortgageApplicationControllerApi,
  PropertyControllerApi,
  RatesControllerApi,
  ShortAppControllerApi,
  TasksControllerApi,
  UsersControllerApi,
} from '@pinecorpca/evergreen';
import axios from 'axios';

const axiosInstance = axios.create({
  adapter: axios.defaults.adapter,
});

const BASE_PATH = '';

const mortgageApplicationApi = new MortgageApplicationControllerApi(undefined, BASE_PATH, axiosInstance);
const borrowerApi = new BorrowerControllerApi(undefined, BASE_PATH, axiosInstance);
const userApi = new UsersControllerApi(undefined, BASE_PATH, axiosInstance);
const incomesApi = new IncomesControllerApi(undefined, BASE_PATH, axiosInstance);
const propertyApi = new PropertyControllerApi(undefined, BASE_PATH, axiosInstance);
const assetsApi = new AssetsControllerApi(undefined, BASE_PATH, axiosInstance);
const addressHistoriesApi = new AddressHistoriesControllerApi(undefined, BASE_PATH, axiosInstance);
const downPaymentApi = new DownpaymentsControllerApi(undefined, BASE_PATH, axiosInstance);
const losApi = new LosControllerApi(undefined, BASE_PATH, axiosInstance);
const crmApi = new CrmControllerApi(undefined, BASE_PATH, axiosInstance);
const shortAppApi = new ShortAppControllerApi(undefined, BASE_PATH, axiosInstance);
const taskApi = new TasksControllerApi(undefined, BASE_PATH, axiosInstance);
const creditConsentApi = new CreditConsentControllerApi(undefined, BASE_PATH, axiosInstance);
const envelopeApi = new EnvelopeControllerApi(undefined, BASE_PATH, axiosInstance);
const documentApi = new DocumentControllerApi(undefined, BASE_PATH, axiosInstance);
const ratesApi = new RatesControllerApi(undefined, BASE_PATH, axiosInstance);

export {
  addressHistoriesApi,
  assetsApi,
  axiosInstance,
  borrowerApi,
  creditConsentApi,
  crmApi,
  documentApi,
  downPaymentApi,
  envelopeApi,
  incomesApi,
  losApi,
  mortgageApplicationApi,
  propertyApi,
  ratesApi,
  shortAppApi,
  taskApi,
  userApi,
};
