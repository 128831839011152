import styled, { css } from 'styled-components';

const StyledPhoneInput = {
  Wrapper: styled.div`
    position: relative;
  `,
  InputPhoneWrapper: styled.div<{ invalid?: boolean; disabled?: boolean }>`
    display: flex;
    align-items: center;

    border: 1px solid ${({ invalid, theme }) => (invalid ? theme.border.error : theme.border.primary)};
    border-radius: 8px;
    height: 65px;
    background-color: ${({ theme }) => theme.background.primary};

    ${({ invalid, theme }) =>
      !invalid
        ? css`
            &:hover {
              border-color: transparent;
              box-shadow: 0 0 0 1px ${theme.border.active} !important;
            }

            &:focus-within {
              border-color: transparent;
              box-shadow: 0 0 0 2px ${theme.border.active} !important;
            }
          `
        : css`
            margin-bottom: 0;
            border-color: transparent;
            box-shadow: 0 0 0 2px ${({ theme }) => theme.border.error};
          `}

    ${({ disabled, theme }) =>
      disabled &&
      css`
        background-color: ${theme.background.disabled};
        pointer-events: none;
      `}

    .PhoneInput {
      width: 100%;
      border-radius: 6px;
      padding: 4px 12px;
      background-color: ${({ theme }) => theme.background.primary};

      ${({ disabled, theme }) =>
        disabled &&
        css`
          background-color: ${theme.background.disabled};
          pointer-events: none;
        `}

      .PhoneInputInput {
        font-family: var(--font-futura);
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        border: none;
        outline: none;
        color: ${({ theme }) => theme.text.primary};

        @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
          font-size: 16px;
          line-height: 21px;
        }

        ${({ disabled, theme }) =>
          disabled &&
          css`
            color: ${theme.text.disabled};
            background-color: ${theme.background.disabled};
            pointer-events: none;
          `}
      }
    }
  `,
  Label: styled.label`
    display: block;
    font-family: var(--font-futura);
    font-weight: 450;
    font-size: 18px;
    color: ${({ theme }) => theme.text.primary};
    line-height: 22px;
    margin-bottom: 9px;
  `,
  ErrorMessage: styled.p`
    position: absolute;

    color: ${({ theme }) => theme.text.error};
    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin: 10px 4px 0;
    text-align: left;
  `,
  ErrorIcon: styled.span`
    position: relative;
    top: 0;
    right: 14px;
    color: ${({ theme }) => theme.text.error};
  `,
};

export default StyledPhoneInput;
