import { OptionStruct } from '@models/option.model';
import React, { forwardRef, useEffect, useState } from 'react';
import Styled from './Dropdown.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

type Props = {
  id: string;
  label: string;
  options: OptionStruct[];
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  name: string;
  style?: React.CSSProperties;
  invalid?: boolean;
  errorMessage?: string;
  onChange: (...args: any) => void;
  'data-testid'?: string;
};

const Dropdown = forwardRef<HTMLSelectElement, Props>(
  (
    { id, label, options, defaultValue, disabled, name, style, invalid, errorMessage, placeholder, onChange, 'data-testid': dataTestId },
    ref
  ) => {
    const [value, setValue] = useState('');

    useEffect(() => {
      setValue(defaultValue ?? '');
    }, [defaultValue]);

    return (
      <Styled.Wrapper style={style}>
        <Styled.Label htmlFor={id}>{label}</Styled.Label>
        <Styled.Select
          hasValue={!!value}
          value={value}
          name={name}
          id={id}
          disabled={disabled}
          invalid={invalid}
          onChange={(event) => {
            setValue(event?.target?.value);
            onChange(event);
          }}
          data-testid={dataTestId}
          ref={ref}
          aria-invalid={invalid}
          aria-errormessage={`${id}-error`}
        >
          <option hidden value="" disabled>
            {placeholder}
          </option>
          {options.map((option) => (
            <option key={option.id} value={option.value}>
              {option.label}
            </option>
          ))}
        </Styled.Select>
        <Styled.ChevronWrapper>
          <FontAwesomeIcon icon={faChevronDown} />
        </Styled.ChevronWrapper>
        {invalid && <Styled.ErrorMessage id={`${id}-error`}>{errorMessage}</Styled.ErrorMessage>}
      </Styled.Wrapper>
    );
  }
);

Dropdown.displayName = 'Dropdown';

Dropdown.defaultProps = {
  defaultValue: '',
  style: {},
  disabled: false,
  invalid: false,
  placeholder: '',
  errorMessage: undefined,
  'data-testid': undefined,
};

export default Dropdown;
