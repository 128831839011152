import styled from 'styled-components';

const StyledOptionGroup = {
  Group: styled.div<{
    col?: number;
    gap?: number;
    maxWidth?: number;
  }>`
    display: grid;
    grid-template-columns: repeat(${(props) => props.col}, minmax(0, 1fr));
    grid-gap: ${(props) => props.gap}px;
    ${(props) => props.maxWidth && `max-width: ${props.maxWidth}px;`};
    gap: 16px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      // in mobile, ensure that we are displaying option group in a single column
      grid-template-columns: 1fr !important;
    }
  `,
  ErrorMessage: styled.p`
    font-family: var(--font-futura);
    font-weight: 400;
    color: ${({ theme }) => theme.text.error};
    font-size: 14px;
    line-height: 16px;
  `,
};

export default StyledOptionGroup;
