import styled, { css } from 'styled-components';

type Props = {
  hideLabel?: boolean;
  invalid?: boolean;
  disabled?: boolean;
};

const StyledInput = {
  Container: styled.div`
    position: relative;
  `,
  Label: styled.label<Props>`
    display: block;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 8px;
    ${({ hideLabel }) =>
      hideLabel &&
      css`
        position: absolute;
        height: 1px;
        width: 1px;
        overflow: hidden;
      `};
  `,
  Wrapper: styled.div<Props>`
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    min-height: 64px;
    box-sizing: border-box;

    background-color: ${({ theme }) => theme.background.primary};
    border: 1px solid ${({ theme }) => theme.border.primary};
    transition: border 200ms, box-shadow 150ms;

    ${({ invalid }) =>
      !invalid
        ? css`
            @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
              &:hover {
                box-shadow: 0 0 0 1px ${({ theme }) => theme.border.active};
                border-color: transparent;
              }
            }

            &:focus-within {
              border-color: transparent;
              box-shadow: 0 0 0 2px ${({ theme }) => theme.border.active};
            }
          `
        : css`
            border-color: transparent;
            box-shadow: 0 0 0 2px ${({ theme }) => theme.border.error};
          `};

    ${({ disabled }) =>
      disabled &&
      css`
        background-color: ${({ theme }) => theme.background.disabled};
        pointer-events: none;
      `}
  `,
  Input: styled.input`
    outline: 0;
    border: 0;
    height: 64px;
    width: 100%;
    padding: 10px 14px;

    box-sizing: border-box;
    background-color: ${({ theme }) => theme.background.primary};
    border-radius: 8px;

    &:disabled {
      background-color: ${({ theme }) => theme.background.disabled};
      color: ${({ theme }) => theme.text.disabled};
    }
    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: ${({ theme }) => theme.text.primary};
  `,
  ErrorIcon: styled.div`
    margin-right: 14px;
    color: ${({ theme }) => theme.text.error};
  `,
  Hint: styled.p`
    font-family: var(--font-futura);
    color: ${({ theme }) => theme.text.secondary};
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin: 8px 0;
  `,
  ErrorMessage: styled.p`
    position: absolute;
    font-family: var(--font-futura);
    font-weight: 400;
    color: ${({ theme }) => theme.text.error};
    font-size: 14px;
    line-height: 17px;
    margin-top: 8px;
  `,
  TextArea: styled.textarea`
    outline: 0;
    border: 0;
    padding: 10px 14px;

    box-sizing: border-box;
    background-color: ${({ theme }) => theme.background.primary};
    border-radius: 8px;
    width: 100%;
    &:disabled {
      background-color: ${({ theme }) => theme.background.disabled};
      color: ${({ theme }) => theme.text.disabled};
    }
    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: ${({ theme }) => theme.text.primary};
  `,
  HintText: styled.p<{ $invalid: boolean }>`
    font-family: var(--font-futura);
    color: ${({ theme }) => theme.text.secondary};
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin: ${({ $invalid }) => ($invalid ? '24px 0 0' : '8px 0')};
  `,
};

export default StyledInput;
