import { PinePromotion } from '@components/Promotion';
import { NAMESPACE } from '@models/enums';
import { LeadSource } from '@pinecorpca/evergreen';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { FunctionComponent } from 'react';
import Styled from '../PinePromotion/PinePromotion.styled';
import config from '@config';
interface PartnerPromotionProps {
  leadSource: LeadSource | undefined | null;
}

const PartnerPromotion: FunctionComponent<PartnerPromotionProps> = ({ leadSource }) => {
  const { t } = useTranslation(NAMESPACE.PROMOTION);

  switch (leadSource) {
    case LeadSource.Wealthsimple:
      return (
        <Styled.ImageWrapper>
          <Image
            data-testid="wealthsimple-promotion"
            alt={t(`${leadSource}_PROMOTION`)}
            src={`${config.IMAGES_BUCKET}ws-login.png`}
            priority
            style={{ borderRadius: '5px', objectFit: 'cover' }}
            fill
          />
        </Styled.ImageWrapper>
      );
    default:
      return <PinePromotion />;
  }
};

export default PartnerPromotion;
