import styled from 'styled-components';

const StyledLayout = {
  Container: styled.section`
    max-width: ${({ theme }) => theme.mediumLayoutMaxWidth};
    min-height: calc(100vh - ${({ theme }) => theme.pineFooterHeight} - ${({ theme }) => theme.pineNavHeight});
    width: 100%;
    margin: calc(${({ theme }) => theme.pineNavHeight}) auto 0;

    /* prettier-ignore */
    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      padding: 50px 24px 12px;
      margin: ${({ theme }) => theme.pineNavHeightMobile} auto 0;
      min-height: calc(
        100vh - ${({ theme }) => theme.pineFooterMobileHeight} - ${({ theme }) => theme.pineNavHeightMobile});
    }
  `,
};

export default StyledLayout;
