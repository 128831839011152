import { NAMESPACE } from '@models/enums/namespace.enum';
import {
  AddressDto,
  DetailedAddressHistoryDto,
  DetailedBorrowerFullDto,
  DwellingStatus,
  MortgageApplicationClientDto,
} from '@pinecorpca/evergreen';
import { useAdditionalPropertyQuery, useAddressHistoriesQuery, useBorrowerQuery, useMortgageAppQuery } from 'hooks';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { areAddressesEqual } from 'utils/formatAddress.util';
import ListedPropertyCard from './ListedPropertyCard';
import Styles from './ListedPropertyCard.styles';

type PropertyType = AddressDto & { tag: DwellingStatus };

const ListedProperty = () => {
  const {
    query: { borrowerId },
  } = useRouter();
  const { data: app = {} as MortgageApplicationClientDto } = useMortgageAppQuery();
  const { rentalIncomeProperties } = useAdditionalPropertyQuery();
  const { data: borrower = {} as DetailedBorrowerFullDto } = useBorrowerQuery(borrowerId as string);
  const { data: addressHistories = [] as DetailedAddressHistoryDto[] } = useAddressHistoriesQuery(borrowerId as string);
  const { t } = useTranslation(NAMESPACE.LONGAPP);

  const primaryProperty = useMemo((): AddressDto | undefined => {
    const { dwellingStatus } = borrower;
    if (dwellingStatus !== DwellingStatus.Own) return undefined;
    if (!addressHistories?.length) return undefined;
    const currentLivingProperty = addressHistories?.find((address) => address.isCurrent)?.address;
    const subjectProperty = app?.property?.propertyAddress;
    return areAddressesEqual(currentLivingProperty as AddressDto, subjectProperty as AddressDto)
      ? undefined
      : (currentLivingProperty as AddressDto);
  }, [borrower, addressHistories, app?.property?.propertyAddress]);

  const propertyList = useMemo((): PropertyType[] => {
    const rentalProperties = rentalIncomeProperties.map((property) => ({
      ...property.propertyAddress,
      tag: DwellingStatus.Rent,
    })) as PropertyType[];

    return primaryProperty ? [...rentalProperties, { ...primaryProperty, tag: DwellingStatus.Own }] : rentalProperties;
  }, [primaryProperty, rentalIncomeProperties]);

  return (
    <>
      {!!propertyList?.length && (
        <>
          <Styles.Header>{t('LISTED_PROPERTY_HEADING')}</Styles.Header>
          <Styles.CardDeck>
            {propertyList.map((address: PropertyType | undefined) => (
              <ListedPropertyCard address={address} key={`address_${address?.id}`} />
            ))}
          </Styles.CardDeck>
          <Styles.Hint>{t('LISTED_PROPERTY_HINT')}</Styles.Hint>
        </>
      )}
    </>
  );
};

export default ListedProperty;
