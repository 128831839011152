import Link from 'next/link';
import styled from 'styled-components';

export const ButtonLink = styled(Link)`
  display: inline-block;
  position: relative;
  max-width: fit-content;
  font-family: var(--font-futura);
  font-weight: 700;
  white-space: nowrap;
  font-size: 16px;
  line-height: 18px;
  background: ${({ theme }) => theme.cta.primary.default.background};
  border-radius: 80px;
  text-align: center;
  text-decoration: none;
  padding: 16px 28px;
  color: ${({ theme }) => theme.text.invert};
  transition: background-color 250ms;
  &:hover {
    background-color: ${({ theme }) => theme.cta.primary.active.background};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.xs}) {
    max-width: none;
    width: 100%;
  }
`;

export const CueAnchorLink = styled.a`
  font-family: var(--font-futura);
  font-weight: 300;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: ${({ theme }) => theme.text.secondary};
`;
