import DatePicker from '@components/DatePicker/DatePicker';
import FormStyles from '@containers/long-app/styles/Form.styles';
import { NAMESPACE } from '@models/enums/namespace.enum';
import { AdditionalIncomeType, DetailedIncomeDto, OtherIncome } from '@pinecorpca/evergreen';
import Dropdown from '@spruce/Dropdown/Dropdown';
import CurrencyInput from '@spruce/Inputs/Currency/CurrencyInput';
import { subYears } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { isAfterOrEqual } from 'utils/date.utils';
import { additionalIncomeTypeOptions } from '../../../models/additionalIncomeTypeOptions.constants';
import { formatDate } from '../../../utils/incomes.utils';

type Props = {
  income: DetailedIncomeDto;
  index: number;
};

const OtherForm = ({ income, index }: Props): JSX.Element => {
  const { control, getValues } = useFormContext();
  const { t } = useTranslation(NAMESPACE.LONGAPP);

  const [otherIncomeType, current] = useWatch({
    control,
    name: [`incomes.${index}.income.type`, `incomes.${index}.current`],
  });
  const isAmountType = useMemo(
    () => ![AdditionalIncomeType.Unemployment, AdditionalIncomeType.Student].includes(otherIncomeType),
    [otherIncomeType]
  );

  return (
    <>
      <FormStyles.FormTitle>
        <span className="title">{t('INCOMES_OTHER_INCOME_DETAILS')}</span>
      </FormStyles.FormTitle>
      <FormStyles.SubFormWrapper>
        <Controller
          control={control}
          name={`incomes.${index}.income.type`}
          rules={{
            required: {
              value: true,
              message: t('INCOMES_OTHER_TYPE_REQUIRED'),
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
            <Dropdown
              name={name}
              onChange={onChange}
              id={name}
              label={t('INCOMES_OTHER_INCOME_TYPE')}
              placeholder={t('INCOMES_OTHER_INCOME_TYPE_PLACEHOLDER')}
              options={additionalIncomeTypeOptions?.map((option) => ({
                ...option,
                label: t(option.label as string),
              }))}
              data-testid={`${(income.income as OtherIncome)?.incomeId}.type`}
              invalid={!!error}
              errorMessage={error?.message}
              defaultValue={value}
              ref={ref}
            />
          )}
        />
        {isAmountType && (
          <Controller
            control={control}
            name={`incomes.${index}.years.0.annualAmount`}
            rules={{
              required: {
                value: true,
                message: t('INCOMES_OTHER_AMOUNT_REQUIRED'),
              },
            }}
            render={({ field: { value, ref, onChange, name }, fieldState: { error } }) => (
              <CurrencyInput
                id={name}
                name={name}
                label={t('INCOMES_OTHER_ANNUAL_AMOUNT')}
                invalid={!!error}
                data-testid={`${(income.income as OtherIncome)?.incomeId}.annualAmount`}
                value={value}
                errorMessage={error?.message}
                placeholder="$0"
                onChange={onChange}
                ref={ref}
              />
            )}
          />
        )}
        <Controller
          key={`START_DATE_${(income.income as OtherIncome)?.incomeId}`}
          control={control}
          name={`incomes.${index}.startDate`}
          rules={{
            required: {
              value: true,
              message: t('INCOMES_EMPLOYMENT_START_DATE_REQUIRED'),
            },
          }}
          render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
            <DatePicker
              id={name}
              name={name}
              ref={ref}
              key={`DATE_ESTABLISHED_${(income.income as OtherIncome)?.incomeId}`}
              label={t('INCOMES_EMPLOYMENT_START_DATE')}
              value={value}
              to={new Date()}
              from={subYears(new Date(), 100)}
              picker="month"
              invalid={!!error?.message}
              errorMessage={error?.message ?? ''}
              onChange={onChange}
            />
          )}
        />
        {!current && (
          <Controller
            key={`END_DATE_${(income.income as OtherIncome)?.incomeId}`}
            control={control}
            name={`incomes.${index}.endDate`}
            rules={{
              required: {
                value: true,
                message: t('INCOMES_EMPLOYMENT_END_DATE_REQUIRED'),
              },
              validate: (value) => {
                if (!value) return undefined;
                const startDate = getValues(`incomes.${index}.startDate`);
                return isAfterOrEqual(new Date(value), new Date(startDate)) || t('INCOMES_INVALID_END_DATE');
              },
            }}
            render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
              <DatePicker
                id={name}
                name={name}
                ref={ref}
                key={`END_DATE_${(income.income as OtherIncome)?.incomeId}`}
                label={t('INCOMES_EMPLOYMENT_END_DATE')}
                value={value}
                to={new Date()}
                from={formatDate(getValues(`incomes.${index}.startDate`)) ?? subYears(new Date(), 100)}
                picker="month"
                invalid={!!error?.message}
                errorMessage={error?.message ?? ''}
                onChange={onChange}
              />
            )}
          />
        )}
      </FormStyles.SubFormWrapper>
    </>
  );
};

export default OtherForm;
