import { OptionStruct } from '@models/option.model';
import { AdditionalIncomeType } from '@pinecorpca/evergreen';

export const additionalIncomeTypeOptions: OptionStruct[] = [
  {
    id: 'ALIMONY',
    value: AdditionalIncomeType.SpousalSupport,
    label: 'ALIMONY',
  },
  {
    id: 'CHILD_SUPPORT',
    value: AdditionalIncomeType.ChildSupport,
    label: 'CHILD_SUPPORT',
  },
  {
    id: 'INVESTMENT_INCOME',
    value: AdditionalIncomeType.InvestmentIncome,
    label: 'INVESTMENT_INCOME',
  },
  {
    id: 'CANADA_CHILD_TAX_BENEFIT',
    value: AdditionalIncomeType.CanadaChildTaxBenefit,
    label: 'CANADA_CHILD_TAX_BENEFIT',
  },
  {
    id: 'MATERNITY_LEAVE',
    value: AdditionalIncomeType.MaternityLeave,
    label: 'MATERNITY_LEAVE',
  },
  {
    id: 'PENSION',
    value: AdditionalIncomeType.Pension,
    label: 'PENSION',
  },
  {
    id: 'DISABILITY',
    value: AdditionalIncomeType.Disability,
    label: 'DISABILITY',
  },
  {
    id: 'STUDENT',
    value: AdditionalIncomeType.Student,
    label: 'STUDENT',
  },
  {
    id: 'UNEMPLOYMENT',
    value: AdditionalIncomeType.Unemployment,
    label: 'UNEMPLOYMENT',
  },
];
