import styled, { keyframes } from 'styled-components';

const Loading = keyframes`
0% { background-color: hsl(200, 20%, 70%); }
100% { background-color: hsl(200, 20%, 95%); }
`;

const InputLoading = keyframes`
0% { box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 0 0 0 0.25px hsl(200, 20%, 70%); }
100% { box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 0 0 0 0.25px hsl(200, 20%, 95%); }
`;

export const FormTitleSkeleton = styled.div`
  width: 160px;
  height: 26px;
  margin-bottom: 30px;
  border-radius: 4px;
  animation: ${Loading} 1s linear infinite alternate;
`;

export const InputSkeleton = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width || '100%'};
  margin-bottom: 15px;
  .label {
    border-radius: 4px;
    height: 21px;
    width: 72px;
    margin-bottom: 12px;
    animation: ${Loading} 1s linear infinite alternate;
  }
  .input {
    border-radius: 4px;
    height: 40px;
    width: 100%;
    animation: ${InputLoading} 1s linear infinite alternate;
  }
`;
