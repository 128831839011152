import Styled from './PinePromotion.styled';
import Image from 'next/image';
import { Trans, useTranslation } from 'next-i18next';
import { NAMESPACE } from '@models/enums';

const PinePromotion = () => {
  const { t } = useTranslation(NAMESPACE.PROMOTION);
  return (
    <Styled.Container>
      <Styled.Wrapper>
        <Styled.Title data-testid="promotion-title">
          <Trans i18nKey="PROMOTION_REFERRAL" t={t} components={{ bold: <Styled.Strong /> }} />
        </Styled.Title>
        <Styled.Link target="_blank" href="https://www.pine.ca/customer-referral" data-testid="promotion-link">
          {t('LEARN_MORE')}
        </Styled.Link>
      </Styled.Wrapper>
      <Styled.ImageWrapper>
        <Image
          src="/images/couple-stairs.png"
          alt={t('PROMOTION_REFERRAL')}
          fill
          style={{ borderRadius: '0 0 5px 2px', objectFit: 'cover' }}
          priority
        />
      </Styled.ImageWrapper>
    </Styled.Container>
  );
};

export default PinePromotion;
