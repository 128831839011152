import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Styled from './Modal.styled';
import Image from 'next/image';
import FocusTrap from 'focus-trap-react';
import GlobalStyle from 'styles/globalStyles';
import { CSSProperties } from 'react';

interface ModalProps {
  title?: string;
  onClose: () => void;
  width?: number;
  open: boolean;
  style?: CSSProperties;
  overlayStyle?: CSSProperties;
  children: ReactNode;
}

const Modal = ({ children, title, onClose, open, width, style, overlayStyle }: ModalProps) => {
  const [mounted, setMounted] = useState(false);
  const closeRef = useRef<HTMLAnchorElement>(null);

  const handleClose = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      onClose();
    },
    [onClose]
  );

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (open) {
      closeRef.current?.focus();
    }
  }, [open]);

  if (mounted) {
    return ReactDOM.createPortal(
      open ? (
        <Styled.Overlay style={overlayStyle}>
          <GlobalStyle />
          <FocusTrap focusTrapOptions={{ fallbackFocus: 'div#popup-dialog' }}>
            <Styled.Modal id="popup-dialog" style={{ width: width ? `${width}px` : 'max-content', ...style }}>
              <Styled.HeaderWrapper>
                <Styled.Close ref={closeRef} href="#" id="close-modal" onClick={handleClose}>
                  <Image alt="close" src="/images/modal-close.svg" width={15} height={15} />
                </Styled.Close>
              </Styled.HeaderWrapper>
              {title && <Styled.Header>{title}</Styled.Header>}
              <Styled.Body>{children}</Styled.Body>
            </Styled.Modal>
          </FocusTrap>
        </Styled.Overlay>
      ) : null,
      document.getElementById('modal-root') as HTMLElement
    );
  } else {
    return null;
  }
};

Modal.defaultProps = {
  width: undefined,
  title: undefined,
  style: undefined,
  overlayStyle: undefined,
};

export default Modal;
