/* eslint-disable max-len */
import { API } from '@models/enums/api.enum';
import {
  AddressHistoriesControllerApiApiAddressHistoryAddressHistoryIdPatchRequest as AddressPatchRequest,
  AddressHistoriesControllerApiApiBorrowersBorrowerIdAddressHistoryPostRequest as AddressPostRequest,
  MortgageApplicationClientDto,
} from '@pinecorpca/evergreen';
import cloneDeep from 'lodash/cloneDeep';
import { addressHistoriesApi } from 'api/evergreen';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useBorrowerQuery } from './borrower.hooks';
import { useRouter } from 'next/router';

export const useAddressHistoriesQuery = (borrowerId?: string) => {
  const { data: borrower, isSuccess } = useBorrowerQuery(borrowerId);
  return { data: borrower.addressHistories, isSuccess };
};

export const useAddressHistoryMutationOnPost = () => {
  const {
    query: { applicationId },
  } = useRouter();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: AddressPostRequest) => addressHistoriesApi.apiBorrowersBorrowerIdAddressHistoryPost(payload),
    onSuccess: ({ data: newAddress }) => {
      queryClient.setQueryData(
        [API.MORTGAGE_APPLICATION, applicationId as string],
        (current: MortgageApplicationClientDto | undefined) => ({
          ...cloneDeep(current),
          borrowers: current?.borrowers?.map((borrower) =>
            borrower.id === newAddress.borrowerId
              ? {
                  ...borrower,
                  addressHistories: [...(borrower?.addressHistories || []), newAddress],
                }
              : borrower
          ),
        })
      );
    },
  });
};

export const useAddressHistoryMutationOnPatch = () => {
  const {
    query: { applicationId },
  } = useRouter();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: AddressPatchRequest) => addressHistoriesApi.apiAddressHistoryAddressHistoryIdPatch(payload),
    onSuccess: ({ data: updatedAddress }) => {
      queryClient.setQueryData([API.MORTGAGE_APPLICATION, applicationId as string], (current: MortgageApplicationClientDto | undefined) => {
        const borrower = current?.borrowers?.find(({ id }) => id === updatedAddress.borrowerId);
        const addressHistories = borrower?.addressHistories?.map((addressHistory) =>
          addressHistory.id === updatedAddress.id ? updatedAddress : addressHistory
        );
        return {
          ...cloneDeep(current),
          borrowers: current?.borrowers?.map((borrower) =>
            borrower.id === updatedAddress.borrowerId ? { ...borrower, addressHistories } : borrower
          ),
        };
      });
    },
  });
};
