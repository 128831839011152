import { MINIMUM_AMOUNT } from '@/lib/constants';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterTypes } from '@models/enums/filterTypes.enum';
import { NAMESPACE } from '@models/enums/namespace.enum';
import CurrencyInput from '@spruce/Inputs/Currency/CurrencyInput';
import Input from '@spruce/Inputs/Input/Input';
import { useTranslation } from 'next-i18next';
import { FC, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { formatCurrency } from 'utils/common.utils';
import Styled from './downPayment.styles';
import { MAX_PRE_APPROVAL_PURCHASE_PRICE, MAX_PURCHASE_PRICE } from './models/constraints';
import { calculateLoanAmount, calculateTotalDownPayment, formatPercent } from './utils/utils';
import { Alert } from '@components/common';
import { useValidateDownPayment } from './hooks/useValidateDownPayment';

interface DownPaymentProps {
  isPreApproval?: boolean;
}

const DownPayment: FC<DownPaymentProps> = ({ isPreApproval }) => {
  const { t } = useTranslation(NAMESPACE.LONGAPP);
  const {
    setValue,
    getValues,
    control,
    formState: { errors, isSubmitted },
  } = useFormContext();
  const { validateMinDownPayment } = useValidateDownPayment();
  const [percentage, setPercentage] = useState<string | undefined>();
  const [price, down, original] = useWatch({
    control,
    name: ['purchasePrice', 'totalDownPayment', 'originalMortgageAmount'],
  });

  useEffect(() => {
    setPercentage(formatPercent(price, down));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue('originalMortgageAmount', calculateLoanAmount(price, down), { shouldDirty: true });
  }, [price, down, setValue]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case 'amountPercent':
        setValue('totalDownPayment', calculateTotalDownPayment(price, value), {
          shouldDirty: true,
        });
        break;
      case 'totalDownPayment':
        setPercentage(formatPercent(price, value));
        setValue('purchasePrice', price, { shouldDirty: true });
        break;
      case 'purchasePrice':
        setPercentage(formatPercent(value, down));
        setValue('totalDownPayment', down, { shouldDirty: true });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Styled.Container>
        {isPreApproval && price > MAX_PRE_APPROVAL_PURCHASE_PRICE && isSubmitted && (
          <Alert
            data-testid="pre-approval-max-price-alert"
            message={t('DOWNPAYMENT_PRE_APPROVAL_MAX_PURCASE_PRICE_ALERT')}
            type="warning"
          />
        )}

        <Controller
          control={control}
          name="purchasePrice"
          rules={{
            required: {
              value: true,
              message: t('DOWNPAYMENT_PURCHASE_PRICE_ERROR'),
            },
            max: {
              value: MAX_PURCHASE_PRICE,
              message: t('DOWNPAYMENT_PURCHASE_PRICE_MAX_ERROR'),
            },
            min: {
              value: MINIMUM_AMOUNT,
              message: t('MINIMUM_AMOUNT_ERROR', { ns: NAMESPACE.DEFAULT }),
            },
            validate: (value) => {
              if (!isPreApproval) return true;
              return Number(value) <= MAX_PRE_APPROVAL_PURCHASE_PRICE || t('DOWNPAYMENT_PRE_APPROVAL_MAX_PURCASE_PRICE');
            },
          }}
          render={({ field: { value, ref, onChange, name }, fieldState: { error } }) => (
            <CurrencyInput
              style={{
                marginBottom: '20px',
                width: '100%',
              }}
              name={name}
              id={name}
              data-testid="down-payment-purchase-price"
              label={t(isPreApproval ? 'DOWNPAYMENT_PURCHASE_PRICE_ESTIMATED' : 'PURCHASE_PRICE')}
              invalid={!!error}
              value={value}
              placeholder="$0"
              onChange={(e) => {
                onChange(e);
                handleOnChange(e);
              }}
              errorMessage={error?.message}
              ref={ref}
            />
          )}
        />
        <Styled.Wrapper>
          <Controller
            control={control}
            name="totalDownPayment"
            rules={{
              required: {
                value: true,
                message: t('DOWNPAYMENT_AMOUNT_ERROR'),
              },
              min: validateMinDownPayment(Number(getValues('purchasePrice'))),
              max: {
                value: Number(getValues('purchasePrice')),
                message: t('DOWNPAYMENT_AMOUNT_ERROR_EXCEEDS'),
              },
            }}
            render={({ field: { value, ref, onChange, name }, fieldState: { error } }) => (
              <CurrencyInput
                name={name}
                style={{
                  marginBottom: '28px',
                  width: '100%',
                }}
                id="downpayment_dollar"
                data-testid="down-payment-down-payment"
                label={t('DOWNPAYMENT_DOLLAR')}
                invalid={!!error}
                value={value}
                placeholder="$0"
                onChange={(e) => {
                  onChange(e);
                  handleOnChange(e);
                }}
                errorMessage={error?.message}
                ref={ref}
              />
            )}
          />
          <Styled.SplitterText>{t('DOWNPAYMENT_SPLITTER')}</Styled.SplitterText>
          <Input
            name="amountPercent"
            style={{
              width: '100%',
            }}
            id="down-payment-amount-percent"
            value={percentage ?? ''}
            placeholder="0%"
            data-testid="down-payment-amount-percent"
            label={t('DOWNPAYMENT_PCNT')}
            onChange={handleOnChange}
            filter={FilterTypes.PERCENT}
          />
        </Styled.Wrapper>
        <Styled.Divider />
        <Styled.LoanAmountWrapper $invalid={errors.root?.type === 'min-downpayment'}>
          <Styled.LoanAmount data-testid="down-payment-label">{formatCurrency(original || '0')}</Styled.LoanAmount>
          <Styled.LoanAmountText id="down-payment-loan-amount">{t('LOAN_AMOUNT')}</Styled.LoanAmountText>
          {errors.root?.type === 'min-downpayment' && (
            <Styled.ErrorMessageWrapper>
              <FontAwesomeIcon icon={faCircleExclamation} />
              <Styled.ErrorMessage data-testid="min-downpayment-required-error" role="alert">
                {t(errors.root?.message || '', { ns: NAMESPACE.DEFAULT })}
              </Styled.ErrorMessage>
            </Styled.ErrorMessageWrapper>
          )}
        </Styled.LoanAmountWrapper>
      </Styled.Container>
    </>
  );
};

DownPayment.defaultProps = {
  isPreApproval: false,
};

export default DownPayment;
