import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterTypes } from '@models/enums/filterTypes.enum';
import noop from 'lodash/noop';
import React, { ChangeEvent, forwardRef, InputHTMLAttributes, useEffect, useImperativeHandle, useState } from 'react';
import Styled from '../Inputs.styles';
import { formatPercentInput, formatZipInput } from '../utils/formatter.utils';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  hideLabel?: boolean;
  filter?: string;
  style?: React.CSSProperties;
  errorMessage?: string;
  invalid?: boolean;
  disabled?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  'data-testid'?: string;
  hint?: string;
  maxLength?: number;
}

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      placeholder,
      label,
      hideLabel,
      filter,
      name,
      style,
      errorMessage,
      invalid,
      onChange,
      onBlur,
      value,
      maxLength,
      disabled,
      'data-testid': dataTestId,
      hint,
      ...rest
    },
    ref
  ): JSX.Element => {
    const [cursorPos, setCursor] = useState<null | number>(null);
    const [currInput, setCurrInput] = useState(value ?? '');
    const innerRef = React.useRef<HTMLInputElement>(null);

    // set forwarded ref to be the same as inner one
    useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);

    const formatInput = (inputValue: string): string => {
      switch (filter) {
        case FilterTypes.PERCENT:
          return formatPercentInput(inputValue);
        case FilterTypes.ZIP:
          return formatZipInput(inputValue);
        case FilterTypes.NUMBER:
          return inputValue?.replace(/[^0-9]/g, '');
        default:
          return inputValue;
      }
    };

    const formatDisplayValue = (inputValue: string): string => {
      const value = formatInput(inputValue);

      if (!value) return '';

      switch (filter) {
        case FilterTypes.PERCENT:
          return `${value || ''}%`;
        default:
          return value;
      }
    };

    // restore the cursor position from last memorized position
    useEffect(() => {
      if (!cursorPos) return; // prevent running on start
      const input = innerRef.current;
      if (input) input.setSelectionRange(cursorPos, cursorPos);
    }, [cursorPos]);

    useEffect(() => {
      setCurrInput(value ?? '');
    }, [value]);

    const onInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      if (invalid) return;
      onBlur?.(event);
    };

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      // remember the cursor position before the value was formatted
      const { value, selectionStart } = event.target;
      const cursorStart = selectionStart ?? 0;
      setCursor(cursorStart);
      setCurrInput(value);

      // account for the extra characters and spaces formatters might add
      switch (filter) {
        case FilterTypes.ZIP: {
          // past each grouping of 3 we insert a space/dash accounted
          // by moving cursor 1 char
          if ((event?.target.selectionStart ?? NaN) % 4 === 0) {
            setCursor(cursorStart + 1);
          }
          break;
        }
        case FilterTypes.PERCENT: {
          // account for the zero we insert when user inputs only a dot
          if (value === '.') {
            setCursor(cursorStart + 1);
          }
          break;
        }
        default:
      }
      onChange({
        ...event,
        target: {
          ...event.target,
          value: formatInput(value),
          name: name as string,
        },
      });
    };

    return (
      <Styled.Container style={style}>
        <Styled.Label hideLabel={hideLabel} htmlFor={id}>
          {label}
        </Styled.Label>
        {hint && <Styled.Hint data-testid={`${dataTestId}-hint`}>{hint}</Styled.Hint>}
        <Styled.Wrapper invalid={invalid} disabled={disabled}>
          <Styled.Input
            id={id}
            data-testid={dataTestId}
            placeholder={placeholder}
            name={name}
            onBlur={onInputBlur}
            onChange={onInputChange}
            value={formatDisplayValue(currInput as string)}
            readOnly={disabled}
            disabled={disabled}
            maxLength={maxLength}
            ref={innerRef}
            aria-invalid={invalid}
            aria-errormessage={`${id}-error`}
            {...rest}
          />
          {invalid && (
            <Styled.ErrorIcon>
              <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
            </Styled.ErrorIcon>
          )}
        </Styled.Wrapper>
        {invalid && <Styled.ErrorMessage id={`${id}-error`}>{errorMessage}</Styled.ErrorMessage>}
      </Styled.Container>
    );
  }
);

Input.displayName = 'Input';

Input.defaultProps = {
  hideLabel: false,
  filter: '',
  style: {},
  errorMessage: '',
  invalid: false,
  onBlur: noop,
  'data-testid': undefined,
  disabled: false,
  maxLength: undefined,
};

export default Input;
