import { isAfter, isBefore, isMatch, isSameDay, parse } from 'date-fns';

export const isBeforeOrEqual = (day: Date, to: Date) => isBefore(day, to) || isSameDay(day, to);

export const isAfterOrEqual = (day: Date, from: Date) => isAfter(day, from) || isSameDay(day, from);

export const formatPersistedDate = (date: string) => {
  if (!date) return undefined;
  const format = isMatch(date, 'yyyy-MM-dd') ? 'yyyy-MM-dd' : 'dd/MM/yyyy';
  return parse(date, format, new Date());
};
