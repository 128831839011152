import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import noop from 'lodash/noop';
import Styled from '../Inputs.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

type Props = {
  id: string;
  label: string;
  name: string;
  value?: number | string;
  disabled?: boolean;
  hideLabel?: boolean;
  placeholder?: string;
  errorMessage?: string;
  invalid?: boolean;
  style?: React.CSSProperties;
  onChange?: (...arg: any) => void;
  onBlur?: (...arg: any) => void;
  'data-testid'?: string;
};

const CurrencyInput = forwardRef<HTMLInputElement, Props>(
  (
    { id, label, disabled, hideLabel, name, value, placeholder, errorMessage, invalid, style, onChange, onBlur, 'data-testid': dataTestId },
    ref
  ) => {
    const formatCurrency = useCallback((value: string) => {
      if (!value) return '';

      if (value === '.') return '0.';

      value = value.replace(/[^\d.]/g, '');

      const parts = value.split('.');
      let integerPart = parts[0];
      let decimalPart = parts[1];

      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      if (decimalPart !== undefined) {
        // Limit to only two decimal places
        decimalPart = decimalPart.substring(0, 2);
        return `${integerPart}.${decimalPart}`;
      }
      return integerPart;
    }, []);

    const [currencyValue, setCurrencyValue] = useState({
      actualValue: value || '',
      maskedValue: value ? formatCurrency(value.toString()) : '',
    });

    useEffect(() => {
      setCurrencyValue(() => ({
        actualValue: value || '',
        maskedValue: value ? formatCurrency(value.toString()) : '',
      }));
    }, [formatCurrency, value]);

    const handleCurrencyOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      if (invalid) return;
      onBlur?.({
        ...event,
        target: { value: currencyValue.actualValue, name },
      });
    };

    const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value: eventValue } = event.target;
      const formattedValue = formatCurrency(eventValue.replace(/,/g, '').replace(/[^0-9.]/g, ''));
      const actualValue = formattedValue.replace(/,/g, '');

      setCurrencyValue(() => ({
        actualValue,
        maskedValue: formattedValue,
      }));
      onChange?.({ target: { value: actualValue, name } });
    };

    return (
      <Styled.Container style={style}>
        <Styled.Label hideLabel={hideLabel} htmlFor={id}>
          {label}
        </Styled.Label>
        <Styled.Wrapper invalid={invalid} disabled={disabled}>
          <Styled.Input
            id={id}
            placeholder={placeholder}
            type="text"
            disabled={disabled}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onBlur={handleCurrencyOnBlur}
            onChange={handleCurrencyChange}
            value={currencyValue.maskedValue ? `$${currencyValue.maskedValue}` : currencyValue.maskedValue}
            name={name}
            ref={ref}
            data-testid={dataTestId}
            aria-invalid={invalid}
            aria-errormessage={`${id}-error`}
          />
          {invalid && (
            <Styled.ErrorIcon>
              <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
            </Styled.ErrorIcon>
          )}
        </Styled.Wrapper>
        {invalid && <Styled.ErrorMessage id={`${id}-error`}>{errorMessage}</Styled.ErrorMessage>}
      </Styled.Container>
    );
  }
);

CurrencyInput.displayName = 'CurrencyInput';

CurrencyInput.defaultProps = {
  disabled: false,
  hideLabel: false,
  placeholder: '',
  value: '',
  errorMessage: '',
  invalid: false,
  style: {},
  onChange: noop,
  onBlur: noop,
  'data-testid': '',
};

export default CurrencyInput;
