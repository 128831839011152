import Form from '@components/Form/Form';
import { FlowNode } from '@models/flow.model';
import { OptionStruct } from '@models/option.model';
import OptionGroup from '@spruce/Option/OptionGroup';
import Tooltip from '@spruce/Tooltip/Tooltip';
import { useResponsive } from 'hooks/responsive';
import get from 'lodash/get';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useRef } from 'react';
import { BackButton } from '..';
import QuestionStyles from './Question.styles';

type Props = {
  question: FlowNode;
  namespace?: string;
  submitting?: boolean;
  onAnswer: (question: FlowNode, answer?: any) => void;
  extraParams?: { [key: string]: any };
  style?: React.CSSProperties;
  data?: any;
  onBack?: () => void;
};

const Question = ({ question, submitting = false, onAnswer, namespace, extraParams, data, style, onBack }: Props): JSX.Element => {
  const { t } = useTranslation(namespace);
  const { isTabletOrMobile } = useResponsive();
  const questionRef = useRef<HTMLDivElement>(null);

  const onSubmit = (ans?: any) => onAnswer(question, ans);

  useEffect(() => {
    // ensure when the question is rendered that the question header is brought to the top of the screen
    questionRef.current?.scrollIntoView({ block: 'start' });
  }, [question.id]);

  const renderSubheader = useCallback((): string | JSX.Element => {
    if (typeof question.subHeader === 'string') {
      return t(question.subHeader);
    }
    const SubheaderComponent = question.subHeader as ({ ...props }: Record<string, any>) => JSX.Element;
    return <SubheaderComponent />;
  }, [question.subHeader, t]);

  const renderComponent = () => {
    switch (question.type) {
      case 'options': {
        const options = question?.options?.map((option) => ({
          ...option,
          label: typeof option.label === 'string' ? t(option.label) : option.label,
          errorMessage: option?.errorMessage ? t(option.errorMessage) : undefined,
        })) as OptionStruct[];

        return (
          <>
            <OptionGroup
              defaultValue={get(data, (question.secondaryFieldName ?? question.fieldName) as string)}
              options={options}
              key={question.id}
              onChange={onSubmit}
              style={question.style}
            />
            {question?.footer && question.footer}
          </>
        );
      }
      case 'customized': {
        const SpecificForm = question.component as ({ ...props }: Record<string, any>) => JSX.Element;
        return (
          <SpecificForm
            key={question.id}
            {...(question.componentProps ?? {})}
            onSubmit={onSubmit}
            onBack={isTabletOrMobile ? onBack : undefined}
            submitting={submitting}
          />
        );
      }
      default: {
        return (
          <Form
            data={data}
            question={question}
            onSubmit={onSubmit}
            submitting={submitting}
            namespace={namespace}
            disabled={submitting}
            Footer={question?.footer}
            style={question.style}
            BackButton={isTabletOrMobile ? <BackButton onClick={() => onBack?.()} visible={!!onBack} /> : undefined}
          />
        );
      }
    }
  };

  return (
    <QuestionStyles.Wrapper role="group" tabIndex={0} style={style} id={question.id} data-testid={question.id} aria-disabled={submitting}>
      <QuestionStyles.DesktopView>
        <BackButton onClick={() => onBack?.()} visible={!!onBack} />
      </QuestionStyles.DesktopView>
      <QuestionStyles.ContentWrapper ref={questionRef}>
        {question.header && (
          <QuestionStyles.HeaderWrapper>
            <QuestionStyles.Header data-testid={`${question.id}-header`}>{t(question.header, extraParams)}</QuestionStyles.Header>
            {question.subHeader && (
              <QuestionStyles.SubHeader data-testid={`${question.id}-sub-header`}>{renderSubheader()}</QuestionStyles.SubHeader>
            )}
            {question.tooltip && (
              <Tooltip
                title={t(question.tooltip.title)}
                content={
                  Array.isArray(question.tooltip.content) ? question.tooltip.content.map((text) => t(text)) : t(question.tooltip.content)
                }
              />
            )}
          </QuestionStyles.HeaderWrapper>
        )}
        {renderComponent()}
        {isTabletOrMobile && !['form', 'customized'].includes(question.type) && (
          <BackButton onClick={() => onBack?.()} visible={!!onBack} />
        )}
      </QuestionStyles.ContentWrapper>
    </QuestionStyles.Wrapper>
  );
};

Question.defaultProps = {
  submitting: false,
  namespace: 'default',
  extraParams: {},
  style: {},
  data: undefined,
};

export default Question;
