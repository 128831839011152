import { useEffect, useState, FunctionComponent, CSSProperties } from 'react';
import Lottie from 'lottie-react';
import Styled from './Loader.styled';
import PineTree from './static/pine-tree.json';

interface LoaderProps {
  timeout?: number;
  style?: CSSProperties;
}

/**
 * This component is NOT part of spruce because of the `lottie-react` dependency.
 */
const Loader: FunctionComponent<LoaderProps> = ({ timeout, style }) => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    /**
     * Using settimeout to prevent the loading animation from "flickering" on fast requests <= 900ms
     */
    const animation = setTimeout(() => {
      setShowLoader(true);
    }, timeout || 0);

    return () => {
      clearTimeout(animation);
      setShowLoader(false);
    };
  }, [timeout]);

  return showLoader ? (
    <Styled.Wrapper style={style}>
      <Lottie
        animationData={PineTree}
        loop
        style={{
          width: '500px',
        }}
      />
    </Styled.Wrapper>
  ) : null;
};

Loader.defaultProps = {
  timeout: undefined,
  style: undefined,
};

export default Loader;
