import FormStyles from '@containers/long-app/styles/Form.styles';
import TypeFormsStyles from '@containers/long-app/styles/TypeForms.styles';
import { DetailedPropertyDto } from '@pinecorpca/evergreen';
import { useEffect, useRef } from 'react';
import PropertyForm from './PropertyForm';
import PropertyFormSkeleton from './Skeleton';

type Props = {
  additionalProperties?: DetailedPropertyDto[];
  loading: boolean;
};

const PropertyFormList = ({ additionalProperties, loading }: Props) => {
  const formRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (additionalProperties?.length) {
      formRefs?.current[additionalProperties.length - 1]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [additionalProperties?.length]);

  return (
    <TypeFormsStyles.FormList>
      {additionalProperties?.map((property: DetailedPropertyDto, index: number) => (
        <TypeFormsStyles.FormListItem key={property.id}>
          <PropertyForm
            ref={(el) => {
              formRefs.current[index] = el;
            }}
            property={property}
            formIndex={index + 1}
          />
        </TypeFormsStyles.FormListItem>
      ))}
      {loading && (
        <TypeFormsStyles.FormListItem key="property_skeleton">
          <TypeFormsStyles.Container>
            <FormStyles.FormWrapper>
              <PropertyFormSkeleton />
            </FormStyles.FormWrapper>
          </TypeFormsStyles.Container>
        </TypeFormsStyles.FormListItem>
      )}
    </TypeFormsStyles.FormList>
  );
};

PropertyFormList.defaultProps = {
  additionalProperties: [],
};

export default PropertyFormList;
