import DeletionPopup from '@containers/long-app/components/DeletionPopup/DeletionPopup';
import FormStyles from '@containers/long-app/styles/Form.styles';
import TypeFormsStyles from '@containers/long-app/styles/TypeForms.styles';
import { NAMESPACE } from '@models/enums/namespace.enum';
import CurrencyInput from '@spruce/Inputs/Currency/CurrencyInput';
import { DownPaymentSourceOptions } from 'hooks/models/downpayments.model';
import { useTranslation } from 'next-i18next';
import { forwardRef, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

type DownpaymentFormProps = {
  fieldIndex: number;
  isLoading: boolean;
  onDelete: () => Promise<any>;
};

const DownpaymentForm = forwardRef<HTMLDivElement, DownpaymentFormProps>(({ fieldIndex, onDelete, isLoading }, ref) => {
  const { control } = useFormContext();
  const { t } = useTranslation(NAMESPACE.LONGAPP);

  const [downPaymentSource] = useWatch({
    control,
    name: [`downpayments.${fieldIndex}.source`],
  });

  const title = useMemo(
    () => DownPaymentSourceOptions.find((source) => source.value === downPaymentSource)?.label as string,
    [downPaymentSource]
  );

  return (
    <TypeFormsStyles.Container ref={ref}>
      <FormStyles.FormTitleWrapper>
        <FormStyles.FormTitle>{t(title ?? '')}</FormStyles.FormTitle>
        <DeletionPopup
          data-testid={`downpayments-${fieldIndex}-delete`}
          style={{ margin: '12px' }}
          isLoading={isLoading}
          onConfirm={onDelete}
          title={t('DOWNPAYMENT_DELETE_DIALOG')}
        />
      </FormStyles.FormTitleWrapper>
      <FormStyles.FormWrapper>
        <FormStyles.FormWrapper>
          <FormStyles.SubFormWrapper>
            <Controller
              control={control}
              name={`downpayments.${fieldIndex}.amount`}
              rules={{
                required: {
                  value: true,
                  message: t('DOWNPAYMENT_AMOUNT_REQUIRED'),
                },
                min: {
                  value: 0.1,
                  message: t('DOWNPAYMENT_AMOUNT_MIN'),
                },
              }}
              render={({ field: { value, ref, onChange, name }, fieldState: { error } }) => (
                <CurrencyInput
                  style={{
                    marginBottom: '20px',
                  }}
                  name={name}
                  id={name}
                  data-testid={`downpayments-${fieldIndex}-amount`}
                  placeholder="$0"
                  label={t('AMOUNT')}
                  invalid={!!error}
                  value={value}
                  onChange={onChange}
                  errorMessage={error?.message}
                  ref={ref}
                />
              )}
            />
          </FormStyles.SubFormWrapper>
        </FormStyles.FormWrapper>
      </FormStyles.FormWrapper>
    </TypeFormsStyles.Container>
  );
});

DownpaymentForm.displayName = 'DownpaymentForm';

export default DownpaymentForm;
