import { CSSProperties } from 'styled-components';
import { forwardRef, useCallback } from 'react';
import en from 'antd/lib/date-picker/locale/en_US';
import { format, isBefore, isMatch, parse, setDate, isEqual, subYears } from 'date-fns';
import Styled from './DatePicker.styled';
import { DateConstants } from './picker.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';

interface Props {
  onChange: (args: string | null) => void;
  value?: Date | string;
  invalid?: boolean;
  errorMessage?: string;
  picker?: 'month' | 'year' | 'date';
  to?: Date | undefined;
  from?: Date | undefined;
  label?: string;
  name: string;
  id: string;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  className?: string;
  disabled?: boolean;
}

const DatePicker = forwardRef<any, Props>(
  ({ onChange, name, label, value, picker, style, invalid, errorMessage, from, to, id, className, labelStyle, disabled }, ref) => {
    const formatDate = useCallback(
      (date: Date | string | undefined) => {
        if (!date) return undefined;
        if (typeof date === 'string') {
          const format = isMatch(date, 'yyyy-MM-dd') ? 'yyyy-MM-dd' : 'dd/MM/yyyy';
          return parse(date, format, new Date());
        }
        return new Date(value as Date);
      },
      [value]
    );

    const disabledDate = useCallback(
      (current: Date) => {
        if (!to && !from) {
          const minDate = subYears(new Date(), 100);
          return isEqual(current, minDate) || isBefore(current, minDate);
        }
        return current > (to ?? 0) || current < (from ?? 0);
      },
      [to, from]
    );

    const defaultPickerDate = useCallback(
      (value: Date | string | undefined) => {
        if (value) return value as Date;
        if (to && new Date() > to) return to;
        return new Date();
      },
      [to]
    );

    const handleOnChange = (date: Date | null) => {
      if (!date) {
        onChange(date);
        return;
      }

      const adjustedDate = picker === 'month' ? setDate(date, 1) : date;
      const formattedDate = format(adjustedDate, DateConstants.BE_DATE_FORMAT);
      onChange(formattedDate);
    };

    return (
      <Styled.Wrapper invalid={invalid ?? false}>
        {label && (
          <Styled.Label style={labelStyle} htmlFor={name}>
            {label}
          </Styled.Label>
        )}
        <>
          <Styled.StyledDatePicker
            data-testid={`date-picker_${id}`}
            value={formatDate(value)}
            defaultPickerValue={defaultPickerDate(formatDate(value))}
            format={picker === 'month' ? 'MM/yyyy' : 'dd/MM/yyyy'}
            disabledDate={disabledDate}
            picker={picker}
            id={id}
            className={className}
            onChange={handleOnChange}
            placeholder={picker === 'month' ? DateConstants.MONTH_PLACEHOLDER : DateConstants.FE_DATE_PLACEHOLDER}
            suffixIcon={<FontAwesomeIcon icon={faCalendar} size="lg" />}
            ref={ref}
            showToday={false}
            allowClear
            invalid={invalid ?? false}
            locale={en}
            disabled={disabled ?? false}
            style={style}
            aria-invalid={invalid}
            aria-errormessage={`${id}-error`}
          />
          {invalid && <Styled.ErrorMessage id={`${id}-error`}>{errorMessage}</Styled.ErrorMessage>}
        </>
      </Styled.Wrapper>
    );
  }
);

DatePicker.displayName = 'DatePicker';

DatePicker.defaultProps = {
  value: undefined,
  style: {},
  picker: 'date',
  to: undefined,
  from: undefined,
  className: '',
  invalid: false,
  errorMessage: '',
};

export default DatePicker;
