import { FormTitleSkeleton, InputSkeleton } from '@containers/long-app/styles/EntrySkeletons.styles';
import FormStyles from '@containers/long-app/styles/Form.styles';

const SelfEmployedFormSkeleton = () => (
  <section data-testid="SelfEmployedSkeleton_form">
    <FormTitleSkeleton />
    <FormStyles.SubFormWrapper>
      <InputSkeleton>
        <div className="label" />
        <div className="input" />
      </InputSkeleton>
      <InputSkeleton>
        <div className="label" />
        <div className="input" />
      </InputSkeleton>
      <InputSkeleton>
        <div className="label" />
        <div className="input" />
      </InputSkeleton>
      <InputSkeleton>
        <div className="label" />
        <div className="input" />
      </InputSkeleton>
    </FormStyles.SubFormWrapper>

    <FormTitleSkeleton />
    <FormStyles.SubFormWrapper>
      <InputSkeleton>
        <div className="label" />
        <div className="input" />
      </InputSkeleton>
      <InputSkeleton>
        <div className="label" />
        <div className="input" />
      </InputSkeleton>
    </FormStyles.SubFormWrapper>
  </section>
);

export default SelfEmployedFormSkeleton;
