export const formatZipInput = (value: string) => {
  const zip = value.replace(/ /g, '').replace(/[^0-9a-zA-Z]/g, '');
  if (value.length < 4) return zip.toUpperCase();
  return `${zip.toUpperCase().slice(0, 3)} ${zip.toUpperCase().slice(3, 6)}`;
};

export const formatPercentInput = (value: string) => {
  if (!value) return '';

  const cleanValue = value
    .replace(/[^-\d.]/g, '')
    .replace(/^-?/, '$&') // Keep the first optional negative sign
    .replace(/(?!^)-/g, '') // Remove any additional negative signs
    .replace(/\./, '$&') // Keep the first decimal point
    .replace(/^(-)?\.(\d*)$/, '$10.$2'); // Add a leading zero if there's only a decimal point

  const matches = cleanValue.split('.');
  if (matches.length > 1) {
    const decimal = matches[1].slice(0, 2);
    return `${matches[0]}.${decimal}`;
  }

  return cleanValue;
};
