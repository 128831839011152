import Analytics from '@components/Analytics/Analytics';
import SEO from '@components/Analytics/SEO';
import { CloseButton, ErrorBoundary, ToastContainer } from '@components/index';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { DehydratedState, HydrationBoundary } from '@tanstack/react-query';
import WithAxios from 'WithAxios';
import { AnalyticsProvider, PartnerProvider } from 'contexts';
import { CustomQueryClientProvider } from 'contexts/CustomQueryClientProvider';
import { useThemePreference } from 'hooks/theme';
import { ldProvider } from 'launchDarklyProvider.config';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { NextPage } from 'next';
import { appWithTranslation } from 'next-i18next';
import type { AppProps as NextAppProps } from 'next/app';
import { ComponentType, ReactNode } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import '../styles/antd.less';
import GlobalStyles from '../styles/globalStyles';
import DataDogInit from '@components/Analytics/DataDogInit';
import AmplitudeInit from '@components/Analytics/AmplitudeInit';
import { MaintenanceToast } from '@components/common';

config.autoAddCss = false;

type GetLayout = (page: ReactNode) => ReactNode;

type Page<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & { getLayout?: GetLayout };

type AppProps<P = Record<string, unknown>> = NextAppProps<P> & { Component: Page<P> };

function App({ Component, pageProps }: AppProps<{ host: string; dehydratedState: DehydratedState }>) {
  const getLayout = Component.getLayout || ((page) => page);
  const theme = useThemePreference(pageProps?.host);

  return (
    <>
      <Analytics />
      <SEO />
      <DataDogInit />
      <ThemeProvider theme={theme}>
        <CustomQueryClientProvider>
          <PartnerProvider host={pageProps.host}>
            <AmplitudeInit />
            <AnalyticsProvider>
              <GlobalStyles />
              <ToastContainer limit={1} closeButton={<CloseButton />} />
              <WithAxios>
                <HydrationBoundary state={pageProps.dehydratedState}>
                  <ErrorBoundary>
                    <MaintenanceToast />
                    <main>{getLayout(<Component {...pageProps} />)}</main>
                  </ErrorBoundary>
                </HydrationBoundary>
              </WithAxios>
            </AnalyticsProvider>
          </PartnerProvider>
        </CustomQueryClientProvider>
      </ThemeProvider>
    </>
  );
}

export default withLDProvider(ldProvider)(appWithTranslation<never>(App) as ComponentType);
