import { API, LONG_APP_ROUTES, NAMESPACE } from '@models/enums';
import { FlowRoot } from '@models/flow.model';
import { FlowState } from '@models/flowState.model';
import { BorrowerType } from '@pinecorpca/evergreen';
import IncomesMain from '../components/forms/IncomesMain';
import { currentIncomesSubmitHandler, isCurrentIncomesComplete, isPreviousIncomesComplete } from '../utils/incomes.utils';
import { Alert } from '@components/common';

export const IncomesForm = (borrowerType: BorrowerType | undefined): FlowRoot => ({
  root: 'current_income_types',
  id: 'income',
  pageTitle: 'INCOMES_PAGE_TITLE',
  flowName: 'income',
  nextSecUrl: `/applications/:applicationId/borrowers/:borrowerId/${LONG_APP_ROUTES.ASSETS}`,
  current_income_types: {
    id: 'current_income_types',
    type: 'customized',
    component: IncomesMain,
    componentProps: {
      isCurrent: true,
    },
    header: borrowerType === BorrowerType.Primary ? 'INCOMES_HEADER' : 'INCOMES_HEADER_CO',
    subHeader: borrowerType === BorrowerType.Primary ? 'INCOMES_SELECT_START' : 'INCOMES_SELECT_START_CO',
    next: FlowState.DYNAMIC,
    submitHandler: currentIncomesSubmitHandler,
    isCompleteHelper: isCurrentIncomesComplete,
    dataPath: `${API.INCOMES}`,
  },
  previous_income_types: {
    id: 'previous_income_types',
    type: 'customized',
    component: IncomesMain,
    componentProps: {
      isCurrent: false,
    },
    header: borrowerType === BorrowerType.Primary ? 'PREVIOUS_INCOMES_HEADER' : 'PREVIOUS_INCOMES_HEADER_CO',
    subHeader: () => <Alert message="PREVIOUS_INCOMES_SUBHEADER" type="warning" namespace={NAMESPACE.LONGAPP} />,
    next: FlowState.NEXTSECTION,
    isCompleteHelper: isPreviousIncomesComplete,
    dataPath: `${API.INCOMES}`,
  },
});
