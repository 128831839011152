import styled, { css } from 'styled-components';

export const StyledAutoComplete = {
  Wrapper: styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 30px;
  `,
  Input: styled.input`
    border: none;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 50px;
    height: 64px;
    width: 100%;

    border: 1px solid ${({ theme }) => theme.border.primary};

    font-family: var(--font-futura);
    font-weight: 400;
    color: ${({ theme }) => theme.text.primary};
    font-size: 18px;
    line-height: 22px;

    :disabled {
      pointer-events: none;
      background-color: ${({ theme }) => theme.background.disabled};
    }

    :focus {
      outline: none;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 0 0 0 2px ${({ theme }) => theme.border.active};
    }

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      font-size: 16px;
      line-height: 21px;
    }
  `,
  InputWrapper: styled.div`
    :focus-within {
      input {
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1), 0 0 0 2px ${({ theme }) => theme.border.active};
      }
    }
  `,
  SearchIconWrapper: styled.div`
    position: absolute;
    left: 24px;
    top: calc(50% - 8px);
  `,
  InputEventWrapper: styled.div`
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
  `,
  ClearButton: styled.button`
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 18px;
    width: 18px;
    padding: 0; // required for safari on iOS to center button image
    svg {
      opacity: 0.4;
    }

    :hover {
      svg {
        opacity: 1;
      }
      transform: scale(1.1);
    }
  `,
  ItemList: styled.ul`
    position: absolute;
    z-index: 999;
    width: 100%;
    padding: 0;
    list-style-type: none;

    display: flex;
    flex-direction: column;

    background: ${({ theme }) => theme.background.primary};
    border: 1px solid rgb(204, 204, 204);
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 12px;

    margin-top: 6px;
    max-height: 240px;

    overflow-y: auto;
  `,
  ListItem: styled.li`
    position: relative;
    :not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.border.primary};
    }
  `,
  Item: styled.button<{ chevronRight: string; showChevron: boolean }>`
    border: none;
    font-family: var(--font-futura);
    font-weight: 300;
    color: ${({ theme }) => theme.text.secondary};
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    background: ${({ theme }) => theme.background.primary};
    cursor: pointer;
    padding: 14px;
    margin: 0;
    width: 100%;
    ${({ chevronRight, showChevron }) =>
      showChevron &&
      css`
        background: url(${chevronRight}) no-repeat 98% center;
        background-size: 8px 12px;
      `}

    :hover, :focus {
      background-color: rgba(0, 0, 0, 0.04);
    }

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      flex-direction: column;
      align-items: flex-start;
    }
  `,
  Text: styled.span`
    font-family: var(--font-futura);
    font-weight: 400;
    color: ${({ theme }) => theme.text.primary};
    font-size: 14px;
    line-height: 18px;
    margin-right: 4px;
  `,
};
