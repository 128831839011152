/**
 * Since Evergreen does not follow the country code pattern
 * that canada-post uses (and most others) we need to map the values
 */

import { Province } from '@pinecorpca/evergreen';

export const provinceMapper: Record<string, string> = {
  AB: Province.Alberta,
  BC: Province.BritishColumbia,
  MB: Province.Manitoba,
  NL: Province.NewfoundlandAndLabrador,
  NB: Province.NewBrunswick,
  NT: Province.NorthwestTerritories,
  NS: Province.NovaScotia,
  NU: Province.Nunavut,
  ON: Province.Ontario,
  PE: Province.PrinceEdwardIsland,
  QC: Province.Quebec,
  SK: Province.Saskatchewan,
  YT: Province.Yukon,
};
