import styled from 'styled-components';

const StyledCheckbox = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Checkbox: styled.div<{ $invalid?: boolean }>`
    display: flex;

    .checkbox__label {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: var(--font-futura);
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
    }

    input[type='checkbox'] {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      min-width: 36px;
      max-width: 36px;
      min-height: 36px;
      max-height: 36px;
      z-index: 1;
    }

    .checkbox__label::before {
      content: '';
      min-width: 20px;
      max-width: 20px;
      min-height: 20px;
      max-height: 20px;
      border: 1px solid ${({ theme, $invalid }) => ($invalid ? theme.border.error : theme.border.active)};
      border-radius: 6px;
      background: ${({ theme }) => theme.background.primary};
      transition: background 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    input[type='checkbox']:checked + .checkbox__label::before {
      background: ${({ theme }) => theme.palette.pine.default};
      background-image: url('/images/checkmarkWhite.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 14px 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `,
};
export default StyledCheckbox;
