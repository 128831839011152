import { BusinessType } from '@pinecorpca/evergreen';

export const BusinessTypeOptions = [
  {
    id: 'SOLE_PROPRIETORSHIP',
    value: BusinessType.SoleProprietorship,
    label: 'Sole proprietorship',
  },
  {
    id: 'PARTNERSHIP',
    value: BusinessType.Partnership,
    label: 'Partnership',
  },
  {
    id: 'CORPORATION',
    value: BusinessType.Corporation,
    label: 'Corporation',
  },
];
