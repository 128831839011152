import { DwellingStatus } from '@pinecorpca/evergreen';

export const DwellingStatusOptions = [
  {
    id: 'RENT',
    value: DwellingStatus.Rent,
    label: 'APPLICANT_DWELLING_STATUS_RENT',
  },
  {
    id: 'OWN',
    value: DwellingStatus.Own,
    label: 'APPLICANT_DWELLING_STATUS_OWN',
  },
  {
    id: 'FREE',
    value: DwellingStatus.Free,
    label: 'APPLICANT_DWELLING_STATUS_FREE',
  },
];
