import { API } from '@models/enums/api.enum';
import {
  MortgageApplicationClientDto,
  PropertyControllerApiApiBorrowersBorrowerIdAdditionalPropertiesPostRequest as PropertyAdditionalPostRequest,
  PropertyControllerApiApiPropertyPropertyIdDeleteRequest as PropertyDeleteRequest,
  PropertyControllerApiApiPropertyPropertyIdPatchRequest as PropertyPatchRequest,
  PropertyControllerApiApiPropertyPropertyIdCoownerPostRequest as CoOwnerPostRequest,
  PropertyControllerApiApiPropertyPropertyIdCoownerDeleteRequest as CoOwnerDeleteRequest,
  BorrowerType,
  RentalIncome,
} from '@pinecorpca/evergreen';
import { propertyApi } from 'api/evergreen';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useBorrowerQuery } from './borrower.hooks';
import { useRouter } from 'next/router';
import partition from 'lodash/partition';
import { useMemo } from 'react';

export const useAdditionalPropertyQuery = () => {
  const {
    query: { borrowerId },
  } = useRouter();
  const { data: borrower } = useBorrowerQuery(borrowerId as string);

  const [rentalIncomeProperties, additionalProperties] = useMemo(
    () =>
      partition(borrower?.additionalProperties || [], (property) =>
        borrower?.incomes?.some((income) => (income.income as RentalIncome)?.property?.id === property.id)
      ),
    [borrower?.additionalProperties, borrower?.incomes]
  );

  return {
    additionalProperties: additionalProperties || [],
    rentalIncomeProperties: rentalIncomeProperties || [],
  };
};

export const useAdditionalPropertyMutationOnPost = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (payload: PropertyAdditionalPostRequest) => propertyApi.apiBorrowersBorrowerIdAdditionalPropertiesPost(payload),
    onSuccess: ({ data: property }) => {
      queryClient.setQueryData(
        [API.MORTGAGE_APPLICATION, applicationId as string],
        (current: MortgageApplicationClientDto | undefined) => ({
          ...current,
          borrowers: current?.borrowers?.map((borrower) =>
            borrower.id === property.borrowerId
              ? {
                  ...borrower,
                  additionalProperties: [...(borrower?.additionalProperties || []), property],
                }
              : borrower
          ),
        })
      );
    },
  });
};

export const useAdditionalPropertyMutationOnPatch = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (payload: PropertyPatchRequest) => propertyApi.apiPropertyPropertyIdPatch(payload),
    onSuccess: ({ data: updatedProperty }) => {
      queryClient.setQueryData([API.MORTGAGE_APPLICATION, applicationId as string], (current: MortgageApplicationClientDto | undefined) => {
        const borrower = current?.borrowers?.find(({ id }) => id === updatedProperty.borrowerId);
        const properties = borrower?.additionalProperties?.map((property) =>
          property.id === updatedProperty.id ? updatedProperty : property
        );
        return {
          ...current,
          borrowers: current?.borrowers?.map((borrower) =>
            borrower.id === updatedProperty.borrowerId ? { ...borrower, additionalProperties: properties } : borrower
          ),
        };
      });
    },
  });
};

export const useCoOwnerPropertyMutationOnPost = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (payload: CoOwnerPostRequest) => propertyApi.apiPropertyPropertyIdCoownerPost(payload),
    onSuccess: ({ data: updateCoOwner }) => {
      queryClient.setQueryData([API.MORTGAGE_APPLICATION, applicationId as string], (current: MortgageApplicationClientDto | undefined) => {
        const mainBorrower = current?.borrowers?.find(({ borrowerType }) => borrowerType === BorrowerType.Primary);
        const properties = mainBorrower?.additionalProperties?.map((property) =>
          property.id === updateCoOwner.propertyId
            ? {
                ...property,
                coOwners: [...(property?.coOwners || []), updateCoOwner.borrowerId as string],
              }
            : property
        );
        return {
          ...current,
          borrowers: current?.borrowers?.map((borrower) =>
            borrower.id === mainBorrower?.id ? { ...borrower, additionalProperties: properties } : borrower
          ),
        };
      });
    },
  });
};

export const useCoOwnerPropertyMutationOnDelete = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (request: CoOwnerDeleteRequest) => propertyApi.apiPropertyPropertyIdCoownerDelete(request),
    onSuccess: (_, request) => {
      queryClient.setQueryData([API.MORTGAGE_APPLICATION, applicationId as string], (current: MortgageApplicationClientDto | undefined) => {
        const mainBorrower = current?.borrowers?.find(({ borrowerType }) => borrowerType === BorrowerType.Primary);
        const properties = mainBorrower?.additionalProperties?.map((property) =>
          property.id === request.propertyId
            ? {
                ...property,
                coOwners: property?.coOwners?.filter((coOwner) => coOwner !== request.borrowerId),
              }
            : property
        );
        return {
          ...current,
          borrowers: current?.borrowers?.map((borrower) =>
            borrower.id === mainBorrower?.id ? { ...borrower, additionalProperties: properties } : borrower
          ),
        };
      });
    },
  });
};

export const useAdditionalPropertyMutationOnDelete = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (request: PropertyDeleteRequest & { borrowerId: string }) => propertyApi.apiPropertyPropertyIdDelete(request),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [API.MORTGAGE_APPLICATION, applicationId as string] }),
  });
};
