import { HiddenSpan } from './ScreenReaderOnly.styles';

interface ScreenReaderOnlyProps {
  children: React.ReactNode;
}

const ScreenReaderOnly = ({ children }: ScreenReaderOnlyProps) => (
  <HiddenSpan>{children}</HiddenSpan>
);

export default ScreenReaderOnly;
