import { API } from '@models/enums/api.enum';
import { FlowNode } from '@models/flow.model';
import { isMatch, format, parse } from 'date-fns';
import { TagEntry } from 'hooks/models/hooks.model';
import get from 'lodash/get';
import { formatPhoneNumber } from 'react-phone-number-input';
import { constructAddressString, formatCurrency } from 'utils/common.utils';

export const constructQuestionTags = (question: FlowNode, data: any, enableFormatters = true): TagEntry[] => {
  const tagEntries: TagEntry[] = [];
  const { type: questionType, isCompleteHelper, constructValueHelper, id: questionId, dataPath, label: questionLabel } = question;
  if (questionType === 'customized' && isCompleteHelper) {
    const required = get(data, dataPath as string);
    const helperValue = isCompleteHelper(required);
    const constructedValue = constructValueHelper ? constructValueHelper(required) : 'true';
    tagEntries.push({
      label: questionLabel ?? '',
      value: helperValue ? constructedValue : '',
      id: questionId,
    });
  }
  if (questionType === 'options') {
    const { tagLabel, api, fieldName, options } = question;
    const path = `${api}.${fieldName}`;
    const value = get(data, path);
    const option = options?.find((o) => o.value?.toString() === value?.toString());
    tagEntries.push({
      id: option?.id as string,
      label: tagLabel as string,
      value: option?.label as string,
      selectedOption: option?.value as string,
    });
  } else if (questionType === 'form') {
    const { inputs, api } = question;
    inputs?.forEach((input) => {
      const { type, label, fieldName, secondaryFieldName, id } = input;
      let value = get(data, `${api}.${fieldName}`) ?? get(data, `${api}.${secondaryFieldName}`);
      switch (type) {
        case 'phone': {
          if (!value) {
            if (input.rules?.required) value = '';
            else break;
          } else {
            value = enableFormatters ? formatPhoneNumber(value) : value;
          }
          tagEntries.push({ label: label as string, value, id });
          break;
        }
        case 'input': {
          if (!value) {
            if (input.rules?.required) value = '';
            else break;
          } else if (input.filter === 'currency') {
            value = enableFormatters ? formatCurrency(value) : value;
          }
          tagEntries.push({ label: label as string, value, id });
          break;
        }
        case 'radio':
        case 'select': {
          if (value == null) {
            if (input.rules?.required) value = '';
            else break;
          }
          tagEntries.push({ label: label as string, value, id });
          break;
        }
        case 'date':
        case 'dateOfBirth': {
          if (!value) {
            if (input.rules?.required) value = '';
            else break;
          } else {
            const dateFormat = isMatch(value, 'yyyy-MM-dd') ? 'yyyy-MM-dd' : 'dd/MM/yyyy';
            const formattedValue = format(parse(value, dateFormat, new Date()), 'MMMM d, yyyy');
            value = enableFormatters ? formattedValue : value;
          }
          tagEntries.push({
            label: label as string,
            value,
            id,
          });
          break;
        }
        case 'address': {
          if (!value) {
            value = '';
          } else {
            value = enableFormatters ? constructAddressString(value) : value;
          }
          tagEntries.push({
            label: label as string,
            value,
            id,
          });
          break;
        }
        case 'downpayment': {
          const purchasePrice = get(data, `${API.PROPERTY}.purchasePrice`);
          const downpayment = get(data, `${API.MORTGAGE_APPLICATION}.totalDownPayment`);
          const loanAmount = get(data, `${API.MORTGAGE_APPLICATION}.originalMortgageAmount`);
          tagEntries.push(
            {
              id: 'downpayment',
              label: 'PURCHASE_PRICE',
              fieldName: 'purchasePrice',
              value: enableFormatters ? formatCurrency(purchasePrice) : purchasePrice,
            },
            {
              id: 'downpayment',
              label: 'DOWNPAYMENT',
              fieldName: 'totalDownPayment',
              value: enableFormatters ? formatCurrency(downpayment) : downpayment,
            },
            {
              id: 'downpayment',
              label: 'DOWNPAYMENT_LOAN_AMOUNT',
              fieldName: 'originalMortgageAmount',
              value: enableFormatters ? formatCurrency(loanAmount) : loanAmount,
            }
          );
          break;
        }
        case 'loanAmount': {
          const estimatedValue = get(data, `${API.PROPERTY}.estimatedValue`);
          const originalMortgageAmount = get(data, `${API.MORTGAGE_APPLICATION}.originalMortgageAmount`);
          const remainingMortgageAmount = get(data, `${API.MORTGAGE_APPLICATION}.remainingMortgageAmount`);
          const additionalFundsAmount = get(data, `${API.MORTGAGE_APPLICATION}.additionalFundsAmount`);
          tagEntries.push({
            id: 'loanAmount',
            label: 'ESTIMATED_PRICE',
            fieldName: 'estimatedValue',
            value: enableFormatters ? formatCurrency(estimatedValue) : estimatedValue,
          });
          if (input.inputProps?.refinance) {
            tagEntries.push(
              {
                id: 'loanAmount',
                label: 'REMAINING_MORTGAGE_BALANCE',
                fieldName: 'remainingMortgageAmount',
                value: enableFormatters ? formatCurrency(remainingMortgageAmount) : remainingMortgageAmount,
              },
              {
                id: 'loanAmount',
                label: 'ADDITIONAL_FUNDS',
                fieldName: 'additionalFundsAmount',
                value: enableFormatters ? formatCurrency(additionalFundsAmount) : additionalFundsAmount,
              }
            );
          } else {
            tagEntries.push({
              id: 'loanAmount',
              label: 'REMAINING_MORTGAGE_BALANCE',
              fieldName: 'originalMortgageAmount',
              value: enableFormatters ? formatCurrency(originalMortgageAmount) : originalMortgageAmount,
            });
          }
          break;
        }
        default:
          break;
      }
    });
  }
  return tagEntries;
};
