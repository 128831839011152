import { LONG_APP_ROUTES } from '@models/enums';
import { API } from '@models/enums/api.enum';
import { FlowRoot } from '@models/flow.model';
import { FlowState } from '@models/flowState.model';
import { MaritalStatusOptions } from '@models/marital-status.constants';
import { DealPurpose, PropertyOccupationType } from '@pinecorpca/evergreen';
import { subYears } from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { commonAreAddressesEqual, constructAddressString } from 'utils/common.utils';
import CurrentAddress from '../components/current-address/CurrentAddress';
import { DwellingStatusOptions } from '../models/dwellingStatus.constants';
import { isCurrentAddressComplete, moveInDateWithinThreeYears } from '../utils/applicant-info.utils';

export const ApplicantInfoForm: FlowRoot = {
  root: 'personal_info',
  id: 'personal_details',
  pageTitle: 'Personal Details',
  flowName: 'personal',
  nextSecUrl: `/applications/:applicationId/borrowers/:borrowerId/${LONG_APP_ROUTES.INCOMES}`,
  personal_info: {
    id: 'personal_info',
    type: 'form',
    header: 'APPLICANT_NAME',
    next: 'current_address',
    api: API.BORROWER,
    style: { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '14px 48px' },
    inputs: [
      {
        id: 'first_name',
        label: 'APPLICANT_FIRST_NAME',
        type: 'input',
        fieldName: 'firstName',
        placeholder: 'APPLICANT_FIRST_NAME_PLACEHOLDER',
        rules: {
          required: {
            value: true,
            message: 'FIRST_NAME_REQUIRED',
          },
          maxLength: { value: 75, message: 'MAXIMUM_LENGTH_EXCEEDED' },
        },
      },
      {
        id: 'middle_initial',
        label: 'APPLICANT_MIDDLE_INITIAL',
        type: 'input',
        fieldName: 'middleName',
      },
      {
        id: 'last_name',
        label: 'APPLICANT_LAST_NAME',
        type: 'input',
        fieldName: 'lastName',
        placeholder: 'APPLICANT_LAST_NAME_PLACEHOLDER',
        rules: {
          required: {
            value: true,
            message: 'LAST_NAME_REQUIRED',
          },
          maxLength: { value: 75, message: 'MAXIMUM_LENGTH_EXCEEDED' },
        },
      },
      {
        id: 'phone_number',
        label: 'APPLICANT_CONTACT_PHONE',
        type: 'phone',
        fieldName: 'phoneNumber',
        rules: {
          required: {
            value: true,
            message: 'PHONE_REQUIRED',
          },
          validate: (value: string) => isPossiblePhoneNumber(value) || 'PHONE_INVALID',
        },
      },
      {
        id: 'date-of-birth',
        label: 'DATE_OF_BIRTH',
        type: 'dateOfBirth',
        fieldName: 'dateOfBirth',
        rules: {
          required: {
            value: true,
            message: 'DATE_OF_BIRTH_REQUIRED',
          },
          validate: (value: string) => new Date(value) <= subYears(new Date(), 18) || 'DATE_OF_BIRTH_AGE_REQUIREMENT',
        },
      },
      {
        id: 'marital_status',
        label: 'MARITAL',
        type: 'select',
        fieldName: 'maritalStatus',
        placeholder: 'MARITAL_PLACEHOLDER',
        options: MaritalStatusOptions,
        rules: {
          required: {
            value: true,
            message: 'MARITAL_REQUIRED',
          },
        },
      },
      {
        id: 'madeOffer_firstTime_buyer',
        label: 'FIRSTTIME_BUYER',
        type: 'radio',
        fieldName: 'firstTimeHomeBuyer',
        options: [
          {
            id: 'is_firstTime_homebuyer',
            value: 'true',
            label: 'FLOW_YES',
          },
          {
            id: 'isNot_firstTime_homebuyer',
            value: 'false',
            label: 'FLOW_NO',
          },
        ],
        rules: {
          validate: (value: boolean | null) => {
            if (value == null) {
              return 'FLOW_OFFER_FIRSTTIME_BUYER_REQUIRED';
            }
            return true;
          },
        },
        condition: ({ dealPurpose }) => dealPurpose === DealPurpose.Purchase || dealPurpose === DealPurpose.PreApproval,
      },
    ],
  },
  current_address: {
    id: 'current_address',
    type: 'customized',
    label: 'CURRENT_ADDRESS',
    component: CurrentAddress,
    next: 'current_address_moveIn',
    header: 'APPLICANT_CURRENT_ADDRESS_TITLE',
    isCompleteHelper: isCurrentAddressComplete,
    constructValueHelper: constructAddressString,
    api: API.ADDRESS,
    dataPath: `${API.ADDRESS}.current.address`,
  },
  current_address_moveIn: {
    id: 'current_address_moveIn',
    header: 'APPLICANT_MOVEIN_TITLE',
    type: 'form',
    next: FlowState.DYNAMIC,
    api: API.ADDRESS,
    inputs: [
      {
        id: 'moveIn-date',
        label: 'APPLICANT_MOVEIN_DATE',
        type: 'date',
        to: new Date(),
        from: subYears(new Date(), 100),
        fieldName: 'moveInDate',
        secondaryFieldName: 'current.moveInDate',
        attributes: { picker: 'month', readOnly: true },
        rules: {
          required: {
            value: true,
            message: 'MOVEIN_DATE_REQUIRED',
          },
        },
      },
    ],
    submitHandler: (args) => {
      const isRenewalAddress =
        (args?.dealPurpose === DealPurpose.SwitchTransfer || args?.dealPurpose === DealPurpose.Refinance) &&
        args?.intendedOccupationType === PropertyOccupationType.OwnerOccupied;

      if (!isRenewalAddress) return 'dwelling_status';

      const isEqual = commonAreAddressesEqual(args?.currentAddress?.address, args?.propertyAddress);

      if (!isEqual) return 'dwelling_status';

      const withInThreeYears = moveInDateWithinThreeYears(args?.currentAddress?.moveInDate);

      return withInThreeYears ? 'previous_address' : FlowState.NEXTSECTION;
    },
  },
  dwelling_status: {
    id: 'dwelling_status',
    header: 'APPLICANT_DWELLING_STATUS_TITLE',
    type: 'options',
    next: FlowState.DYNAMIC,
    fieldName: 'dwellingStatus',
    api: API.BORROWER,
    options: cloneDeep(DwellingStatusOptions),
    submitHandler: (args) => {
      const withInThreeYears = moveInDateWithinThreeYears(args.moveInDate);
      return withInThreeYears ? 'previous_address' : FlowState.NEXTSECTION;
    },
    dataPath: `${API.ADDRESS}.current`,
    tagLabel: 'LIVING_ARRANGEMENTS',
  },
  previous_address: {
    id: 'previous_address',
    header: 'APPLICANT_PREVIOUS_ADDRESS_TITLE',
    type: 'form',
    next: FlowState.NEXTSECTION,
    api: API.ADDRESS,
    inputs: [
      {
        id: 'previous_address_form',
        label: 'PREVIOUS_ADDRESS',
        type: 'address',
        fieldName: 'address',
        secondaryFieldName: 'previous.address',
      },
    ],
  },
};
