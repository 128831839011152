import styled from 'styled-components';

const StyledCurrentAddress = {
  ButtonWrapper: styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      justify-content: space-between;
    }
  `,
};

export default StyledCurrentAddress;
