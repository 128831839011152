import generatePicker from 'antd/lib/date-picker/generatePicker';
import styled, { css } from 'styled-components';
import generateConfig from './dateFns.config';
/**
 * This is used to override moment.js and have the date-picker use
 * date-fns https://ant.design/docs/react/replace-moment#Use-date-fns
 */
const DateFnsDatePicker = generatePicker<Date>(generateConfig);

const StyledPicker = {
  Label: styled.label`
    font-family: var(--font-futura);
    font-weight: 450;
    font-size: 18px;
    line-height: 22px;
    color: ${({ theme }) => theme.text.primary};
    margin-bottom: 8px;
  `,
  ErrorMessage: styled.p`
    font-family: var(--font-futura);
    font-weight: 400;
    color: ${({ theme }) => theme.text.error};
    font-size: 14px;
    line-height: 17px;
    margin-top: 8px;
  `,
  Wrapper: styled.div<{ invalid: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 26px;
    ${({ invalid }) =>
      invalid &&
      css`
        margin-bottom: 0;
      `}
  `,
  StyledDatePicker: styled(DateFnsDatePicker)<{ invalid: boolean; disabled: boolean }>`
    border-radius: 6px;
    margin: 0;
    padding: 0;
    .ant-picker-input {
      height: 64px;
      border-radius: 6px;
      background-color: ${({ theme }) => theme.background.primary};
      transition: box-shadow 0.3s;
      ${({ invalid }) =>
        !invalid
          ? css`
              @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
                &:hover,
                ::after {
                  box-shadow: 0 0 0 1px ${({ theme }) => theme.border.active};
                }
              }

              &:focus-within {
                box-shadow: 0 0 0 2px ${({ theme }) => theme.border.active};
                background-color: ${({ theme }) => theme.background.primary};
              }
            `
          : css`
              box-shadow: 0 0 0 2px ${({ theme }) => theme.border.error};
              &:focus-within {
                background-color: ${({ theme }) => theme.background.primary};
              }
            `};

      ${({ disabled }) =>
        disabled &&
        css`
          background-color: ${({ theme }) => theme.background.disabled};
          pointer-events: none;
        `}
    }

    .ant-picker-input > input {
      font-family: var(--font-futura);
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: ${({ theme }) => theme.text.primary};

      @media only screen and (max-width: ${({ theme }) => theme.breakpoint.sm}) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .ant-picker-cell .ant-picker-cell-inner {
      font-family: var(--font-futura);
      font-weight: 400;
      font-size: 14px;
      @media only screen and (max-width: ${({ theme }) => theme.breakpoint.sm}) {
        font-size: 16px;
      }
    }

    .ant-picker-suffix {
      color: ${({ theme }) => theme.text.primary};
      margin: 0 20px;
      order: -1;
    }
    .ant-picker-clear {
      padding-right: 10px;
    }
  `,
};

export default StyledPicker;
