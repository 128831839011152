import { API } from '@models/enums/api.enum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { incomesApi } from 'api/evergreen';
import {
  IncomesControllerApiApiBorrowersBorrowerIdIncomesPostRequest as IncomesPostRequest,
  IncomesControllerApiApiIncomesIncomeIdPatchRequest as IncomesPatchRequest,
  IncomesControllerApiApiIncomesIncomeIdDeleteRequest as IncomesDeleteRequest,
  MortgageApplicationClientDto,
  RentalIncome,
} from '@pinecorpca/evergreen';
import { useBorrowerQuery } from './borrower.hooks';
import { useRouter } from 'next/router';

export const useIncomeQuery = (borrowerId?: string) => {
  const { data: borrower, isSuccess, isFetching } = useBorrowerQuery(borrowerId);
  return { data: borrower?.incomes || [], isSuccess, isFetching };
};

export const useIncomeMutationOnPost = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (payload: IncomesPostRequest) => incomesApi.apiBorrowersBorrowerIdIncomesPost(payload),
    onSuccess: ({ data: newIncome }) => {
      const rentalIncomeProperty = (newIncome?.income as RentalIncome)?.property;

      queryClient.setQueryData(
        [API.MORTGAGE_APPLICATION, applicationId as string],
        (current: MortgageApplicationClientDto | undefined) => ({
          ...current,
          borrowers: current?.borrowers?.map((borrower) =>
            borrower.id === newIncome.borrowerId
              ? {
                  ...borrower,
                  incomes: [...(borrower?.incomes || []), newIncome],
                  // a rental income is a property thats associated with additional properties, to ensure data consistency, we add the rental income in additionalProperties
                  ...(!!rentalIncomeProperty
                    ? { additionalProperties: [...(borrower?.additionalProperties || []), rentalIncomeProperty] }
                    : {}),
                }
              : borrower
          ),
        })
      );
    },
  });
};

export const useIncomeMutationOnPatch = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (payload: IncomesPatchRequest) => incomesApi.apiIncomesIncomeIdPatch(payload),
    onSuccess: ({ data: updatedIncome }) => {
      queryClient.setQueryData([API.MORTGAGE_APPLICATION, applicationId as string], (current: MortgageApplicationClientDto | undefined) => {
        const borrower = current?.borrowers?.find(({ id }) => id === updatedIncome.borrowerId);
        const incomes = borrower?.incomes?.map((income) => (income.id === updatedIncome.id ? updatedIncome : income));
        const rentalIncomeProperty = (updatedIncome?.income as RentalIncome)?.property;

        return {
          ...current,
          borrowers: current?.borrowers?.map((borrower) =>
            borrower.id === updatedIncome.borrowerId
              ? {
                  ...borrower,
                  incomes,
                  ...(!!rentalIncomeProperty
                    ? {
                        // a rental income is a property thats associated with additional properties, to ensure data consistency, we update the rental income in additionalProperties
                        additionalProperties: borrower?.additionalProperties?.map((property) =>
                          property.id === rentalIncomeProperty.id ? rentalIncomeProperty : property
                        ),
                      }
                    : {}),
                }
              : borrower
          ),
        };
      });
    },
  });
};

export const useIncomeMutationOnDelete = () => {
  const queryClient = useQueryClient();
  const {
    query: { applicationId },
  } = useRouter();

  return useMutation({
    mutationFn: (request: IncomesDeleteRequest & { borrowerId: string }) => incomesApi.apiIncomesIncomeIdDelete(request),
    onSuccess: (_, variables) => {
      queryClient.setQueryData([API.MORTGAGE_APPLICATION, applicationId as string], (current: MortgageApplicationClientDto | undefined) => {
        const income = current?.borrowers
          ?.find(({ id }) => id === variables.borrowerId)
          ?.incomes?.find(({ id }) => id === variables.incomeId);
        const rentalPropertyId = (income?.income as RentalIncome)?.property?.id;
        return {
          ...current,
          borrowers: current?.borrowers?.map((borrower) =>
            borrower.id === variables.borrowerId
              ? {
                  ...borrower,
                  incomes: borrower.incomes?.filter(({ id }) => id !== variables.incomeId),
                  // a rental income is a property thats associated with additional properties, to ensure data consistency, we delete the rental income in additionalProperties
                  ...(!!rentalPropertyId
                    ? { additionalProperties: borrower.additionalProperties?.filter(({ id }) => id !== rentalPropertyId) }
                    : {}),
                }
              : borrower
          ),
        };
      });
    },
  });
};
