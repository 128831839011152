import styled from 'styled-components';

export const Paragraph = styled.p`
  font-family: var(--font-futura);
  font-weight: 300;
  font-style: normal;
  font-size: 24px;
  line-height: 30px;
`;

export const ParagraphRegular = styled.p`
  font-family: Heebo Regular;
  font-style: normal;
`;

export const ParagraphBold = styled.p`
  font-family: var(--font-futura);
  font-weight: 450;
  font-size: 14px;
  line-height: 24px;
`;
