import { NAMESPACE } from '@models/enums/namespace.enum';
import { useTranslation } from 'next-i18next';
import Styled from './StepIndicator.styles';
import { Step, StepStatus } from './models/stepIndicator.model';

interface IndicatorItemProps {
  step: Step;
}

const IndicatorItem = ({ step }: IndicatorItemProps) => {
  const { t } = useTranslation(NAMESPACE.LONGAPP);

  if (!step.to) {
    return (
      <Styled.ItemText
        $status={step.status}
        $current={step.current || step.status === StepStatus.COMPLETED}
        tabIndex={step.status === StepStatus.INCOMPLETE ? -1 : 0}
        data-testid={step.label}
        aria-disabled={step.status === StepStatus.INCOMPLETE}
        aria-label={`${t(step.label)} ${t(step.status)}`}
      >
        {t(step.label)}
      </Styled.ItemText>
    );
  }

  return (
    <Styled.ItemLink
      $status={step.status}
      $current={step.current || step.status === StepStatus.COMPLETED}
      tabIndex={step.status === StepStatus.INCOMPLETE ? -1 : 0}
      data-testid={step.label}
      aria-disabled={step.status === StepStatus.INCOMPLETE}
      aria-label={`${t(step.label)} ${t(step.status)}`}
      href={step.to}
    >
      {t(step.label)}
    </Styled.ItemLink>
  );
};
export default IndicatorItem;
