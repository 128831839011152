import { FormTitleSkeleton, InputSkeleton } from '@containers/long-app/styles/EntrySkeletons.styles';
import FormStyles from '@containers/long-app/styles/Form.styles';

const OtherFormSkeleton = () => (
  <>
    <FormTitleSkeleton />
    <FormStyles.SubFormWrapper data-testid="OtherSkeleton_form">
      <InputSkeleton>
        <div className="label" />
        <div className="input" />
      </InputSkeleton>
      <InputSkeleton>
        <div className="label" />
        <div className="input" />
      </InputSkeleton>
    </FormStyles.SubFormWrapper>
  </>
);

export default OtherFormSkeleton;
