import { FormTitleSkeleton, InputSkeleton } from '@containers/long-app/styles/EntrySkeletons.styles';
import FormStyles from '@containers/long-app/styles/Form.styles';

const DownpaymentSkeleton = () => (
  <>
    <FormTitleSkeleton />
    <FormStyles.SubFormWrapper data-testid="downpayment_skeleton">
      <InputSkeleton>
        <div className="label" />
        <div className="input" />
      </InputSkeleton>
      <FormStyles.DummyField />
    </FormStyles.SubFormWrapper>
  </>
);

export default DownpaymentSkeleton;
