import styled from 'styled-components';

export const Label = styled.label`
  font-family: var(--font-futura);
  font-weight: 450;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.text.disabled};
`;

export const LabelLight = styled.label`
  font-family: var(--font-futura);
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.text.secondary};
`;

export const LabelBold = styled.label`
  font-family: var(--font-futura);
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  color: ${({ theme }) => theme.text.primary};
`;
