import { hexToRGB } from '@pinecorpca/spruce';
import styled, { keyframes } from 'styled-components';

const keyframe = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const StyledModal = {
  Body: styled.div`
    padding: 20px;
  `,
  Header: styled.h2`
    font-size: 20px;
    font-family: var(--font-tiempos);
    font-weight: 300;
    font-size: 34px;
    line-height: 34px;
    color: ${({ theme }) => theme.text.primary};
    padding: 20px 45px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      padding: 16px;
    }
  `,
  HeaderWrapper: styled.div`
    display: flex;
    justify-content: flex-end;
  `,
  Modal: styled.div`
    background-color: ${({ theme }) => theme.background.primary};
    padding: 14px;
    box-shadow: rgb(0 0 0 / 10%) 2px 2px 7px 1px;
    transform: translate(-50%, -50%);

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      margin: 16px;
    }
    animation: ${keyframe} 0.3s ease-in-out forwards;
  `,
  Overlay: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => hexToRGB(theme.background.invert, 0.3)};
    z-index: 99999;
  `,
  Close: styled.a`
    min-height: 20px;
    min-width: 20px;
    max-width: 20px;
    max-height: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    :focus {
      outline: 1px solid ${({ theme }) => theme.palette.pine.default};
    }
  `,
};

export default StyledModal;
