import styled from 'styled-components';

export const StyledLoanAmount = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
  LoanAmountText: styled.p`
    font-family: var(--font-futura);
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: ${({ theme }) => theme.text.primary};
  `,
  LoanAmount: styled.p`
    font-family: var(--font-tiempos);
    font-weight: 400;
    font-size: 36px;
    line-height: 41px;
    color: ${({ theme }) => theme.text.primary};
  `,
  LoanAmountWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.border.primary};
    padding: 32px;
  `,
  Divider: styled.div`
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.border.primary};
    margin-bottom: 20px;

    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      margin: 20px 0;
    }
  `,
  ErrorMessageWrapper: styled.div`
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${({ theme }) => theme.text.error};
  `,
  ErrorMessage: styled.p`
    font-family: var(--font-futura);
    font-weight: 400;
    color: ${({ theme }) => theme.text.error};
    font-size: 14px;
    line-height: 16px;
  `,
};
