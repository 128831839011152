import { axiosInstance } from 'api/evergreen';
import { AddressSuggestion } from '../models/address-response.model';
import config from '@config';

const COUNTRY = 'CAN';
const MAX_RESULTS = 8;
const FIND_URI = `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws?Key=${config.CANADA_POST_KEY}`;
const RETRIEVE_URI = `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.10/json3.ws?Key=${config.CANADA_POST_KEY}`;

export const findAddress = async (searchTerm: string, id?: string) =>
  axiosInstance.get(FIND_URI, {
    params: {
      SearchTerm: searchTerm,
      Country: COUNTRY,
      lastId: id ?? '',
      MaxResults: MAX_RESULTS,
    },
  });

export const retrieveAddress = async ({ Id }: AddressSuggestion) =>
  axiosInstance.get(RETRIEVE_URI, { params: { Id } });
